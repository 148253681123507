import { companyStatsContext } from 'application/contexts/useCompanyStats';
import { companyUserContext } from 'application/contexts/useCompanyUser';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { IChatRoom } from 'domain/entities/ChatRoom/ChatRoom';
import { ICompanyUser } from 'domain/entities/CompanyUser/CompanyUser';
import { IMeetRequest } from 'domain/entities/MeetRequest/MeetRequest';
import { Message } from 'domain/entities/Message/Message';
import { User } from 'domain/entities/User/User';
import { CompanyChatRoomQuery } from 'interfaceAdapter/queries/CompanyChatRoom';
import { CompanyChatRoomRepository } from 'interfaceAdapter/repositories/CompanyChatRoom';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useModal } from 'ui/components/company/screens/modals/useModal';

export interface ChatDetailProps {
  room?: IChatRoom;
  fetchRooms: () => Promise<void>;
}

export const useChatDetail = ({ room, fetchRooms }: ChatDetailProps) => {
  const params = useParams();
  const { user } = useContext(companyUserContext);
  const { setNeedFirstContactMeetRoomIds } = useContext(companyStatsContext);
  const diContainer = useContext(diContainerContext);
  const companyChatRoomQuery = diContainer.resolve(CompanyChatRoomQuery);
  const companyChatRoomRepository = diContainer.resolve(CompanyChatRoomRepository);
  const memberEditModal = useModal();
  const remindModal = useModal();
  const ref = useRef<HTMLDivElement>(null);
  const [roomId, setRoomId] = useState<string | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [members, setMembers] = useState<{
    users: User[];
    companyUsers: ICompanyUser[];
  }>({
    users: [],
    companyUsers: [],
  });
  const [meetRequests, setMeetRequests] = useState<IMeetRequest[]>([]);
  const [acceptedMeetRequest, setAcceptedMeetRequest] = useState<IMeetRequest | null>(null);
  const [joined, setJoined] = useState(false);
  const [remindModalProps, setRemindModalProps] = useState<{
    projectSpreaderId?: number;
    passedDays?: number;
    meetRequestId?: number;
  }>({});

  const fetchMessages = async () => {
    if (roomId === null) return;
    const messages = await companyChatRoomQuery.getMessages(roomId);
    setMessages(messages.reverse());
  };

  let latestTimestamp = 0;
  const refetchMessages = async () => {
    if (roomId === null) return;
    const messages = await companyChatRoomQuery.getMessages(roomId);
    if (messages.length > 0 && latestTimestamp !== messages[0].timestamp) {
      setMessages((state) => [...state, messages[0]]);
      latestTimestamp = messages[0].timestamp;
    }
  };

  const fetchMembers = async () => {
    if (roomId === null) return;
    const members = await companyChatRoomQuery.getMembers(roomId);
    setMembers(members);
    setJoined(members.companyUsers.some((u) => u.id === user.id));
  };

  const fetchMeetRequests = async (roomId: string) => {
    const { meetRequests, acceptedMeetRequest } = await companyChatRoomQuery.getMeetRequests(
      roomId,
    );
    setMeetRequests(meetRequests);
    setAcceptedMeetRequest(acceptedMeetRequest);
  };

  const scrollToBottom = () => {
    if (!ref.current) return;
    ref.current.scrollTop = ref.current.scrollHeight;
  };

  const onSendMessage = useCallback(async () => {
    setNeedFirstContactMeetRoomIds((prev) => prev.filter((id) => id !== roomId));
    refetchMessages();

    if (!room) {
      return;
    }
    if (room.chatType === 'mr' && acceptedMeetRequest !== null && !acceptedMeetRequest.meeting_at) {
      setRemindModalProps({
        meetRequestId: acceptedMeetRequest.id,
      });
      remindModal.openModal();
    }
    if (room.chatType !== 'mr' && room.passed_days !== null && room.passed_days > 1) {
      setRemindModalProps({
        projectSpreaderId: room.project_spreader_id,
        passedDays: room.passed_days,
      });
      remindModal.openModal();
    }
  }, [roomId, room, acceptedMeetRequest]);

  const removeMessage = useCallback(
    async (chatId: number) => {
      if (roomId === null) return;

      await companyChatRoomRepository.removeChat(chatId);

      const messages = await companyChatRoomQuery.getMessages(roomId);
      setMessages(messages.reverse());
    },
    [roomId],
  );

  useEffect(() => {
    if (params.roomId && params.roomId !== 'rooms') {
      setRoomId(params.roomId);
    }
  }, []);

  useEffect(() => {
    if (!room) return;
    setRoomId(room.room_id);
    if (room?.chatType === 'mr') {
      fetchMeetRequests(room.room_id);
    } else {
      setMeetRequests([]);
    }
  }, [room]);

  useEffect(() => {
    fetchMessages();
    fetchMembers();
  }, [roomId]);

  // メッセージのフェッチ後に最下部（最新メッセージ箇所）にスクロールするようにしている
  useEffect(() => {
    scrollToBottom();
  }, [roomId, messages]);

  return {
    ref,
    user,
    roomId,
    messages,
    members,
    meetRequests,
    acceptedMeetRequest,
    joined,
    memberEditModal,
    remindModal,
    remindModalProps,
    fetchMembers,
    onSendMessage,
    removeMessage,
    fetchRooms,
  };
};
