import { ILocalVideoTrack, LocalVideoTrack } from 'agora-rtc-react';
import { memo } from 'react';
import { VideoIndicator } from 'ui/components/video/features/VideoIndicator/VideoIndicator';
import { TogglePin } from 'ui/components/video/features/VideoPin/TogglePin';
import { useVideoPin } from 'ui/components/video/features/VideoPin/useVideoPin';
import { MuteIcon } from '../../elements/MuteIcon/MuteIcon';

// 呼び出し元コンポーネントの再描画の度に再描画しないようにするためにメモ化している
// 再描画が走ると映像が一瞬点滅するように見えてしまうため
export const LocalVideo = memo(
  ({
    name,
    videoTrack,
    uid,
    muted,
    index,
  }: {
    name?: string;
    videoTrack: ILocalVideoTrack | null;
    uid: string | number;
    muted: boolean;
    index: number;
  }) => {
    // const [fit, setFit] = React.useState<'cover' | 'contain'>('contain');
    const { pinnedVideoIndex, setPinnedVideoIndex } = useVideoPin();

    if (!videoTrack) {
      return null;
    }

    return (
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: 'auto',
          maxHeight: '100%',
          maxWidth: '100%',
          minWidth: 0,
          // height: '100%',
          aspectRatio: '4 / 3',
          // minHeight: '160px',
          borderRadius: '10px',
          // gridColumn: pinnedVideoIndex === index ? '1 / -1' : 'auto',
        }}
      >
        <LocalVideoTrack
          style={{
            width: '100%',
            height: '100%',
            position: 'relative',
            borderRadius: '10px',
            border: '1px solid #1e2931',
            overflow: 'hidden',
          }}
          as="div"
          videoPlayerConfig={{ fit: 'contain' }}
          key={videoTrack.getTrackId()}
          track={videoTrack}
          play={true}
        />
        <VideoIndicator uid={uid} />

        <div
          style={{
            position: 'absolute',
            top: '10px',
            left: '10px',
            fontSize: '1.5rem',
            display: 'flex',
            padding: '5px',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            color: 'white',
          }}
        >
          {name}
        </div>

        <div
          style={{
            position: 'absolute',
            bottom: '10px',
            left: '10px',
            fontSize: '1.5rem',
          }}
        >
          <MuteIcon muted={muted} />
        </div>

        <TogglePin
          pinned={pinnedVideoIndex === index}
          onChange={() => {
            setPinnedVideoIndex(pinnedVideoIndex === index ? null : index);
          }}
        />
        {/* <ToggleFit
          value={fit}
          onChange={(value) => {
            setFit(value);
          }}
        /> */}
      </div>
    );
  },
);
LocalVideo.displayName = 'LocalVideo';
