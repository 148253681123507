import styled from '@emotion/styled';
import CheckIcon from '@mui/icons-material/Check';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import StopScreenShareIcon from '@mui/icons-material/StopScreenShare';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { ICameraVideoTrack, ILocalVideoTrack, IMicrophoneAudioTrack } from 'agora-rtc-react';
import React, { memo } from 'react';
import { useModal } from 'ui/components/user/screens/modals/useModal';
import { AudioDeviceSelector } from 'ui/components/video/features/AudioDeviceSelector/AudioDeviceSelector';
import { CameraDeviceSelector } from 'ui/components/video/features/CameraDeviceSelector/CameraDeviceSelector';
import { useDenoiser } from 'ui/components/video/features/Denoiser/useDenoiser';
import { VIDEO_CALL_FOOTER_HEIGHT_PX } from 'ui/components/video/layouts/VideoCallLayout/VideoCallLayout';
import { mq } from 'utils/responsive';

const Selector = styled.div`
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
  width: max-content;
  padding: 1rem;
  border: 1px solid black;
  background-color: #1e2931;
  color: white;
  position: absolute;
  z-index: 1;
  border-radius: 10px;
  font-size: 1.2rem;
`;

const SelectorItem = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background-color: #38424a;
  }
`;

export const PanelSelect = memo(function PanelSelect({
  label,
  options,
  onChange,
  value,
}: {
  label: string;
  options: { value: string; label: string }[];
  onChange: (value: string) => void;
  value: string;
}) {
  const { createModal, openModal, closeModal } = useModal();

  return (
    <>
      {createModal(
        <Selector>
          {options.map((option) => (
            <SelectorItem
              key={option.value}
              onClick={() => {
                onChange(option.value);
                closeModal();
              }}
              style={
                {
                  // backgroundColor: value === option.value ? 'lightblue' : 'white',
                }
              }
            >
              {value === option.value && (
                // <span style={{ marginRight: '0.2rem' }}>
                <CheckBoxIcon sx={{ color: 'lightgreen' }} />
                // </span>
              )}{' '}
              {option.label}
            </SelectorItem>
          ))}
        </Selector>,
      )}
      <PanelButton icon={<MoreHorizIcon />} onClick={openModal}>
        {label}
      </PanelButton>
    </>
  );
});

const PanelButtonWrapper = styled.div<{ variant: 'default' | 'red' }>`
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  padding: 0 1rem;
  background-color: ${(props) => (props.variant === 'red' ? 'var(--color-danger)' : '#1e2931')};
  word-break: keep-all;
  height: 100%;
  cursor: pointer;
  border-radius: 5px;
  color: white;
  transition: background-color 0.2s;
  &:hover {
    background-color: ${(props) => (props.variant === 'red' ? 'darkred' : '#38424a')};
  }
`;

export const PanelButton = memo(function PanelButton({
  icon,
  children,
  onClick,
  disabled = false,
  variant = 'default',
}: {
  icon?: React.ReactNode;
  children: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
  variant?: 'default' | 'red';
}) {
  const Icon = memo(function Icon({ icon }: { icon?: React.ReactNode }) {
    const originalElement = icon as React.ReactElement;
    const originalSx = originalElement?.props?.sx || {};

    if (!icon) return null;
    return React.cloneElement(icon as React.ReactElement, {
      sx: { ...originalSx, fontSize: '24px' },
    });
  });

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        maxWidth: 'fit-content',
        height: '100%',
      }}
    >
      <PanelButtonWrapper
        variant={variant}
        onClick={disabled ? undefined : onClick}
        style={disabled ? { color: 'grey', cursor: 'default' } : {}}
      >
        <Icon icon={icon} />
        <div>{children}</div>
      </PanelButtonWrapper>
    </div>
  );
});

const HiddenControlPanelWrapper = styled.div`
  height: ${VIDEO_CALL_FOOTER_HEIGHT_PX}px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: min-content;
  padding: 10px;
  scrollbar-width: none;
  ms-overflow-style: none;
  background-color: #101a25;
  border-radius: 0 10px 10px 0;
  z-index: 10;
  opacity: 0.8;
`;

const ControlPanelWrapper = styled.div`
  height: ${VIDEO_CALL_FOOTER_HEIGHT_PX}px;
  display: flex;
  gap: 10px;
  padding: 10px;
  scrollbar-width: none;
  ms-overflow-style: none;
  overflow-x: scroll;
  background-color: #101a25;
  white-space: nowrap;
  z-index: 10;
  width: 100%;

  ${mq('NARROW')} {
    position: fixed;
    bottom: 0;
    left: 0;
  }
`;

export const ControlPanel = memo(function ControlPanel({
  localMicrophoneTrack,
  localCameraTrack,
  localScreenTrack,
  handleScreenShareToggle,
  denoiserExtension,
  extensionActive,
  enableBackground,
  disableBackground,
  toggleMic,
  toggleCamera,
  micOn,
  cameraOn,
  handleLeave,
  recordingStarted,
  children,
}: {
  localMicrophoneTrack: IMicrophoneAudioTrack | null;
  localCameraTrack: ICameraVideoTrack | null;
  localScreenTrack: ILocalVideoTrack | null;
  handleScreenShareToggle: () => void;
  denoiserExtension: ReturnType<typeof useDenoiser>;
  extensionActive: boolean;
  enableBackground: () => void;
  disableBackground: () => void;
  toggleMic: () => void;
  toggleCamera: () => void;
  micOn: boolean;
  cameraOn: boolean;
  handleLeave: () => void;
  recordingStarted: boolean;
  children?: React.ReactNode;
}) {
  const [opened, setOpened] = React.useState(true);
  const userAgent = window.navigator.userAgent.toLowerCase();
  const isMobile =
    userAgent.includes('android') || userAgent.includes('iphone') || userAgent.includes('ipad');

  if (!opened) {
    return (
      <HiddenControlPanelWrapper>
        <PanelButton icon={<MoreHorizIcon />} onClick={() => setOpened(true)}>
          メニュー
        </PanelButton>
      </HiddenControlPanelWrapper>
    );
  }

  return (
    <ControlPanelWrapper>
      <div style={{ marginRight: '2rem' }}>
        <PanelButton icon={<VisibilityOffIcon />} onClick={() => setOpened(false)}>
          メニューを隠す
        </PanelButton>
      </div>

      <div>
        <PanelButton icon={<ExitToAppIcon />} onClick={handleLeave}>
          退出
        </PanelButton>
      </div>

      <AudioDeviceSelector micTrack={localMicrophoneTrack} />
      <CameraDeviceSelector videoTrack={localCameraTrack} />

      <>
        {localMicrophoneTrack?.enabled && (
          <PanelButton
            icon={micOn ? <MicIcon /> : <MicOffIcon sx={{ color: 'red' }} />}
            onClick={toggleMic}
          >
            {micOn ? 'ミュート' : 'ミュート解除'}
          </PanelButton>
        )}

        <PanelButton
          icon={cameraOn ? <VideocamIcon /> : <VideocamOffIcon sx={{ color: 'red' }} />}
          onClick={toggleCamera}
        >
          {cameraOn ? 'カメラOFF' : 'カメラON'}
        </PanelButton>

        {!isMobile && (
          <PanelButton
            icon={
              localScreenTrack?.enabled ? (
                <StopScreenShareIcon sx={{ color: 'green' }} />
              ) : (
                <ScreenShareIcon />
              )
            }
            onClick={handleScreenShareToggle}
            disabled={!recordingStarted}
          >
            {localScreenTrack?.enabled ? '画面共有停止' : '画面共有'}
          </PanelButton>
        )}

        <PanelButton
          onClick={() => {
            denoiserExtension.extensionActive
              ? denoiserExtension.disableDenoiser()
              : denoiserExtension.enableDenoiser();
          }}
          icon={<CheckIcon sx={{ color: denoiserExtension.extensionActive ? 'green' : 'white' }} />}
        >
          {denoiserExtension.extensionActive ? 'ノイズ抑制' : 'ノイズ抑制'}
        </PanelButton>
        <PanelButton
          icon={<CheckIcon sx={{ color: extensionActive ? 'green' : 'white' }} />}
          onClick={() => {
            extensionActive ? disableBackground() : enableBackground();
          }}
        >
          {extensionActive ? '背景ぼかし' : '背景ぼかし'}
        </PanelButton>
      </>

      {children}
    </ControlPanelWrapper>
  );
});
