import AgoraRTC, {
  AgoraRTCProvider,
  ILocalVideoTrack,
  useIsConnected,
  useJoin,
  useLocalCameraTrack,
  useLocalMicrophoneTrack,
  usePublish,
  useRemoteAudioTracks,
  useRemoteUsers,
  useRemoteVideoTracks,
  useRTCClient,
} from 'agora-rtc-react';

import { useCallback, useEffect, useRef, useState } from 'react';

import { ConnectingNow } from 'ui/components/video/elements/ConnectingNow/ConnectingNow';
import { Leaved } from 'ui/components/video/elements/Leaved/Leaved';
import { LocalScreenShare } from 'ui/components/video/elements/LocalScreenShare/LocalScreenShare';
import { VideoError } from 'ui/components/video/elements/VideoError/VideoError';
import {
  defaultVideoEncoderConfig,
  useAdaptiveVideoQuality,
} from 'ui/components/video/features/AdaptiveVideoQuality/useAdaptiveVideoQuality';
import { useDenoiser } from 'ui/components/video/features/Denoiser/useDenoiser';
import { useMediaPermissions } from 'ui/components/video/features/MediaPermission/useMediaPermissions';
import { useStartRecordingAfterJoin } from 'ui/components/video/features/Recording/useStartRecordingAfterJoin';
import { useUpdateRecordingLayout } from 'ui/components/video/features/Recording/useUpdateRecordingLayout';
import { useVideoBlur } from 'ui/components/video/features/VideoBlur/useVideoBlur';
import { VideoPinProvider } from 'ui/components/video/features/VideoPin/useVideoPin';
import { VideoCallLayout } from 'ui/components/video/layouts/VideoCallLayout/VideoCallLayout';
import { ControlPanel, PanelButton } from 'ui/components/video/panes/ControlPanel/ControlPanel';
import { Header } from 'ui/components/video/panes/Header/Header';
import { VideosLayout } from 'ui/components/video/panes/VideosLayout/VideosLayout';
import { LocalVideo } from 'ui/components/video/patterns/LocalVideo/LocalVideo';
import { RemoteVideo } from 'ui/components/video/patterns/RemoteVideo/RemoteVideo';
import { useSignaling } from '../../features/Signaling/useSignaling';

export const VideoCall = ({
  token,
  rtmToken,
  rtmUid,
  uid,
  screenShareToken,
  screenShareUid,
  channelName,
  isBusinessUser,
  userName,
}: {
  token: string;
  rtmToken: string;
  rtmUid: string;
  uid: number;
  screenShareToken: string;
  screenShareUid: number;
  channelName: string;
  isBusinessUser: boolean;
  userName: string;
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  AgoraRTC.setParameter('AUDIO_VOLUME_INDICATION_INTERVAL', 500);
  const client = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });

  return (
    <>
      <AgoraRTCProvider client={client}>
        <VideoPinProvider>
          <Videos
            token={token}
            rtmToken={rtmToken}
            uid={uid}
            rtmUid={Number(rtmUid)}
            screenShareToken={screenShareToken}
            screenShareUid={screenShareUid}
            channelName={channelName}
            isBusinessUser={isBusinessUser}
            userName={userName}
          />
        </VideoPinProvider>
      </AgoraRTCProvider>
    </>
  );
};

const Videos = ({
  token,
  rtmToken,
  uid,
  rtmUid,
  screenShareToken,
  screenShareUid,
  channelName,
  isBusinessUser,
  userName,
}: {
  token: string;
  rtmToken: string;
  uid: number;
  rtmUid: number;
  screenShareToken: string;
  screenShareUid: number;
  channelName: string;
  isBusinessUser: boolean;
  userName: string;
}) => {
  console.log('RENDER | Videos');

  const agoraAppId = process.env.REACT_APP_AGORA_APP_ID;
  if (!agoraAppId) {
    throw new Error('REACT_APP_AGORA_APP_ID must be provided');
  }
  const client = useRTCClient();

  const screenClientRef = useRef<ReturnType<typeof AgoraRTC.createClient> | null>(null); // 画面共有用クライアントのref

  const [screenShareOn, setScreenShare] = useState(false);
  const screenShareOnRef = useRef(screenShareOn);

  useEffect(() => {
    screenShareOnRef.current = screenShareOn;
  }, [screenShareOn]);

  const [cameraOn, setCamera] = useState(true);
  const [micOn, setMicOn] = useState(true);

  const { permissionError } = useMediaPermissions();

  const isConnected = useIsConnected(client);
  const [leaved, setLeaved] = useState(false);
  const { localMicrophoneTrack } = useLocalMicrophoneTrack(undefined, {}, client);

  const { localCameraTrack } = useLocalCameraTrack(
    cameraOn,
    {
      encoderConfig: defaultVideoEncoderConfig,
    },
    client,
  );

  const { enableBackground, disableBackground, extensionActive } = useVideoBlur({
    localCameraTrack,
  });
  const denoiserExtension = useDenoiser({ localMicrophoneTrack });

  const [localScreenTrack, setLocalScreenTrack] = useState<ILocalVideoTrack | null>(null);

  const { error: joinError, isConnected: joinCompleted } = useJoin(
    {
      appid: agoraAppId,
      channel: channelName,
      token,
      uid,
    },
    true,
    client,
  );

  // 全員分のユーザー名を表示のために保持する
  const [userNames, setUserNames] = useState<
    {
      uid: number;
      userName: string;
    }[]
  >([]);

  /**
   * 画面共有停止処理
   */
  const stopScreenShare = useCallback(async () => {
    if (!screenClientRef.current) {
      return;
    }
    const screenClient = screenClientRef.current;

    setScreenShare(false);
    if (localScreenTrack) {
      localScreenTrack.close(); // 画面共有トラックをクローズ. ブラウザの画面共有状態を停止する
      screenClient.unpublish(localScreenTrack); // 画面共有トラックの公開を停止
      await screenClient.leave();
      setLocalScreenTrack(null);
    }
  }, [localScreenTrack]);

  /**
   * 退出処理
   */
  const leave = useCallback(() => {
    client.unpublish();
    client.leave();
    if (screenShareOnRef.current) {
      stopScreenShare();
    }

    setLeaved(true);
  }, [client, screenShareOn, stopScreenShare]);

  const { publishDestroy } = useSignaling({
    channelName,
    token: rtmToken,
    rtmUid: rtmUid.toString(),
    userName,
    uid,
    onUserNameReceived: (payload) => {
      if (userNames.find((u) => u.uid === payload.uid)) {
        return;
      }
      setUserNames((prev) => {
        return [...prev, payload];
      });
    },
  });

  /**
   * チャンネル破棄イベントを受けて自身を退出させる
   * */
  const handleSprChannelDestroy = () => {
    leave();
  };

  useEffect(() => {
    // チャンネル破棄イベントを監視
    window.addEventListener('spr-channel-destroy', handleSprChannelDestroy);

    return () => {
      window.removeEventListener('spr-channel-destroy', handleSprChannelDestroy);
    };
  }, [leave]);

  useAdaptiveVideoQuality({ client, joinCompleted, localCameraTrack });

  const { recordingStarted } = useStartRecordingAfterJoin({
    joinCompleted,
    channelName,
    isBusinessUser,
    leave,
  });
  useUpdateRecordingLayout({ client, uid, channelName, isBusinessUser });

  usePublish([localMicrophoneTrack, localCameraTrack], isConnected, client);

  // チャンネル参加後に発火、再発火する必要がある
  client.enableAudioVolumeIndicator();

  const remoteUsers = useRemoteUsers(client);
  const remoteVideo = useRemoteVideoTracks(
    remoteUsers.filter((ru) => Number(ru.uid) != screenShareUid),
    client,
  );
  const remoteAudio = useRemoteAudioTracks(
    remoteUsers.filter((ru) => Number(ru.uid) != screenShareUid),
    client,
  );
  remoteAudio.audioTracks.map((track) => {
    track.play();
  });

  const toggleMic = () => {
    // localMicrophoneTrack?.muted が動的に変化しないため、Stateで管理する
    // mutedとmicOnでは論理が逆なので注意
    localMicrophoneTrack?.setMuted(micOn);
    setMicOn(!micOn);
  };

  const toggleCamera = () => {
    localCameraTrack?.setMuted(cameraOn);
    setCamera(!cameraOn);
  };

  const handleLeave = () => {
    if (!window.confirm('退出しますか？')) {
      return;
    }

    leave();
  };

  useEffect(() => {
    // 画面共有停止を検知
    localScreenTrack?.on('track-ended', async () => {
      handleScreenShareToggle();
    });
  }, [localScreenTrack]);

  const handleScreenShareToggle = async () => {
    if (screenShareOn) {
      await stopScreenShare();
    } else {
      // SafariのgetUserMediasへの制限により、イベントハンドラ直下で処理を実行する
      try {
        setScreenShare(true);

        const screenTrack = (await AgoraRTC.createScreenVideoTrack({
          optimizationMode: 'detail',
          encoderConfig: '1080p_2',
        })) as ILocalVideoTrack;

        if (!screenClientRef.current) {
          screenClientRef.current = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });
        }
        const screenClient = screenClientRef.current;
        await screenClient.join(agoraAppId, channelName, screenShareToken, screenShareUid);

        if (!screenTrack) {
          return;
        }

        // 画面共有が成功した場合のみ Agora クライアントを初期化
        setLocalScreenTrack(screenTrack);
        screenClient.publish(screenTrack); // カメラ映像の公開

        // await startScreenShare();
      } catch (error) {
        setScreenShare(false);
        console.error('Failed to start screen share directly', JSON.stringify(error));
      }
    }
  };

  const handleDestroy = () => {
    if (!window.confirm('通話を終了しますか？')) {
      return;
    }
    publishDestroy();
  };

  if (joinError) {
    return <VideoError />;
  }

  if (leaved) {
    return <Leaved />;
  }

  if (!isConnected) {
    return <ConnectingNow />;
  }

  if (permissionError) {
    return <div>{permissionError}</div>;
  }

  return (
    <VideoCallLayout>
      <Header remoteUserUids={remoteUsers.map((u) => u.uid)} />

      <VideosLayout
        displayedVideoCount={
          remoteUsers.length + (localScreenTrack?.enabled ? 1 : 0) + 1 // +1 for local video
        }
      >
        <LocalVideo
          name={userName}
          muted={!micOn}
          videoTrack={localCameraTrack}
          uid={uid}
          index={0}
        />

        {remoteUsers
          // 画面共有→個人ユーザー→ビジネスユーザーの順に表示する
          .toSorted((a, b) => Number(b.uid) - Number(a.uid))
          // 自身の画面共有は表示しない
          .filter((user) => user.uid !== screenShareUid)
          .map((user, i) => {
            const videoTrack = remoteVideo.videoTracks.find((t) => t.getUserId() === user.uid);
            // Mute状態を更新できる
            user.audioTrack?.getMediaStreamTrack();

            return (
              <RemoteVideo
                userName={userNames.find((u) => u.uid === user.uid)?.userName}
                index={i + 1}
                key={user.uid}
                videoTrack={videoTrack}
                uid={user.uid}
              />
            );
          })}

        <LocalScreenShare localScreenTrack={localScreenTrack} screenShareOn={screenShareOn} />
      </VideosLayout>

      <ControlPanel
        localMicrophoneTrack={localMicrophoneTrack}
        localCameraTrack={localCameraTrack}
        localScreenTrack={localScreenTrack}
        handleScreenShareToggle={handleScreenShareToggle}
        denoiserExtension={denoiserExtension}
        extensionActive={extensionActive}
        enableBackground={enableBackground}
        disableBackground={disableBackground}
        toggleMic={toggleMic}
        toggleCamera={toggleCamera}
        micOn={micOn}
        cameraOn={cameraOn}
        handleLeave={handleLeave}
        recordingStarted={recordingStarted}
      >
        {isBusinessUser ? (
          <PanelButton variant="red" onClick={handleDestroy}>
            全員に対して終了
          </PanelButton>
        ) : null}
      </ControlPanel>
    </VideoCallLayout>
  );
};
