import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  ICompanyVideoCallRecordingFilesClient,
  IGetInput,
  IGetResponse,
} from 'interfaceAdapter/gatewaySchemas/ICompanyVideoCallRecordingFileClient';

export class CompanyVideoCallRecordingFileClient implements ICompanyVideoCallRecordingFilesClient {
  async get(input: IGetInput): Promise<IGetResponse> {
    const PATH = `${API_LOCATION_SPREADY}/video_call/chat_room_units/${input.chat_room_unit_id}/video_call_recording_files`;
    const { token } = input;

    const method = 'GET';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };

    try {
      const response = await fetch(`${PATH}`, {
        method,
        headers,
      });
      const { message, data } = await response.json();

      if (!response.ok) {
        throw new Error(message);
      }

      return {
        message,
        data,
      };
    } catch (e) {
      if (e instanceof Error) {
        throw new Error(e.message);
      }
      throw new Error('処理に失敗しました');
    }
  }
}
