import { useEffect } from 'react';
import { Button } from 'ui/components/user/elements/Button/Button';
import { useModal } from 'ui/components/user/screens/modals/useModal';

export const RecordingAnnouncement = ({ forUserType }: { forUserType: 'user' | 'workgroup' }) => {
  const { createModal, openModal, closeModal } = useModal();

  useEffect(() => {
    openModal();
  }, []);

  return (
    <>
      {createModal(
        <div
          style={{
            padding: '20px',
            fontSize: '14px',
            textAlign: 'center',
            lineHeight: '1',
          }}
        >
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                gap: '4px',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  width: '10px',
                  height: '10px',
                  borderRadius: '10px',
                  backgroundColor: 'red',
                }}
              ></div>
              本通話は録画されています
            </div>

            {forUserType === 'user' ? (
              <>
                <p>
                  録画した動画はお相手企業の分析のためにのみ利用され、公開されることはありません
                </p>
                <p>ご了承の上ご利用ください</p>
              </>
            ) : (
              <>
                <p>録画した動画はチャット画面からダウンロード可能です</p>
                <p>
                  録画データのダウンロードと、録画完了から72時間より前の動画の再生にはプランへのご加入が必要となります
                </p>
              </>
            )}

            <div
              style={{
                marginTop: '2rem',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                onClick={() => {
                  closeModal();
                }}
              >
                了承して閉じる
              </Button>
            </div>
          </div>
        </div>,
      )}
    </>
  );
};
