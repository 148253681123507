import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IVideoCallRecordingClient,
  IPostInput,
  IPostResponse,
} from 'interfaceAdapter/gatewaySchemas/IVideoCallRecordingClient';

export class VideoCallRecordingClient implements IVideoCallRecordingClient {
  async post(input: IPostInput): Promise<IPostResponse> {
    const PATH = `${API_LOCATION_SPREADY}/video_call/start_recording`;
    const { token } = input;

    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN': token,
    };
    const body = JSON.stringify({
      channel_name: input.channelName,
    });

    try {
      const response = await fetch(`${PATH}`, {
        method,
        headers,
        body,
      });
      const { status } = response;
      const { message } = await response.json();
      console.log({ status, message });

      if (!response.ok) {
        throw new Error(message);
      }

      return {
        message,
        status,
      };
    } catch (e) {
      if (e instanceof Error) {
        throw new Error(e.message);
      }
      throw new Error('処理に失敗しました');
    }
  }
}
