import styled from '@emotion/styled';
import { Controller } from 'react-hook-form';
import { FormInput } from 'ui/components/company/elements/FormInput/FormInput';
import { FormCheckbox } from 'ui/components/company/elements/FormCheckbox/FormCheckbox';
import { FormError } from 'ui/components/company/elements/FormError/FormError';
import { CustomLink } from 'ui/components/publicPages/elements/CustomLink/CustomLink';
import { ActionButton } from 'ui/components/company/elements/ActionButton/ActionButton';
import { useSendEmail } from './useSendEmail';

const Wrapper = styled.div`
  padding: 40px 30px;
  border-radius: 12px;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: var(--font-size--medium);
`;

const Title = styled.div`
  font-size: var(--font-size--xx-large);
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
`;

const CheckContainer = styled.div`
  margin: 0 auto;
`;

const LoginLink = styled.div`
  text-align: center;
  margin-top: 4px;
  font-size: var(--font-size--default);
`;

const Separator = styled.div`
  margin-block: 24px;
  text-align: center;
  font-size: var(--font-size--default);
  letter-spacing: 0.5em;
  color: var(--gray);
`;

const RegisterLink = styled.div`
  font-size: var(--font-size--default);
  word-break: break-all;
`;

export const SendEmailForm: React.FC = () => {
  const { control, loading, errors, sendMail } = useSendEmail();

  return (
    <Wrapper>
      <Title>メールアドレスで登録</Title>
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <div>
            <FormInput
              {...field}
              placeholder="example@spready.jp"
              hasError={!!errors.email?.message}
            />
            {errors.email?.message && <FormError message={errors.email.message} />}
          </div>
        )}
      />
      <CheckContainer>
        <Controller
          name="agreed"
          control={control}
          render={({ field }) => (
            <FormCheckbox
              id="agreed"
              checked={field.value}
              onChange={() => field.onChange(!field.value)}
              disableBorder
              hasError={!!errors.agreed?.message}
            >
              <CustomLink
                inline
                textDecoration="underline"
                url="/company-application/terms/"
                text="利用規約"
                target="_blank"
              />
              及び
              <CustomLink
                inline
                textDecoration="underline"
                url="/info/policy/"
                text="個人情報の取り扱い"
                target="_blank"
              />
              に同意
            </FormCheckbox>
          )}
        />
        {errors.agreed?.message && <FormError message={errors.agreed.message} />}
      </CheckContainer>
      <ActionButton disabled={loading} onClick={sendMail} bgColor="secondary-dark">
        登録する（無料）
      </ActionButton>
      <LoginLink>
        ログイン（会員登録がお済みの方）は
        <CustomLink inline textDecoration="underline" url="/business/" text="こちら" />
      </LoginLink>
      <Separator>.....</Separator>
      <RegisterLink>
        本ページは案件を掲載する法人用アカウントの作成ページです。案件をご覧になりたい場合は
        <CustomLink inline textDecoration="underline" url="/register/" text="個人ユーザー登録" />
        をお願いいたします
      </RegisterLink>
    </Wrapper>
  );
};
