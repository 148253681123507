import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import Hls, { ErrorData, Events } from 'hls.js';
import { useEffect, useRef, useState } from 'react';
import { Button } from 'ui/components/company/elements/Button/Button';
import { useModal } from 'ui/components/user/screens/modals/useModal';
import { useVideoStreamingManifestQuery } from 'useVideoStreamingManifestQuery';
import { mq } from 'utils/responsive';

const ModalWrapper = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  // gap: 1rem;
  max-height: 80vh;
  overflow-y: auto;
  ${mq('SP')} {
    width: 90vw;
    @supports (width: 100svw) {
      width: 90svw;
    }
  }
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
`;

export const VideoPlayer = (args: {
  recordingFileId: number;
  opened: boolean;
  onClose: () => void;
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const { createModal, closeModal, openModal } = useModal();
  const [hlsSrc, setHlsSrc] = useState<string | null>(null);
  const { data, fetching, refetch } = useVideoStreamingManifestQuery({
    recordingFileId: args.recordingFileId,
  });
  const lastTimeBeforeRefetch = useRef(0);

  const setSrc = () => {
    const text = data?.streaming_manifest;
    if (!text) {
      return;
    }
    const blob = new Blob([text], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    setHlsSrc(url);
  };

  useEffect(() => {
    if (!hlsSrc || !Hls.isSupported() || !videoRef.current) {
      return;
    }

    const hls = new Hls();
    hls.loadSource(hlsSrc);
    hls.attachMedia(videoRef.current);

    // 再生位置が保存されている場合はその位置まで移動させる
    if (lastTimeBeforeRefetch.current > 0) {
      seekTo(lastTimeBeforeRefetch.current);
      lastTimeBeforeRefetch.current = 0;
    }

    /**
     * エラー発生時のハンドラー
     * 署名付きURLが切れたことによるエラーだと思われる場合はマニフェストファイルを再読み込みする
     *
     * Note:
     *   このハンドラの主目的は、マニフェスト内にあるtsファイルの署名付きURLの期限が切れたことによるネットワークエラー時のハンドリングである
     *   再読み込みが走れば期限はリセットされる
     */
    const hlsErrorHandler = (_error: Events.ERROR, data: ErrorData) => {
      if (data.type !== 'networkError' || !data.error.message.includes('403')) {
        return;
      }

      if (fetching) {
        return;
      }

      // 再読み込みする前に再生位置をrefに保存しておく
      lastTimeBeforeRefetch.current = videoRef.current?.currentTime ?? 0;
      refetch();
    };

    hls.once(Events.ERROR, hlsErrorHandler);

    return () => {
      hls.off(Events.ERROR, hlsErrorHandler);
    };
  }, [hlsSrc]);

  const seekTo = (timeInSeconds: number) => {
    if (!videoRef.current) {
      return;
    }

    if (videoRef.current.readyState >= 1) {
      // HAVE_METADATA
      videoRef.current.currentTime = timeInSeconds;
      return;
    }

    videoRef.current.addEventListener(
      'loadedmetadata',
      () => {
        if (!videoRef.current) {
          return;
        }
        videoRef.current.currentTime = timeInSeconds;
      },
      { once: true },
    );
  };

  useEffect(() => {
    if (fetching) {
      return;
    }
    if (args.opened) {
      setSrc();
      openModal();
    }
  }, [args.opened, fetching]);

  return (
    <>
      {createModal(
        <ModalWrapper>
          <ModalHeader>
            <Button
              onClick={() => {
                closeModal();
                args.onClose();
              }}
              button_type={'gray'}
            >
              <CloseIcon />
            </Button>
          </ModalHeader>
          <video ref={videoRef} controls autoPlay style={{ width: '100%' }} />
        </ModalWrapper>,
        { onClose: args.onClose, maskClosable: false },
      )}
    </>
  );
};
