export const VideoError = () => {
  return (
    <div
      style={{
        padding: '2rem',
      }}
    >
      <p>ビデオ通話に参加できませんでした。</p>
      <p>ビデオ通話に参加するためのトークンが失効している可能性があります。</p>
      <p>お手数ですが、再度チャット画面から参加をお試しください。</p>
    </div>
  );
};
