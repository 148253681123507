import styled from '@emotion/styled';
import React, { memo } from 'react';
import { mq, mqUser } from 'utils/responsive';
import {
  getGridTemplateColumns,
  getGridTemplateRows,
} from 'ui/components/video/panes/VideosLayout/useScreenLayout';
import { VIDEO_CALL_FOOTER_HEIGHT_PX } from 'ui/components/video/layouts/VideoCallLayout/VideoCallLayout';
import { useVideoPin } from 'ui/components/video/features/VideoPin/useVideoPin';
import { VIDEO_CALL_HEADER_HEIGHT_PX } from 'ui/components/video/panes/Header/Header';

const SizeDefinition = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  width: 100vw;
  height: calc(100svh - ${VIDEO_CALL_HEADER_HEIGHT_PX + VIDEO_CALL_FOOTER_HEIGHT_PX}px);
  overflow: auto;

  ${mq('NARROW')} {
    height: 100svh;
    padding-bottom: ${VIDEO_CALL_FOOTER_HEIGHT_PX}px;
  }
`;

const Wrapper = styled.div<{ displayedVideoCount: number; pinnedVideoIndex: number | null }>`
  display: grid;
  gap: 1rem;
  width: 100%;
  max-width: 100vw;
  height: fit-content;
  max-height: fit-content;
  min-height: fit-content;

  grid-template-columns: ${({ displayedVideoCount, pinnedVideoIndex }) => {
    if (displayedVideoCount === 1) {
      return getGridTemplateColumns({ displayedVideoCount, size: 'WIDE' });
    }
    if (pinnedVideoIndex !== null) {
      return `90% 10%`;
    }
    return getGridTemplateColumns({ displayedVideoCount, size: 'WIDE' });
  }};
  ${mqUser('STANDARD')} {
    grid-template-columns: ${({ displayedVideoCount }) =>
      getGridTemplateColumns({ displayedVideoCount, size: 'STANDARD' })};
  }
  ${mqUser('NARROW')} {
    grid-template-columns: ${({ displayedVideoCount }) =>
      getGridTemplateColumns({ displayedVideoCount, size: 'NARROW' })};
  }
  ${mqUser('SP')} {
    grid-template-columns: ${({ displayedVideoCount }) =>
      getGridTemplateColumns({ displayedVideoCount, size: 'SP' })};
  }

  grid-template-rows: ${({ displayedVideoCount, pinnedVideoIndex }) => {
    if (pinnedVideoIndex !== null) {
      return `auto`;
    }
    return getGridTemplateRows({ displayedVideoCount, size: 'WIDE' });
  }};
  ${mqUser('STANDARD')} {
    grid-template-rows: ${({ displayedVideoCount }) =>
      getGridTemplateRows({ displayedVideoCount, size: 'STANDARD' })};
  }
  ${mqUser('NARROW')} {
    grid-template-rows: ${({ displayedVideoCount }) =>
      getGridTemplateRows({ displayedVideoCount, size: 'NARROW' })};
  }
  ${mqUser('SP')} {
    grid-template-rows: ${({ displayedVideoCount }) =>
      getGridTemplateRows({ displayedVideoCount, size: 'SP' })};
  }
`;

const ReorderChildren = ({ children }: { children: React.ReactNode }) => {
  const { pinnedVideoIndex } = useVideoPin();
  console.log('pinnedVideoIndex', pinnedVideoIndex);
  if (pinnedVideoIndex === null) {
    return <>{children}</>;
  }
  const reordered = [
    React.Children.toArray(children)[pinnedVideoIndex],
    ...React.Children.toArray(children).filter((_, index) => index !== pinnedVideoIndex),
  ];
  return <>{reordered}</>;
};

const PinnedLayout = styled.div<{ displayedVideoCount: number }>`
  display: flex;
  gap: 1rem;
  height: 100%;
  // max-height: fit-content;
  width: 100%;
  max-width: 100svw;

  ${mq('SP')} {
    flex-direction: column;
  }

  & > * {
    // min-height: 300px;
  }
`;

const PinnedItem = styled.div`
  position: relative;
  width: 80%;
  height: 100%;

  ${mq('SP')} {
    width: 100%;
    height: unset;
  }
`;

const NotPinnedItems = styled.div<{ displayedVideoCount: number }>`
  display: grid;
  gap: 1rem;
  padding: 2px;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(${({ displayedVideoCount }) => displayedVideoCount - 1}, 1fr);
  width: 20%;
  height: fit-content;
  overflow: auto;
  max-height: 100%;
  flex-direction: column;

  & > * {
    min-width: 0;
  }

  ${mq('SP')} {
    // display: block;
    width: 100%;
  }
`;

export const VideosLayout = memo(function VideosLayout({
  children,
  displayedVideoCount,
}: {
  children: React.ReactNode;
  displayedVideoCount: number;
}) {
  const { pinnedVideoIndex } = useVideoPin();
  return (
    <SizeDefinition>
      {pinnedVideoIndex !== null ? (
        <PinnedLayout displayedVideoCount={displayedVideoCount}>
          <PinnedItem>{React.Children.toArray(children)[pinnedVideoIndex]}</PinnedItem>
          <NotPinnedItems displayedVideoCount={displayedVideoCount}>
            {React.Children.toArray(children).map((child, index) => {
              if (index === pinnedVideoIndex) return null;
              return <React.Fragment key={index}>{child}</React.Fragment>;
            })}
          </NotPinnedItems>
        </PinnedLayout>
      ) : (
        <Wrapper pinnedVideoIndex={pinnedVideoIndex} displayedVideoCount={displayedVideoCount}>
          <ReorderChildren>{children}</ReorderChildren>
        </Wrapper>
      )}
    </SizeDefinition>
  );
});
