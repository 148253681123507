import { useCallback, useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { IMeetRequestMessageTemplateQuery } from 'application/querySchemas/IMeetRequestMessageTemplateQuery';
import { IMeetRequestMessageTemplateRepository } from 'application/repositorySchemas/IMeetRequestMessageTemplateRepository';
import { useModal } from 'ui/components/company/screens/modals/useModal';
import { ListTableRow } from 'ui/components/company/features/ListTable/useListTable';

export interface MeetRequestMessageTemplateTableRow extends ListTableRow {
  id: number;
  title: string;
  body: string;
  createdAt: string;
}

export const useMeetRequestMessageTemplates = () => {
  const diContainer = useContext(diContainerContext);
  const query = diContainer.resolve<IMeetRequestMessageTemplateQuery>(
    'IMeetRequestMessageTemplateQuery',
  );
  const repository = diContainer.resolve<IMeetRequestMessageTemplateRepository>(
    'IMeetRequestMessageTemplateRepository',
  );
  const modal = useModal();

  const [rows, setRows] = useState<MeetRequestMessageTemplateTableRow[]>([]);
  const [selectedRow, setSelectedRow] = useState<MeetRequestMessageTemplateTableRow | null>(null);
  const [requesting, setRequesting] = useState(false);

  const fetchTemplates = useCallback(async () => {
    setRequesting(true);
    try {
      const templates = await query.fetch();
      setRows(
        templates.map((template) => ({
          id: template.id,
          title: template.title,
          body: template.body,
          createdAt: dayjs(template.created_at).format('YYYY/MM/DD'),
        })),
      );
    } finally {
      setRequesting(false);
    }
  }, []);

  const openModal = useCallback((row?: MeetRequestMessageTemplateTableRow) => {
    if (row) {
      setSelectedRow(row);
    }
    modal.openModal();
  }, []);

  const closeModal = useCallback(() => {
    setSelectedRow(null);
    modal.closeModal();
  }, []);

  const createOrEditTemplate = useCallback(
    async (title: string, body: string) => {
      setRequesting(true);
      try {
        if (selectedRow) {
          await repository.edit(selectedRow.id, title, body);
        } else {
          await repository.create(title, body);
        }
        fetchTemplates();
        closeModal();
      } finally {
        setRequesting(false);
      }
    },
    [selectedRow],
  );

  const removeTemplate = useCallback(
    async (id: number) => {
      if (!confirm('この定型メッセージを削除しますか？')) {
        return;
      }
      setRequesting(true);
      try {
        await repository.remove(id);
        fetchTemplates();
      } finally {
        setRequesting(false);
      }
    },
    [repository],
  );

  useEffect(() => {
    fetchTemplates();
  }, []);

  return {
    rows,
    selectedRow,
    requesting,
    openModal,
    closeModal,
    createModal: modal.createModal,
    createOrEditTemplate,
    removeTemplate,
  };
};
