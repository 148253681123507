import styled from '@emotion/styled';
import AgoraRTC, { ICameraVideoTrack, IMicrophoneAudioTrack } from 'agora-rtc-react';
import { useEffect, useRef, useState } from 'react';
import { Button } from 'ui/components/company/elements/Button/Button';
import { FormSelect } from 'ui/components/company/elements/FormSelect/FormSelect';
import { VolumeIndicator } from 'ui/components/company/screens/modals/VideoDeviceTest/VolumeIndicator';
import { useDenoiser } from 'ui/components/video/features/Denoiser/useDenoiser';
import { useMediaPermissions } from 'ui/components/video/features/MediaPermission/useMediaPermissions';
import { useVideoBlur } from 'ui/components/video/features/VideoBlur/useVideoBlur';
import { mqUser } from 'utils/responsive';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;

  width: 600px;
  padding: 24px;

  ${mqUser('SP')} {
    width: 95svw;
  }
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Header = styled.h4`
  font-size: 16px;
  font-weight: semibold;
  margin: 0;
`;

const Desc = styled.p`
  font-size: 14px;
`;

const Video = styled.div`
  width: 100%;
  aspect-ratio: 3 / 2;
  background-color: #efefef;
  place-self: center;

  ${mqUser('SP')} {
    width: 120px;
    aspect-ratio: 2 / 3;
  }
`;

const LoadingImage = styled.img`
  width: 10px;
  margin-right: 5px;
`;

const Center = styled.div`
  place-self: center;
`;

export const VideoCallDeviceTestModalContent = ({ closeModal }: { closeModal: () => void }) => {
  const ref = useRef(false);
  const [microphoneDevices, setMicrophoneDevices] = useState<MediaDeviceInfo[]>([]);
  const [cameraDevices, setCameraDevices] = useState<MediaDeviceInfo[]>([]);
  const [selectedCameraDevice, setSelectedCameraDevice] = useState<MediaDeviceInfo | null>(null);
  const [selectedMicrophoneDevice, setSelectedMicrophoneDevice] = useState<MediaDeviceInfo | null>(
    null,
  );
  const [videoTrack, setVideoTrack] = useState<ICameraVideoTrack | null>(null);
  const [audioTrack, setAudioTrack] = useState<IMicrophoneAudioTrack | null>(null);
  const { permissionError, checkFinished } = useMediaPermissions();
  const { enableDenoiser } = useDenoiser({ localMicrophoneTrack: audioTrack });
  useVideoBlur({ localCameraTrack: videoTrack });

  // useMediaPermissionsによるカメラとマイクの権限チェックが完了したら初期化処理を行う
  useEffect(() => {
    if (!checkFinished || permissionError) {
      return;
    }

    if (ref.current) {
      return;
    }

    ref.current = true;

    init();
  }, [permissionError, checkFinished]);

  const init = async () => {
    const devices = await AgoraRTC.getDevices();

    const audioDevices = devices.filter((d) => d.kind === 'audioinput');
    setMicrophoneDevices(audioDevices);
    setSelectedMicrophoneDevice(audioDevices[0]);

    const videoDevices = devices.filter((d) => d.kind === 'videoinput');
    setCameraDevices(videoDevices);
    setSelectedCameraDevice(videoDevices[0]);
  };

  // カメラを選択した時の処理
  useEffect(() => {
    if (!selectedCameraDevice) return;

    onCameraDeviceSelected(selectedCameraDevice);
  }, [selectedCameraDevice]);

  const onCameraDeviceSelected = async (selectedCameraDevice: MediaDeviceInfo) => {
    if (videoTrack) {
      videoTrack.stop();
      videoTrack.close();
    }

    const _videoTrack = await AgoraRTC.createCameraVideoTrack({
      cameraId: selectedCameraDevice.deviceId,
    });
    _videoTrack.play('video');
    setVideoTrack(_videoTrack);
  };

  // マイクを選択した時の処理
  useEffect(() => {
    if (!selectedMicrophoneDevice) return;

    onAudioDeviceSelected(selectedMicrophoneDevice);
  }, [selectedMicrophoneDevice]);

  const onAudioDeviceSelected = async (selectedAudioDevice: MediaDeviceInfo) => {
    if (audioTrack) {
      audioTrack.stop();
      audioTrack.close();
    }

    const _audioTrack = await AgoraRTC.createMicrophoneAudioTrack({
      microphoneId: selectedAudioDevice.deviceId,
    });

    setAudioTrack(_audioTrack);
  };

  // 初期化時やマイクを変更した時など、audioTrackが変更された時の処理
  useEffect(() => {
    if (!audioTrack) {
      return;
    }

    enableDenoiser().then(() => {
      audioTrack.play();
    });
  }, [audioTrack]);

  // モーダルを閉じるボタンを押した時の処理
  const handleClose = () => {
    if (videoTrack) {
      videoTrack.stop();
      videoTrack.close();
    }
    if (audioTrack) {
      audioTrack.stop();
      audioTrack.close();
    }

    closeModal();
  };

  if (!checkFinished) {
    return (
      <Wrapper>
        <Desc>
          <LoadingImage src="/loading.gif" />
          カメラとマイクを確認しています。
        </Desc>
      </Wrapper>
    );
  }

  if (permissionError) {
    return (
      <Wrapper>
        <Desc>{permissionError}</Desc>
        <Center>
          <Button button_type="blue" onClick={handleClose}>
            閉じる
          </Button>
        </Center>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Group>
        <Header>カメラ</Header>
        <FormSelect
          name="camera-select"
          value={selectedCameraDevice?.deviceId}
          options={cameraDevices.map((d) => ({ value: d.deviceId, label: d.label }))}
          onChange={(e) =>
            setSelectedCameraDevice(
              cameraDevices.find((d) => d.deviceId === e.target.value) ?? null,
            )
          }
        />
        <Video id="video" />
      </Group>
      <Group>
        <Header>マイク</Header>
        <FormSelect
          name="audio-select"
          value={selectedMicrophoneDevice?.deviceId}
          options={microphoneDevices.map((d) => ({ value: d.deviceId, label: d.label }))}
          onChange={(e) =>
            setSelectedMicrophoneDevice(
              microphoneDevices.find((d) => d.deviceId === e.target.value) ?? null,
            )
          }
        />
        <VolumeIndicator audioTrack={audioTrack} />
      </Group>
      <Center>
        <Button button_type="blue" onClick={handleClose}>
          閉じる
        </Button>
      </Center>
    </Wrapper>
  );
};
