import styled from '@emotion/styled';
import { useStartVideoCall } from 'ui/components/user/features/StartVideoCall/useStartVideoCall';

type Props = {
  chatRoomUnitId: number | undefined;
};

const Link = styled.a`
  border: none;
  outline: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: opacity 0.3s ease-out;

  &:hover,
  &:focus {
    opacity: 0.8;
  }

  width: fit-content;
  height: 40px;
  padding: 0 16px;
  background-color: var(--spr-black);
  color: var(--spr-white);

  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

export const StartVideoCallLink: React.FC<Props> = (props) => {
  const { getStartVideoCallPageLink } = useStartVideoCall(props.chatRoomUnitId);

  return (
    <Link href={getStartVideoCallPageLink()} target="_blank" rel="noreferrer">
      ビデオ通話を開始
    </Link>
  );
};
