import { inject, injectable } from 'tsyringe';
import type { IAnswerMeetRequestSurveyForUserClient } from 'interfaceAdapter/gatewaySchemas/IAnswerMeetRequestSurveyForUserClient';
import type { IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import { IMeetRequestUserSurveyRepository } from 'application/repositorySchemas/IMeetRequestUserSurveyRepository';

@injectable()
export class MeetRequestUserSurveyRepository implements IMeetRequestUserSurveyRepository {
  constructor(
    @inject('IUserTokenRepository') public readonly userToken: IUserTokenRepository,
    @inject('IAnswerMeetRequestSurveyForUserClient')
    public readonly answerClient: IAnswerMeetRequestSurveyForUserClient,
  ) {}

  public async answer(code: string, answers: Array<number | string | null>): Promise<true> {
    const { message } = await this.answerClient.post({
      token: this.userToken.get() || '',
      code,
      answers,
    });

    if (message) {
      throw new Error(message);
    }
    return true;
  }
}
