import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'utils/yup';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { companyUserContext } from 'application/contexts/useCompanyUser';
import { CompanyUserRepository } from 'interfaceAdapter/repositories/CompanyUser';
import { CompanyUserQuery } from 'interfaceAdapter/queries/CompanyUser';
import { ICompanyUser } from 'domain/entities/CompanyUser/CompanyUser';
import { TEAM_ROLE_TYPE } from 'domain/entities/Team/TeamRole';

const schema = yup
  .object()
  .shape({
    icon_image: yup.string().default(''),
    last_name: yup.string().default('').required(),
    first_name: yup.string().default('').required(),
    department: yup.string().default('').required(),
    job: yup.string().default(''),
    /*
     * このあたりは通るようにしてある
     * +81-90-0000-0000
     * 81-90-0000-0000
     * 090-0000-0000
     * 03-1234-1234
     */
    phone_number: yup
      .string()
      .required()
      .matches(/^[0-9+]{1,5}(?:-\d{1,5})?-\d{3,5}-\d{3,5}$/, {
        message: 'ハイフンありの半角数字でご入力ください',
      }),
  })
  .required();

type UserEditForm = yup.InferType<typeof schema>;

export const useUserProfileEdit = () => {
  const diContainer = useContext(diContainerContext);
  const companyUserRepository = diContainer.resolve<CompanyUserRepository>(CompanyUserRepository);
  const companyUserQuery = diContainer.resolve<CompanyUserQuery>(CompanyUserQuery);
  /** 現在ログイン中のユーザー */
  const { user: editor } = useContext(companyUserContext);
  const params = useParams();
  const { control, handleSubmit, formState, setValue, watch } = useForm<UserEditForm>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  /** 編集対象のユーザー */
  const [targetUser, setTargetUser] = useState<ICompanyUser | null>(null);
  const [requesting, setRequesting] = useState(false);
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);

  const isFormAvailable = useMemo(() => {
    // 自分自身の編集
    if (Number(params.userId) === editor.id) {
      return true;
    }
    // 他ユーザーの編集は特定のロールのみ可能
    return editor.team_role === TEAM_ROLE_TYPE.SUPPORT_USER;
  }, [params.userId, editor.team_role]);

  const onImageUpload = useCallback(async (fileUrl: string) => {
    setValue('icon_image', fileUrl);
  }, []);

  const onSubmit = handleSubmit(async (form) => {
    setRequesting(true);
    try {
      if (!params.userId || isNaN(Number(params.userId))) {
        return;
      }

      const id = Number(params.userId);
      if (id === editor.id) {
        // 自分自身の編集
        await companyUserRepository.editOwn({
          icon_image: form.icon_image === '' ? null : form.icon_image,
          last_name: form.last_name,
          first_name: form.first_name,
          department: form.department,
          job: form.job === '' ? null : form.job,
          phone_number: form.phone_number,
        });
      } else {
        // 他ユーザーの編集
        await companyUserRepository.editOther({
          id,
          icon_image: form.icon_image === '' ? null : form.icon_image,
          last_name: form.last_name,
          first_name: form.first_name,
          department: form.department,
          job: form.job === '' ? null : form.job,
          phone_number: form.phone_number,
        });
      }
      setShowSuccessSnackbar(true);
    } catch (e) {
      // @ts-expect-error エラーの型がunknownなので
      throw new Error(e.message ?? '登録に失敗しました');
    } finally {
      setRequesting(false);
    }
  });

  useEffect(() => {
    const id = parseInt(params.userId ?? '', 10);
    if (isNaN(id)) {
      return;
    }
    companyUserQuery.get(id).then((target) => {
      setTargetUser(target);
      setValue('icon_image', target.icon_image ?? '');
      setValue('last_name', target.last_name);
      setValue('first_name', target.first_name);
      setValue('department', target.department ?? '');
      setValue('job', target.job ?? '');
      setValue('phone_number', target.phone_number ?? '');
    });
  }, []);

  return {
    isFormAvailable,
    editor,
    targetUser,
    control,
    watch,
    formState,
    requesting,
    showSuccessSnackbar,
    setShowSuccessSnackbar,
    onImageUpload,
    onSubmit,
  };
};
