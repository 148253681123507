import { Controller } from 'react-hook-form';
import styled from '@emotion/styled';
import { SegmentSelector } from 'ui/components/company/screens/modals/SegmentSelector/SegmentSelector';
import { FormLabel } from 'ui/components/company/elements/FormLabel/FormLabel';
import { Button } from 'ui/components/company/elements/Button/Button';
import { Select } from 'ui/components/company/elements/Select/Select';
import { Input } from 'ui/components/company/elements/Input/Input';
import { SpreaderFilterProps, useSpreaderFilter } from './useSpreaderFilter';

const Wrapper = styled.div`
  margin-bottom: 24px;
  padding: 20px 16px;
  border: 1px solid var(--gray-light-1);
  border-radius: 10px;
`;

const LabelWrapper = styled.div`
  margin-bottom: 16px;
`;

const SubLabel = styled.div`
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 14px;
`;

const Inputs = styled.div`
  display: flex;
  gap: 18px;
  margin-bottom: 20px;

  > * {
    width: 46%;
  }
`;

const Ages = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  white-space: nowrap;

  > * {
    max-width: 180px;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 30px;
  text-align: center;
`;

/**
 * スプレッダー絞り込み
 */
export const SpreaderFilter: React.FC<SpreaderFilterProps> = (props) => {
  const {
    control,
    formState: { errors },
    userSegments,
    requesting,
    search,
    ageOptions,
  } = useSpreaderFilter(props);

  return (
    <Wrapper>
      <LabelWrapper>
        <FormLabel title="スプレッダーの所属企業・職種情報" />
      </LabelWrapper>
      <Inputs>
        <div>
          <SubLabel>業界</SubLabel>
          <Controller
            name="industry"
            control={control}
            render={({ field }) => (
              <SegmentSelector
                title="所属業界"
                segments={userSegments?.company || []}
                selectedSegment={field.value}
                error={errors.industry?.child?.message}
                setSelectedSegment={field.onChange}
              />
            )}
          />
        </div>
        <div>
          <SubLabel>職種</SubLabel>
          <Controller
            name="job"
            control={control}
            render={({ field }) => (
              <SegmentSelector
                title="職種"
                segments={userSegments?.job || []}
                selectedSegment={field.value}
                error={errors.job?.child?.message}
                setSelectedSegment={field.onChange}
              />
            )}
          />
        </div>
      </Inputs>

      <Inputs>
        <div>
          <SubLabel>所属企業</SubLabel>
          <Controller
            name="company_name"
            control={control}
            render={({ field }) => (
              <Input {...field} placeholder="企業名" error={errors.company_name?.message} />
            )}
          />
        </div>
        <div>
          <SubLabel>役職名</SubLabel>
          <Controller
            name="job_name"
            control={control}
            render={({ field }) => (
              <Input {...field} placeholder="役職名" error={errors.job_name?.message} />
            )}
          />
        </div>
      </Inputs>

      <LabelWrapper>
        <FormLabel title="スプレッダーの年齢" />
      </LabelWrapper>
      <Ages>
        <Controller
          name="start_age"
          control={control}
          render={({ field }) => (
            <Select {...field} options={ageOptions} error={errors.start_age?.message} />
          )}
        />
        から
        <Controller
          name="end_age"
          control={control}
          render={({ field }) => (
            <Select {...field} options={ageOptions} error={errors.end_age?.message} />
          )}
        />
      </Ages>

      <ButtonWrapper>
        <Button button_type="dark" disabled={requesting} onClick={search}>
          検索
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};
