import styled from '@emotion/styled';
import { PageTitle } from 'ui/components/company/elements/PageTitle/PageTitle';
import { UserProfileEditForm } from 'ui/components/company/patterns/UserProfileEditForm/UserProfileEditForm';
import { UserRoleEditForm } from 'ui/components/company/patterns/UserRoleEditForm/UserRoleEditForm';

const Wrapper = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 30px;
`;

/**
 * 企業ユーザー 編集レイアウト
 */
export const UserEdit = () => {
  return (
    <Wrapper>
      <PageTitle title="ユーザー編集" />
      <UserProfileEditForm />
      <UserRoleEditForm />
    </Wrapper>
  );
};
