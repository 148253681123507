import styled from '@emotion/styled';
import { SpreaderCard } from 'ui/components/company/patterns/SpreaderCard/SpreaderCard';
import { MeetRequestItem } from 'ui/components/company/patterns/MeetRequestItem/MeetRequestItem';
import { SpreaderFilter } from 'ui/components/company/patterns/SpreaderFilter/SpreaderFilter';
import { MeetRequestMessageTemplates } from 'ui/components/company/patterns/MeetRequestMessageTemplates/MeetRequestMessageTemplates';
import {
  MEET_REQUEST_TAB,
  MeetRequestTabs,
} from 'ui/components/company/features/MeetRequestTabs/MeetRequestTabs';
import { MeetRequestDesc } from 'ui/components/company/features/MeetRequestDesc/MeetRequestDesc';
import { Pager } from 'ui/components/company/features/Pager/Pager';
import { PageTitle } from 'ui/components/company/elements/PageTitle/PageTitle';
import { Spinner as SpinnerElement } from 'ui/components/company/elements/Spinner/Spinner';
import { useMeetRequestLayout } from './useMeetRequest';

const Wrapper = styled.div`
  padding-block: 16px;
`;

const Spreaders = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(265px, 1fr));
  column-gap: 8px;
  row-gap: 20px;
`;

const PagerWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-block: 10px 20px;
`;

const SpinnerWrapper = styled.div`
  margin-block: 60px 80px;
  text-align: center;
`;

const Spinner = () => (
  <SpinnerWrapper>
    <SpinnerElement size="40px" color="var(--gray-dark-2)" />
  </SpinnerWrapper>
);

/**
 * 企業系 会いたいリクエスト
 */
const CompanyMeetRequestLayout = () => {
  const {
    requesting,
    currentTab,
    setCurrentTab,
    page,
    setPage,
    totalPage,
    setFilterAndFetch,
    spreaders,
    meetRequests,
  } = useMeetRequestLayout();
  return (
    <Wrapper>
      <PageTitle title="会いたいリクエスト" />
      <MeetRequestTabs value={currentTab} onSelect={setCurrentTab} />

      {currentTab === MEET_REQUEST_TAB.ALL ? (
        <>
          <MeetRequestDesc />
          <SpreaderFilter onSearch={setFilterAndFetch} />
          {requesting ? (
            <Spinner />
          ) : (
            <Spreaders>
              {spreaders.map((spreader) => (
                <SpreaderCard key={spreader.id} user={spreader} />
              ))}
            </Spreaders>
          )}
        </>
      ) : null}

      {currentTab === MEET_REQUEST_TAB.WAITING_ACCEPT ||
      currentTab === MEET_REQUEST_TAB.CONNECTED ? (
        requesting ? (
          <Spinner />
        ) : (
          meetRequests.map((meetRequest) => (
            <MeetRequestItem key={meetRequest.id} meetRequest={meetRequest} />
          ))
        )
      ) : null}

      {currentTab === MEET_REQUEST_TAB.TEMPLATE_MESSAGES ? <MeetRequestMessageTemplates /> : null}

      {currentTab !== MEET_REQUEST_TAB.TEMPLATE_MESSAGES && !requesting && (
        <PagerWrapper>
          <Pager page={page} total={totalPage} onChange={setPage} />
        </PagerWrapper>
      )}
    </Wrapper>
  );
};

export default CompanyMeetRequestLayout;
