import yup from 'utils/yup';
import {
  IMeetRequestMessageTemplate,
  MeetRequestMessageTemplate,
} from '../MeetRequest/MeetRequestMessageTemplate';

export interface IMeetRequestMessageTemplateFactory {
  build(data: IMeetRequestMessageTemplateData): IMeetRequestMessageTemplate;
}

export const meetRequestMessageTemplateSchema = yup.object({
  id: yup.number().required(),
  title: yup.string().required(),
  body: yup.string().required(),
  created_at: yup.string().required(),
});

type IMeetRequestMessageTemplateData = yup.InferType<typeof meetRequestMessageTemplateSchema>;

export class MeetRequestMessageTemplateFactory implements IMeetRequestMessageTemplateFactory {
  public build(data: IMeetRequestMessageTemplateData): IMeetRequestMessageTemplate {
    return new MeetRequestMessageTemplate(
      data.id,
      data.title,
      data.body,
      new Date(data.created_at),
    );
  }
}
