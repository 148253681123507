import { Outlet } from 'react-router-dom';
import { useUserStandard } from './useUserStandard';

export const UserStandard: React.FC = () => {
  const { canEnter } = useUserStandard();

  if (!canEnter) {
    return null;
  }

  return <Outlet />;
};
