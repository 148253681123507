import { Outlet } from 'react-router-dom';
import { useCompanyStandard } from './useCompanyStandard';

/**
 * Business系 認証済みページの共通処理
 */
export const CompanyStandard: React.FC = () => {
  const { canEnter } = useCompanyStandard();
  return canEnter ? <Outlet /> : null;
};
