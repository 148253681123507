import { Button } from 'ui/components/user/elements/Button/Button';
import { useModal } from 'ui/components/user/screens/modals/useModal';
import { VideoCallDeviceTestModalContent } from 'ui/components/user/screens/modals/VideoDeviceTest/VideoCallDeviceTestModal';

export const VideoCallDeviceTest = () => {
  const { createModal, openModal, closeModal } = useModal();

  return (
    <>
      {createModal(<VideoCallDeviceTestModalContent closeModal={closeModal} />, {
        // 終了時にマイクとカメラの使用をやめる処理を行いたいのでモーダル内の閉じるボタン以外で閉じられないようにしている
        keyboard: false,
        maskClosable: false,
      })}

      <Button width="max-content" status="secondary" onClick={openModal}>
        カメラとマイクをテスト
      </Button>
    </>
  );
};
