import styled from '@emotion/styled';
import { mqUser } from 'utils/responsive';
import { useNavigate } from 'react-router-dom';
import { ActionButton } from 'ui/components/company/elements/ActionButton/ActionButton';
import { UserIcon } from 'ui/components/company/elements/UserIcon/UserIcon';
import { IconButton } from 'ui/components/company/elements/IconButton/IconButton';
import Step3Img from 'ui/images/company/icons/step3.svg';
import EditIcon from 'ui/images/company/icons/edit.svg';
import { useTeamCreateConfirm } from './useTeamCreateConfirm';
import { FormError } from 'ui/components/company/elements/FormError/FormError';

const Wrapper = styled.div`
  height: calc(100vh - 70px);
  @supports (height: 100svh) {
    min-height: calc(100svh - 70px);
  }
  padding: 20px 20px 60px;
  background-color: var(--primary);
  overflow-y: auto;
  margin: -16px -30px -60px;
`;

const TitleContainer = styled.div`
  font-size: var(--font-xxxx-large);
  color: var(--spr-black);
  font-weight: var(--font-extra-bold);
  width: 100%;
  margin: 10px 0 20px;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: flex-start;

  img {
    width: 70px;
    height: 70px;
  }
`;

const FormContainer = styled.div`
  max-width: 1080px;
  margin-inline: auto;
`;

const TitleText = styled.div`
  font-size: var(--font-xxxx-large);
  font-weight: var(--font-extra-bold);
  color: var(--spr-black);
  ${mqUser('SP')} {
    font-size: var(--font-xx-large);
  }
`;

const BgWhite = styled.div`
  margin-bottom: 20px;
  padding: 40px 28px;
  border-radius: 12px;
  background-color: var(--white);
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 20px;
  font-size: var(--font-xxx-large);
  font-weight: 900;
  line-height: 1;
`;

const Group = styled.div`
  margin-bottom: 16px;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--gray-light-1);
  font-weight: 900;
`;

const Label = styled.p`
  margin: 0 0 8px;
  font-size: var(--font-x-large);
`;

const Value = styled.p`
  margin: 0;
  font-size: var(--font-large);
  white-space: pre-line;
  overflow-wrap: break-word;
`;

const Pic = styled.div`
  align-items: center;
  img {
    width: 250px;
    margin-inline: 1%;
    border-radius: 8px;
  }
`;

const Ogp = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  p {
    margin: 0;
  }
`;

export const TeamCreateConfirm: React.FC = () => {
  const { loading, requesting, errors, getValues, onSubmit } = useTeamCreateConfirm();

  const navigate = useNavigate();

  if (loading) return null;

  const pics = getValues('pics');
  const urls = getValues('urls');

  return (
    <>
      <Wrapper>
        <FormContainer>
          <TitleContainer>
            <img src={Step3Img} alt="step3" />
            <TitleText>入力内容の確認</TitleText>
          </TitleContainer>
          <BgWhite>
            {Object.keys(errors).length > 0 && (
              <FormError
                wide
                message="入力エラーがあります。入力内容をご確認ください。"
                style={{ marginBottom: '40px' }}
              />
            )}
            <Title>
              企業情報（ワークグループ）の入力
              <IconButton
                icon={EditIcon}
                buttonStyle={{ padding: '9px 12px' }}
                onClick={() => navigate('/workgroup/create/profile')}
              >
                修正する
              </IconButton>
            </Title>
            <Group>
              <Label>企業名</Label>
              <Value>{getValues('name')}</Value>
            </Group>
            <Group>
              <Label>ワークグループ識別名</Label>
              <Value>{getValues('meta_description')}</Value>
            </Group>
            <Group>
              <Label>郵便番号</Label>
              <Value>{getValues('zipcode')}</Value>
            </Group>
            <Group>
              <Label>御社所在地</Label>
              <Value>{getValues('address')}</Value>
            </Group>
            <Group>
              <Label>コーポレートサイトURL</Label>
              <Value>{getValues('url')}</Value>
            </Group>
            <Group>
              <Label>ロゴ（アイコン画像）</Label>
              <Value>
                {getValues('icon_image') ? (
                  <UserIcon src={getValues('icon_image')} size={80} />
                ) : (
                  '未選択'
                )}
              </Value>
            </Group>
            <Group>
              <Label>会社説明</Label>
              <Value>{getValues('about_company')}</Value>
            </Group>
            <Group>
              <Label>会社説明画像1</Label>
              <Value>
                {pics && pics.length > 0 ? (
                  <Pic>
                    <img src={pics[0]} alt={getValues('name')} />
                  </Pic>
                ) : (
                  '未選択'
                )}
              </Value>
            </Group>
            <Group>
              <Label>会社説明画像2</Label>
              <Value>
                {pics && pics.length > 1 ? (
                  <Pic>
                    <img src={pics[1]} alt={getValues('name')} />
                  </Pic>
                ) : (
                  '未選択'
                )}
              </Value>
            </Group>
            <Group>
              <Label>会社紹介URL</Label>
              <Value>
                <Ogp>
                  {urls && urls.length > 0
                    ? urls.map((url: { text: string }) => <p key={url.text}>{url.text}</p>)
                    : '未入力'}
                </Ogp>
              </Value>
            </Group>
            <Group>
              <Label>企業のコピーや理念など</Label>
              <Value>{getValues('copy')}</Value>
            </Group>
            <Group>
              <Label>HERP連携用WebHookURL</Label>
              <Value>{getValues('herp_webhook_url') || '未入力'}</Value>
            </Group>
            <Group>
              <Label>Slack通知用WebHookURL</Label>
              <Value>{getValues('slack_webhook_url') || '未入力'}</Value>
            </Group>
          </BgWhite>
          <BgWhite>
            <ActionButton
              bgColor="secondary-dark"
              style={{ margin: '32px auto 0' }}
              disabled={requesting}
              onClick={onSubmit}
            >
              この内容で登録する
            </ActionButton>
          </BgWhite>
        </FormContainer>
      </Wrapper>
    </>
  );
};
