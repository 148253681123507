import { CompleteSendEmail as CompleteSendEmailPane } from 'ui/components/company/panes/SendEmail/CompleteSendEmail';
import { useHead } from './useHead';

/**
 * アカウント登録 メール送信完了ページ
 */
export const CompleteSendEmail = () => {
  useHead();

  return <CompleteSendEmailPane />;
};
