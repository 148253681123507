import AgoraRTC, { ICameraVideoTrack } from 'agora-rtc-react';
import { useEffect, useRef, useState } from 'react';
import { PanelSelect } from 'ui/components/video/panes/ControlPanel/ControlPanel';

export const CameraDeviceSelector = ({ videoTrack }: { videoTrack: ICameraVideoTrack | null }) => {
  const init = useRef(false);
  const [videoDevices, setVideoDevices] = useState<MediaDeviceInfo[]>();
  const [selectedDeviceLabel, setSelectedDeviceLabel] = useState<string>(
    videoTrack?.getTrackId() ?? '',
  );
  const setVideoDevicesHandler = async () => {
    const browserDevices = await navigator.mediaDevices.getUserMedia({ video: true });
    console.log({ browserDevices });
    const devices = await AgoraRTC.getCameras();
    setVideoDevices(devices.filter((device) => device.kind === 'videoinput'));
  };

  const handleChange = (value: string) => {
    const deviceId = videoDevices?.find((device) => device.label === value)?.deviceId;
    if (!deviceId) {
      return;
    }
    videoTrack?.setDevice(deviceId);
    setSelectedDeviceLabel(value);
  };

  videoTrack?.on('track-updated', () => {
    // Changeイベントではなくtrack-updatedイベントを使い、Agora側の変更としたいが、ラベルの情報が渡ってこないため、不可能
    // setSelectedDeviceLabel(track.label);
  });

  AgoraRTC.onCameraChanged = async () => {
    setVideoDevicesHandler();
  };

  useEffect(() => {
    setVideoDevicesHandler();
  }, []);

  useEffect(() => {
    if (init.current) {
      return;
    }
    if (!videoTrack) {
      return;
    }
    console.log('videoTrack.getTrackId()', videoTrack.getTrackId(), videoTrack.getTrackLabel());
    setSelectedDeviceLabel(videoTrack.getTrackLabel());
    init.current = true;
  }, [videoTrack]);

  if (!videoTrack) {
    return null;
  }

  return (
    <PanelSelect
      label="映像デバイス"
      onChange={handleChange}
      options={videoDevices?.map((device) => ({ value: device.label, label: device.label })) || []}
      value={selectedDeviceLabel}
    />
  );
};
