import { type ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';
import { inject, injectable } from 'tsyringe';

import { type ICompanyVideoTokenRepository } from 'application/repositorySchemas/ICompanyVideoTokenRepository';
import { type ICompanyVideoTokenClient } from 'interfaceAdapter/gatewaySchemas/ICompanyVideoTokenClient';
import { IPostResponse } from 'interfaceAdapter/gatewaySchemas/IVideoTokenClient';

@injectable()
export class CompanyVideoTokenRepository implements ICompanyVideoTokenRepository {
  constructor(
    @inject('ICompanyUserTokenRepository')
    public readonly companyUserToken: ICompanyUserTokenRepository,
    @inject('ICompanyVideoTokenClient')
    public readonly client: ICompanyVideoTokenClient,
  ) {}

  public async post({ chatRoomUnitId }: { chatRoomUnitId: number }): Promise<IPostResponse> {
    const response = await this.client.post({
      token: this.companyUserToken.get() || '',
      chatRoomUnitId,
    });

    if (response.message) {
      throw new Error(response.message);
    }
    return response;
  }
}
