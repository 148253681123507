import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { ChatRoom } from 'domain/entities/ChatRoom/ChatRoom';
import { Link } from 'react-router-dom';
import { DashboardArrowIcon } from 'ui/components/user/features/DashboardArrowIcon/DashboardArrowIcon';

const Wrapper = styled.div`
  width: 80%;
  min-width: 300px;
  max-width: 600px;
  margin: 16px auto;
  padding: 16px;
  background: var(--spr-primary-paler);
  border-radius: 8px;
`;

const Date = styled.span`
  display: inline-block;
  margin-bottom: 10px;
  font-size: 10px;
  color: var(--gray-dark-2);
  line-height: 16px;
`;

const Label = styled.p`
  margin: 0 0 10px;
  font-weight: 700;
  font-size: 13px;
`;

const Project = styled.div`
  display: flex;
  gap: 10px;
  padding: 16px;
  background-color: var(--spr-white);
  border-radius: 4px;
  img {
    margin-inline: auto;
    width: 144px;
    height: 80px;
  }
  flex: 1;
  font-weight: 700;
  font-size: 14px;
`;

const LinkWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
  font-size: 11px;
  line-height: 11px;
  a:hover {
    opacity: 0.8;
  }
`;

interface Props {
  project: ChatRoom['project'];
}

/**
 * チャット詳細内に表示するセッション概要
 */
export const RoomProjectSummary: React.FC<Props> = ({ project }) => {
  if (!project) {
    return null;
  }

  const startDateTime = project?.start_datetime
    ? dayjs(project.start_datetime).format('YYYY/MM/DD HH:mm')
    : '';
  return (
    <Wrapper>
      <Label>こちらについてお話させてください</Label>
      <Project>
        <img src={project.main_pic} alt="プロジェクト画像" />
        <div
          style={{
            flex: '1',
            display: 'flex',
            flexDirection: 'column',
            // alignItems: 'flex-start',
            gap: '10px',
          }}
        >
          <span>{project.title}</span>
          <Date>{startDateTime}</Date>
          <LinkWrapper>
            <Link to={`/user/project/${project.id}/`} target="_blank">
              詳細を見る
            </Link>
            <DashboardArrowIcon />
          </LinkWrapper>
        </div>
      </Project>
    </Wrapper>
  );
};
