import styled from '@emotion/styled';
import { Controller } from 'react-hook-form';
import { Button } from 'ui/components/company/elements/Button/Button';
import { FormLabel } from 'ui/components/company/elements/FormLabel/FormLabel';
import { Select } from 'ui/components/company/elements/Select/Select';
import { SnackBar } from 'ui/components/company/elements/SnackBar/SnackBar';
import ng from 'ui/images/form/validation_ng.png';
import ok from 'ui/images/form/validation_ok.png';
import { AboutPermissionButton } from '../../features/AboutPermissionButton/AboutPermissionButton';
import { useUserRoleEditForm } from './useUserRoleEditForm';

const Wrapper = styled.div`
  max-width: 600px;
  margin: 0 auto 50px;
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  gap: 12px;
  align-items: center;
  margin-top: 16px;
  margin-bottom: ${(props: { noMarginBottom?: boolean }) => (props.noMarginBottom ? 0 : 54)}px;
`;

const FormIcon = styled.div`
  width: 24px;
  height: 24px;
  background-image: url(${(props: { isValid: boolean }) => (props.isValid ? ok : ng)});
  background-size: 100% 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const PermissonLabels = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

/**
 * 企業ユーザーの権限変更フォーム
 */
export const UserRoleEditForm: React.FC = () => {
  const {
    isFormAvailable,
    control,
    formState: { errors },
    requesting,
    selectableRoles,
    showSuccessSnackbar,
    setShowSuccessSnackbar,
    onSubmit,
  } = useUserRoleEditForm();

  if (!isFormAvailable) {
    return null;
  }

  return (
    <Wrapper>
      <SnackBar
        open={showSuccessSnackbar}
        setOpen={setShowSuccessSnackbar}
        message="権限を変更しました"
      />

      <PermissonLabels>
        <FormLabel title="役割・権限" isRequired />
        <AboutPermissionButton />
      </PermissonLabels>
      <InputWrapper>
        <Controller
          name="team_role"
          control={control}
          render={({ field }) => (
            <Select
              value={field.value ?? -1}
              options={selectableRoles}
              error={errors.team_role?.message}
              placeholder="役割・権限"
              onChange={(e) => field.onChange(e.target.value)}
            />
          )}
        />
        <FormIcon isValid={!errors.team_role} />
      </InputWrapper>

      <ButtonWrapper>
        <Button button_type="dark" disabled={requesting} onClick={onSubmit}>
          保存
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};
