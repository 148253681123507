import { Address4, Address6 } from 'ip-address';

export const IP_VERSION = {
  IPv4: 'IPv4',
  IPv6: 'IPv6',
} as const;

type IpVersion = (typeof IP_VERSION)[keyof typeof IP_VERSION];

export const isIpAddress = (value: string) => {
  return Address4.isValid(value) || Address6.isValid(value);
};

export const getIpVersion = (value: string): IpVersion | null => {
  if (Address4.isValid(value)) {
    return IP_VERSION.IPv4;
  }
  if (Address6.isValid(value)) {
    return IP_VERSION.IPv6;
  }
  return null;
};
