import { IconButton } from '../../elements/IconButton/IconButton';
import HelpIcon from 'ui/images/company/icons/help.svg';

export const AboutPermissionButton = () => {
  return (
    <a
      target="_blank"
      rel="noreferrer"
      href="https://spready.notion.site/1511a713999a81848649e2fd35d3a808#1511a713999a81c19865e776839ecb07"
    >
      <IconButton icon={HelpIcon} buttonStyle={{ border: 'none', padding: '0px' }}></IconButton>
    </a>
  );
};
