import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { IMeetRequestMessageTemplateQuery } from 'application/querySchemas/IMeetRequestMessageTemplateQuery';
import { IMeetRequestMessageTemplate } from 'domain/entities/MeetRequest/MeetRequestMessageTemplate';
import { Select } from 'ui/components/company/elements/Select/Select';
import { Button } from 'ui/components/company/elements/Button/Button';

const Wrapper = styled.div`
  margin-top: 8px;
  padding: 12px 12px 16px;
  border-radius: 8px;
  background-color: var(--gray-light-3);
`;

const Message = styled.div<{ alignRight?: boolean }>`
  padding: 4px 0;
  font-size: var(--font-medium);
  color: var(--gray-dark-3);
  text-align: ${({ alignRight }) => (alignRight ? 'right' : 'left')};
`;

const Link = styled.a`
  color: var(--accent);
  text-decoration: underline;
`;

const Label = styled.p<{ marginTop?: boolean }>`
  margin: ${({ marginTop }) => (marginTop ? '12px 4px 12px' : '4px 4px 12px')};
  font-size: var(--font-medium);
  font-weight: var(--font-semi-bold);
  color: var(--gray-dark-3);
`;

const Preview = styled.div`
  max-height: 200px;
  overflow-y: auto;
  margin-bottom: 8px;
  padding: 12px;
  border: 1px solid var(--gray-light-2);
  border-radius: 4px;
  background-color: var(--white);
  font-size: var(--font-large);
  line-height: 1.5;
  color: var(--gray-dark-3);
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  > button {
    background-color: var(--white);
  }
`;

interface Props {
  onConfirm: (template: IMeetRequestMessageTemplate) => void;
}

/**
 * 会いたいリクエスト 定型メッセージ選択
 */
export const MeetRequestMessageTemplateSelector: React.FC<Props> = ({ onConfirm }) => {
  const diContainer = useContext(diContainerContext);
  const query = diContainer.resolve<IMeetRequestMessageTemplateQuery>(
    'IMeetRequestMessageTemplateQuery',
  );

  const [templates, setTemplates] = useState<IMeetRequestMessageTemplate[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<IMeetRequestMessageTemplate | null>(
    null,
  );
  const [requesting, setRequesting] = useState(false);

  const options = useMemo(() => {
    return templates.map((template) => {
      return {
        value: template.id,
        label: template.title.length < 24 ? template.title : template.title.slice(0, 24) + '...',
      };
    });
  }, [templates]);

  const fetchTemplates = useCallback(async () => {
    try {
      setRequesting(true);
      const templates = await query.fetch();
      setTemplates(templates);
      setSelectedTemplate(templates[0]);
    } finally {
      setRequesting(false);
    }
  }, [query]);

  const handleSelect = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const template = templates.find((t) => `${t.id}` === e.target.value);
      if (template) {
        setSelectedTemplate(template);
      }
    },
    [templates],
  );

  const handleConfirm = useCallback(() => {
    if (selectedTemplate) {
      onConfirm(selectedTemplate);
    }
  }, [selectedTemplate, onConfirm]);

  useEffect(() => {
    fetchTemplates();
  }, []);

  if (requesting) {
    return <Message>読み込み中...</Message>;
  }

  if (templates.length === 0) {
    return (
      <Message>
        まだ定型メッセージがありません。
        <Link href={`/workgroup/meet/?type=template_messages`} target="_blank" rel="noreferrer">
          「定型メッセージ」タブ
        </Link>
        から作成してください。
      </Message>
    );
  }

  return (
    <Wrapper>
      <Label>定型メッセージを選択</Label>
      <Select options={options} onChange={handleSelect} />

      {selectedTemplate && (
        <>
          <Label marginTop>挿入されるメッセージ内容</Label>
          <Preview>{selectedTemplate.body}</Preview>
          <Message alignRight>
            定型メッセージの編集は
            <Link href={`/workgroup/meet/?type=template_messages`} target="_blank" rel="noreferrer">
              「定型メッセージ」タブ
            </Link>
            から
          </Message>
        </>
      )}

      <ButtonWrapper>
        <Button
          button_type="transparent_blue"
          width="200px"
          padding="14px 10px"
          onClick={handleConfirm}
        >
          メッセージ本文に挿入
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};
