import { PropsWithChildren } from 'react';
import styled from '@emotion/styled';

interface Props {
  button_type:
    | 'dark'
    | 'blue'
    | 'pale_blue'
    | 'red'
    | 'weak_red'
    | 'delete'
    | 'gray'
    | 'transparent_blue';
  disabled?: boolean;
  width?: string;
  height?: string;
  fontSize?: string;
  padding?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const BaseButton = styled.button`
  max-width: 100%;
  border: none;
  outline: none;
  border-radius: 4px;
  text-align: center;
  font-size: ${(props: { fontSize?: string }) => props.fontSize || '14px'};
  cursor: pointer;
  transition: opacity 0.3s ease-out;

  &:hover,
  &:focus {
    opacity: 0.8;
  }
`;

const DarkButton = styled(BaseButton)`
  width: ${(props: { width?: string }) => props.width || '320px'};
  height: ${(props: { height?: string }) => props.height || '40px'};
  padding: ${(props: Partial<Props>) => props.padding || '0 16px'};
  background-color: var(--spr-black);
  color: var(--spr-white);
  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`;

const BlueButton = styled(BaseButton)`
  width: ${(props: Partial<Props>) => props.width || null};
  padding: ${(props: Partial<Props>) => props.padding || '10px 8px'};
  background-color: var(--spr-accent);
  color: var(--spr-white);
  &:disabled {
    background-color: #0000001f;
    color: #00000042;
    cursor: default;
  }
`;

const PaleBlueButton = styled(BaseButton)`
  width: ${(props: Partial<Props>) => props.width || null};
  padding: ${(props: { padding?: string }) => props.padding || '9px 15px'};
  background-color: var(--spr-accent-paler);
  color: var(--spr-accent-dark);
  border: 1px solid rgba(0, 0, 0, 0.12);
`;

const RedButton = styled(BaseButton)`
  width: ${(props: Partial<Props>) => props.width || null};
  padding: ${(props: Partial<Props>) => props.padding || '10px 8px'};
  background-color: var(--color-new-lp-warn);
  color: var(--spr-white);
`;

const WeakRedButton = styled(BaseButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  width: ${(props: { width?: string }) => props.width || null};
  border-radius: 4px;
  padding: 8px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  background-color: var(--spr-warn-paler);
  color: var(--spr-warn);
`;

const DeleteButton = styled(BaseButton)`
  padding: ${(props: { padding?: string }) => props.padding || '8px 10px'};
  font-size: ${(props: { fontSize?: string }) => props.fontSize || '10px'};
  color: var(--color-new-lp-warn);
  background-color: transparent;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14),
    0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  border: 1px solid rgba(0, 0, 0, 0.12);
`;

const GrayButton = styled(BaseButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  width: ${(props: { width?: string }) => props.width || null};
  border-radius: 4px;
  padding: 8px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  color: var(--gray-dark-3);
  background-color: var(--gray-light-2);
`;

const TransparentBlueButton = styled(BaseButton)`
  width: ${(props: Partial<Props>) => props.width || '200px'};
  padding: ${(props: { padding?: string }) => props.padding || '9px 15px'};
  background-color: transparent;
  color: #1061fb;
  border: 1px solid rgba(0, 0, 0, 0.12);

  &:hover {
    background-color: rgba(16, 97, 251, 0.05);
  }
`;

/**
 * 汎用ボタンコンポーネント
 */
export const Button: React.FC<PropsWithChildren<Props>> = ({
  button_type,
  disabled,
  width,
  height,
  fontSize,
  padding,
  onClick,
  children,
}) => {
  const ButtonElement = {
    dark: DarkButton,
    blue: BlueButton,
    pale_blue: PaleBlueButton,
    red: RedButton,
    weak_red: WeakRedButton,
    delete: DeleteButton,
    gray: GrayButton,
    transparent_blue: TransparentBlueButton,
  }[button_type];
  return (
    <ButtonElement
      disabled={disabled}
      onClick={onClick}
      width={width}
      height={height}
      fontSize={fontSize}
      padding={padding}
    >
      {children}
    </ButtonElement>
  );
};
