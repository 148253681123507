import styled from '@emotion/styled';
import { MeetRequestTemplateMessageForm } from 'ui/components/company/screens/modals/MeetRequestTemplateMessageForm/MeetRequestTemplateMessageForm';
import { ListTable } from 'ui/components/company/features/ListTable/ListTable';
import { ListTableColumn } from 'ui/components/company/features/ListTable/useListTable';
import { Button } from 'ui/components/company/elements/Button/Button';
import { Spinner as SpinnerElement } from 'ui/components/company/elements/Spinner/Spinner';
import {
  MeetRequestMessageTemplateTableRow,
  useMeetRequestMessageTemplates,
} from './useMeetRequestMessageTemplates';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-block: 16px 4px;
  padding-right: 16px;
`;

const NoData = styled.div`
  margin-block: 16px;
  padding: 80px 0 100px;
  font-size: var(--font-medium);
  text-align: center;
`;

const BodyCell = styled.div`
  display: -webkit-box;
  max-width: 100%;
  padding: 4px 0;
  padding-right: 16px;
  overflow: hidden;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
`;

const ActionButtons = styled.div`
  display: flex;
  column-gap: 8px;
  row-gap: 4px;
  justify-content: flex-end;
  padding: 0 16px;
  white-space: nowrap;
`;

const SpinnerWrapper = styled.div`
  margin-block: 60px 80px;
  text-align: center;
`;

/**
 * 会いたいリクエスト 定型メッセージ一覧の表示および作成･編集･削除
 */
export const MeetRequestMessageTemplates: React.FC = () => {
  const {
    rows,
    selectedRow,
    requesting,
    openModal,
    closeModal,
    createModal,
    createOrEditTemplate,
    removeTemplate,
  } = useMeetRequestMessageTemplates();

  const TABLE_COLUMNS: ListTableColumn<MeetRequestMessageTemplateTableRow>[] = [
    {
      key: 'title',
      label: '名前',
    },
    {
      key: 'body',
      label: 'メッセージ内容',
      cellRenderer: (row: MeetRequestMessageTemplateTableRow) => <BodyCell>{row.body}</BodyCell>,
    },
    {
      key: 'createdAt',
      label: '作成日',
    },
    {
      key: '',
      label: '',
      cellRenderer: (row: MeetRequestMessageTemplateTableRow) => (
        <ActionButtons>
          <Button
            button_type="transparent_blue"
            width="88px"
            padding="10px 16px"
            onClick={() => openModal(row)}
          >
            編集する
          </Button>
          <Button
            button_type="red"
            padding="10px 16px"
            disabled={requesting}
            onClick={() => removeTemplate(row.id)}
          >
            削除する
          </Button>
        </ActionButtons>
      ),
    },
  ];

  if (requesting) {
    return (
      <SpinnerWrapper>
        <SpinnerElement size="40px" color="var(--gray-dark-2)" />
      </SpinnerWrapper>
    );
  }

  return (
    <>
      <ButtonWrapper>
        <Button
          button_type="blue"
          padding="10px 16px"
          disabled={requesting}
          onClick={() => openModal()}
        >
          新しく作成する
        </Button>
      </ButtonWrapper>

      {rows.length === 0 ? (
        <NoData>まだ定型メッセージがありません</NoData>
      ) : (
        <ListTable columns={TABLE_COLUMNS} data={rows} countPerPageOptions={[50, 100, 200]} />
      )}

      {createModal(
        <MeetRequestTemplateMessageForm
          title={selectedRow?.title}
          body={selectedRow?.body}
          onCancel={closeModal}
          onSave={createOrEditTemplate}
        />,
        { keyboard: false, maskClosable: false },
      )}
    </>
  );
};
