import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { Controller } from 'react-hook-form';
import { useCompanyEditForm } from './useCompanyEditForm';
import { SnackBar } from 'ui/components/company/elements/SnackBar/SnackBar';
import { FormLabel } from 'ui/components/company/elements/FormLabel/FormLabel';
import { ImageUpload } from 'ui/components/company/features/ImageUpload/ImageUpload';
import ok from 'ui/images/form/validation_ok.png';
import ng from 'ui/images/form/validation_ng.png';
import { Input } from 'ui/components/company/elements/Input/Input';
import { TextArea } from 'ui/components/company/elements/TextArea/TextArea';
import { Button } from 'ui/components/company/elements/Button/Button';
import { Checkbox } from '../../elements/Checkbox/Checkbox';

const Container = styled.div`
  max-width: 700px;
  margin: 0 auto;
`;

const Card = styled.div`
  margin-bottom: 16px;
  padding: 40px 0;
  border-radius: 12px;
  background-color: var(--spr-white);
`;

const Group = styled.div`
  position: relative;
  margin-bottom: 50px;
  text-align: left;
  display: grid;
  grid-template-areas:
    'label label'
    'input icon';
  grid-template-columns: 90% 10%;

  > div:first-of-type {
    margin-bottom: 18px;
  }

  textarea {
    height: 90px;
  }
`;

const Wrapper = styled.span`
  position: relative;
  display: inline-block;
  width: 100%;
`;

const InputElement = styled.input`
  width: 100%;
  padding: 10px 16px;
  border-radius: 5px;
  font-size: var(--font-size--large);
  line-height: 22px;
  border: 1px solid var(--gray-light-2);
  background-color: var(--spr-white);
`;

const LabelContainer = styled.div`
  grid-area: label;
`;

const FormIcon = styled.div`
  display: grid;
  margin: 10px 0 0 10px;
  width: 24px;
  height: 24px;
  background-image: url(${(props: { isValid: boolean }) => (props.isValid ? ok : ng)});
  background-size: 100% 100%;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin: 10px;
  padding-inline: 10px;
`;

const IconDesc = styled.p`
  margin: 0;
  margin-left: 10px;
  margin-bottom: 50px;
  font-size: 9px;
  line-height: 1.4em;
`;

const Desc = styled.p`
  margin: 10px 0 10px 10px;
  font-size: 11px;
  line-height: 1.4em;
`;

const Buttons = styled.div`
  display: flex;
  gap: 10px;
  white-space: nowrap;
  margin-right: 40px;
  margin-bottom: 50px;
`;

const UrlGroup = styled.div`
  position: relative;
  text-align: left;
  display: grid;
  grid-template-areas: 'input icon';
  grid-template-columns: 90% 10%;

  > div:first-of-type {
    margin-bottom: 18px;
  }
`;

const SubmitButtonContainer = styled.div`
  padding: 15px;
  text-align: center;
`;

const SlackTestButtonContainer = styled.div`
  text-align: right;
  margin: 20px 20px 0 0;
`;

/**
 * ワークグループ詳細
 */
export const CompanyEditForm: React.FC = () => {
  const {
    user,
    showSnackbar,
    control,
    errors,
    requesting,
    snackbarMessage,
    onImageUpload,
    onPicsUpload,
    onSubmit,
    setValue,
    setShowSnackbar,
    removeEmptyUrls,
    watch,
    testSlackWebhook,
  } = useCompanyEditForm();
  const navigate = useNavigate();
  const picsData = watch('pics');
  const urls = watch('urls');

  const addUrl = () => {
    urls.push('');
    setValue('urls', urls);
  };
  return (
    <Container>
      <SnackBar
        open={showSnackbar}
        setOpen={setShowSnackbar}
        message={snackbarMessage}
        callback={() => navigate('/workgroup/')}
      />
      <Card>
        <Group>
          <LabelContainer>
            <FormLabel title="ワークグループ名 ※編集不可" isRequired />
          </LabelContainer>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Wrapper>
                <InputElement {...field} disabled />
              </Wrapper>
            )}
          />
          <FormIcon isValid={true} />
        </Group>
        <Group>
          <LabelContainer>
            <FormLabel title="ワークグループ識別名" />
          </LabelContainer>
          <Controller
            name="meta_description"
            control={control}
            render={({ field }) => (
              <TextArea
                {...field}
                rows={2}
                resize="vertical"
                placeholder="ワークグループ識別名を入力"
                error={errors.meta_description?.message}
              />
            )}
          />
          <FormIcon isValid={!errors.meta_description} />
          <Desc>
            ワークグループを複数作成した場合の識別用として、プロジェクト名やチーム名を入力してご利用ください。個人ユーザーには公開されません。
          </Desc>
        </Group>
        <Group>
          <LabelContainer>
            <FormLabel title="郵便番号" isRequired />
          </LabelContainer>
          <Controller
            name="zipcode"
            control={control}
            render={({ field }) => (
              <Input {...field} placeholder="111-1111" error={errors.zipcode?.message} />
            )}
          />
          <FormIcon isValid={!errors.zipcode} />
        </Group>
        <Group>
          <LabelContainer>
            <FormLabel title="御社所在地" isRequired />
          </LabelContainer>
          <Controller
            name="address"
            control={control}
            render={({ field }) => (
              <Input {...field} placeholder="渋谷区東2-25-3" error={errors.address?.message} />
            )}
          />
          <FormIcon isValid={!errors.address} />
        </Group>
        <Group>
          <LabelContainer>
            <FormLabel title="コーポレートサイトURL" isRequired />
          </LabelContainer>
          <Controller
            name="url"
            control={control}
            render={({ field }) => (
              <Input {...field} placeholder="https://spready.co.jp" error={errors.url?.message} />
            )}
          />
          <FormIcon isValid={!errors.url} />
        </Group>
        <LabelContainer>
          <FormLabel title="企業ロゴ（アイコン画像）" />
        </LabelContainer>
        <IconWrapper>
          <ImageUpload
            imagePath={watch('icon_image')}
            directory="profile"
            fileName="icon"
            fileCategory="company"
            disabled={requesting}
            withPreview
            onComplete={onImageUpload}
          />
        </IconWrapper>
        <IconDesc>推奨サイズ：横200px以上、縦200px以上。正方形画像</IconDesc>
        <Group>
          <LabelContainer>
            <FormLabel title="利用企業ご紹介サイトへの企業ロゴの掲載許可" />
          </LabelContainer>
          <Controller
            name="logo_display_enable"
            control={control}
            render={({ field }) => (
              <Checkbox
                id="logo_display_enable"
                checked={field.value}
                onChange={() => field.onChange(!field.value)}
              >
                使用可
              </Checkbox>
            )}
          />
          <FormIcon isValid={!errors.about_company} />
        </Group>

        <Group>
          <LabelContainer>
            <FormLabel title="御社代表者氏名" />
          </LabelContainer>
          <Controller
            name="president_name"
            control={control}
            render={({ field }) => <Input {...field} error={errors.president_name?.message} />}
          />
          <FormIcon isValid={!errors.president_name} />
        </Group>
        <Group>
          <LabelContainer>
            <FormLabel title="資本金（万円）" />
          </LabelContainer>
          <Controller
            name="capital"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                value={field.value ?? ''}
                error={errors.capital?.message}
                placeholder="1000"
              />
            )}
          />
          <FormIcon isValid={!errors.capital} />
        </Group>
        <Group>
          <LabelContainer>
            <FormLabel title="従業員数" />
          </LabelContainer>
          <Controller
            name="employee"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                value={field.value ?? ''}
                error={errors.employee?.message}
                placeholder="30"
              />
            )}
          />
          <FormIcon isValid={!errors.employee} />
        </Group>
        <Group>
          <LabelContainer>
            <FormLabel title="会社説明（セッションの下部に表示されます）" />
          </LabelContainer>
          <Controller
            name="about_company"
            control={control}
            render={({ field }) => (
              <TextArea {...field} placeholder="会社説明" error={errors.about_company?.message} />
            )}
          />
          <FormIcon isValid={!errors.about_company} />
          <Desc>改行が使えます。URLはリンクされます。</Desc>
        </Group>
        <LabelContainer>
          <FormLabel title="会社説明画像1" />
        </LabelContainer>
        <IconWrapper>
          <ImageUpload
            imagePath={picsData[0] ?? ''}
            directory="company"
            fileName="company_pic1"
            fileCategory="company"
            placeholder="image"
            aspectRatio={16 / 9}
            width={480}
            height={270}
            disabled={requesting}
            withPreview
            isSquare
            onComplete={(path) => onPicsUpload(path, 0)}
          />
        </IconWrapper>
        <IconDesc>推奨サイズ：横480px以上、縦270px以上。縦:横 = 16:9</IconDesc>
        <LabelContainer>
          <FormLabel title="会社説明画像2" />
        </LabelContainer>
        <IconWrapper>
          <ImageUpload
            imagePath={picsData[1] ?? ''}
            directory="company"
            fileName="company_pic2"
            fileCategory="company"
            placeholder="image"
            aspectRatio={16 / 9}
            width={480}
            height={270}
            disabled={requesting}
            withPreview
            isSquare
            onComplete={(path) => onPicsUpload(path, 1)}
          />
        </IconWrapper>
        <IconDesc>推奨サイズ：横480px以上、縦270px以上。縦:横 = 16:9</IconDesc>

        <LabelContainer>
          <FormLabel title="企業紹介URL" />
          <Desc>記事やブログなど御社のことが伝わるURLを記載してください。</Desc>
        </LabelContainer>

        {urls.map((_url, index) => (
          <UrlGroup key={index}>
            <Controller
              name={`urls.${index}`}
              control={control}
              render={({ field }) => (
                <Input {...field} placeholder="URL" error={errors.urls?.[index]?.message} />
              )}
            />
            <FormIcon isValid={!errors.urls?.[index]} />
            <br />
          </UrlGroup>
        ))}

        <Buttons>
          <Button
            disabled={requesting}
            button_type="weak_red"
            width="100%"
            onClick={removeEmptyUrls}
          >
            - 未入力項目を削除
          </Button>
          <Button disabled={requesting} button_type="gray" width="100%" onClick={addUrl}>
            + 追加
          </Button>
        </Buttons>
        <Group>
          <LabelContainer>
            <FormLabel title="企業のコピーや理念など" />
          </LabelContainer>
          <Controller
            name="copy"
            control={control}
            render={({ field }) => <Input {...field} error={errors.copy?.message} />}
          />
          <FormIcon isValid={!errors.copy} />
        </Group>
        <Group>
          <LabelContainer>
            <FormLabel title="HERP連携用WebHookURL" />
          </LabelContainer>
          <Controller
            name="herp_webhook_url"
            control={control}
            render={({ field }) => <Input {...field} error={errors.herp_webhook_url?.message} />}
          />
          <FormIcon isValid={!errors.herp_webhook_url} />
        </Group>
        <Group>
          <LabelContainer>
            <FormLabel title="Slack通知用WebHookURL" />
          </LabelContainer>
          <Controller
            name="slack_webhook_url"
            control={control}
            render={({ field }) => <Input {...field} error={errors.slack_webhook_url?.message} />}
          />
          <FormIcon isValid={!errors.slack_webhook_url} />
          {(user.isAdministrator || user.isSupportUser) && (
            <SlackTestButtonContainer>
              <Button
                disabled={requesting}
                button_type="dark"
                width="100px"
                height="26px"
                fontSize="10px"
                padding="0 10px"
                onClick={testSlackWebhook}
              >
                通知テストをする
              </Button>
            </SlackTestButtonContainer>
          )}
        </Group>
        {(user.isAdministrator || user.isSupportUser) && (
          <SubmitButtonContainer>
            <Button disabled={requesting} button_type="transparent_blue" onClick={onSubmit}>
              保存
            </Button>
            {Object.keys(errors).length > 0 && <div>入力が不足しているか誤りがあります</div>}
          </SubmitButtonContainer>
        )}
      </Card>
    </Container>
  );
};
