import {
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
  ICameraVideoTrack,
  NetworkQuality,
  RemoteStreamType,
  UID,
  useRemoteUsers,
  VideoEncoderConfiguration,
} from 'agora-rtc-react';
import { useEffect, useState } from 'react';

export const defaultVideoEncoderConfig = {
  width: 640,
  height: 480,
  frameRate: 15,
  bitrateMax: 400,
  bitrateMin: 400,
} as const satisfies VideoEncoderConfiguration;

const lowQualityVideoEncoderConfig = {
  width: 320,
  height: 240,
  frameRate: 15,
  bitrateMax: 200,
  bitrateMin: 50,
} as const satisfies VideoEncoderConfiguration;

/**
 * ローカルからpublishする自分のカメラ映像と、リモートから受け取る他者のカメラ映像の品質をネットワーク状態に応じて調整する
 * ネットワークの状態が悪い時は、解像度・フレームレート・ビットレートを低い値にする
 */
export const useAdaptiveVideoQuality = ({
  client,
  joinCompleted,
  localCameraTrack,
}: {
  client: IAgoraRTCClient;
  joinCompleted: boolean;
  localCameraTrack: ICameraVideoTrack | null;
}) => {
  const remoteUsers = useRemoteUsers();
  const [downlinkNetworkIsWeak, setDownlinkNetworkIsWeak] = useState(false);
  const [uplinkNetworkIsWeak, setUplinkNetworkIsWeak] = useState(false);
  const [dualStreamModeEnabled, setDualStreamModeEnabled] = useState(false);

  // ネットワークの状態が変わったイベント受け取るハンドラを設定
  useEffect(() => {
    const handler = (event: NetworkQuality) => {
      // ネットワークの状態が悪い(=3以上)かどうか判定してstate更新
      // ref: https://api-ref.agora.io/en/video-sdk/web/4.x/interfaces/networkquality.html
      setDownlinkNetworkIsWeak(event.downlinkNetworkQuality >= 3);
      setUplinkNetworkIsWeak(event.uplinkNetworkQuality >= 3);
    };

    client.on('network-quality', handler);

    return () => {
      client.off('network-quality', handler);
    };
  }, []);

  // ネットワークの状態(下り)が良くなった or 悪くなった時の処理
  useEffect(() => {
    if (!joinCompleted) {
      return;
    }

    if (downlinkNetworkIsWeak) {
      onDownlinkNetworkBecameWeak(remoteUsers.map((ru) => ru.uid));
    } else {
      onDownlinkNetworkBecameStrong(remoteUsers.map((ru) => ru.uid));
    }
  }, [downlinkNetworkIsWeak, remoteUsers, joinCompleted]);

  // ネットワークの状態(上り)が良くなった or 悪くなった時の処理
  useEffect(() => {
    if (!joinCompleted || !localCameraTrack || !localCameraTrack.isPlaying) {
      return;
    }

    if (uplinkNetworkIsWeak) {
      onUplinkNetworkBecameWeak();
    } else {
      onUplinkNetworkBecameStrong();
    }
  }, [uplinkNetworkIsWeak, joinCompleted, localCameraTrack, localCameraTrack?.isPlaying]);

  const onDownlinkNetworkBecameWeak = async (
    remoteUsersUidList: Array<IAgoraRTCRemoteUser['uid']>,
  ) => {
    // 他の参加者から受け取るカメラ映像の画質を落とす
    for (const remoteUserUid of remoteUsersUidList) {
      // 1は低品質. ref: https://api-ref.agora.io/en/video-sdk/web/4.x/enums/remotestreamtype.html
      changeRemoteVideoStreamType(remoteUserUid, 1);
    }
  };

  const onDownlinkNetworkBecameStrong = async (
    remoteUsersUidList: Array<IAgoraRTCRemoteUser['uid']>,
  ) => {
    // 他の参加者から受け取るカメラ映像の画質を高品質に戻す
    for (const remoteUserUid of remoteUsersUidList) {
      // 0は高品質. ref: https://api-ref.agora.io/en/video-sdk/web/4.x/enums/remotestreamtype.html
      changeRemoteVideoStreamType(remoteUserUid, 0);
    }
  };

  const onUplinkNetworkBecameWeak = async () => {
    // ネットワーク使用量を節約するためにdual-stream modeを無効化
    await deactivateDualStreamMode();

    // 自身のカメラ映像の画質を落とす
    await changeLocalCameraTrackQuality(lowQualityVideoEncoderConfig);
  };

  const onUplinkNetworkBecameStrong = async () => {
    // dual-stream modeを有効化
    await activateDualStreamMode();

    // publishする自身のカメラ映像の画質をデフォルトに戻す
    await changeLocalCameraTrackQuality(defaultVideoEncoderConfig);
  };

  /**
   * dual-stream modeを有効化する
   * ローカルからpublishする自分のカメラ映像が2種類(高画質と低画質)送信されるようになる
   * 受信側がネットワークの状態を見て必要に応じて受信する映像の品質を切り替えられるようにするため
   */
  const activateDualStreamMode = async () => {
    if (dualStreamModeEnabled) {
      return;
    }

    // 通話参加直後(正確にはLocalCameraTrackのplay開始直後)にdual-stream modeの切り替えをするとエラーになることがあるので固定でsleepする
    await new Promise((resolve) => setTimeout(resolve, 1000));

    try {
      await client.enableDualStream();
    } catch (e: unknown) {
      const isAlreadyEnabled =
        e instanceof Error && e.message.includes('Dual stream is already enabled');
      console.warn('dual-stream, isAlreadyEnabled=', isAlreadyEnabled);
      if (!isAlreadyEnabled) {
        throw e;
      }
    }

    // 低画質映像の品質を設定する
    client.setLowStreamParameter({
      width: { max: lowQualityVideoEncoderConfig.width, min: lowQualityVideoEncoderConfig.width },
      height: {
        max: lowQualityVideoEncoderConfig.height,
        min: lowQualityVideoEncoderConfig.height,
      },
      bitrate: lowQualityVideoEncoderConfig.bitrateMin,
      framerate: lowQualityVideoEncoderConfig.frameRate,
    });
    setDualStreamModeEnabled(true);
  };

  const deactivateDualStreamMode = async () => {
    if (!dualStreamModeEnabled) {
      return;
    }

    await client.disableDualStream();
    setDualStreamModeEnabled(false);
  };

  const changeRemoteVideoStreamType = async (uid: UID, remoteStreamType: RemoteStreamType) => {
    await client.setRemoteVideoStreamType(uid, remoteStreamType);
  };

  const changeLocalCameraTrackQuality = async (config: VideoEncoderConfiguration) => {
    await localCameraTrack?.setEncoderConfiguration(config);
  };
};
