import VirtualBackgroundExtension, {
  IVirtualBackgroundProcessor,
} from 'agora-extension-virtual-background';
import AgoraRTC, { ICameraVideoTrack } from 'agora-rtc-react';
import { useEffect, useRef, useState } from 'react';

export const useVideoBlur = ({
  localCameraTrack,
}: {
  localCameraTrack: ICameraVideoTrack | null;
}) => {
  const [extensionActive, setExtensionActive] = useState(false);
  const backgroundExt = useRef(new VirtualBackgroundExtension());
  const processor = useRef<IVirtualBackgroundProcessor>();

  const enableBackground = async () => {
    if (processor.current && localCameraTrack) {
      console.log('enableBackground');
      localCameraTrack.pipe(processor.current).pipe(localCameraTrack.processorDestination);
      processor.current.setOptions({ type: 'blur', blurDegree: 2 });
      await processor.current.enable();
      setExtensionActive(true);
    }
  };

  useEffect(() => {
    if (!localCameraTrack) {
      return;
    }
    const initExtension = async () => {
      AgoraRTC.registerExtensions([backgroundExt.current]);
      processor.current = backgroundExt.current.createProcessor();
      await processor.current.init();
    };

    initExtension();

    enableBackground();

    return () => {
      processor.current?.unpipe();
      localCameraTrack?.unpipe();
    };
  }, [localCameraTrack]);

  const disableBackground = async () => {
    if (processor.current && localCameraTrack) {
      localCameraTrack.unpipe();
      await processor.current.disable();
      setExtensionActive(false);
    }
  };

  return {
    enableBackground,
    disableBackground,
    extensionActive,
  };
};
