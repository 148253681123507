import styled from '@emotion/styled';
import { User } from 'domain/entities/User/User';
import { UserIcon } from 'ui/components/company/elements/UserIcon/UserIcon';
import { SimpleToolTip } from 'ui/components/company/features/SimpleToolTip/SimpleToolTip';

const UserIconContainer = styled.div`
  ${(props: { width?: string }) => props.width && `width: ${props.width};`}
  display: flex;
`;

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Icon = styled.div`
  padding-right: 2px;
`;

const SlimName = styled.div`
  width: calc(100% - 30px);
  span {
    ${(props: { isBold?: boolean }) => props.isBold && 'font-weight: 700;'}
  }

  span:nth-of-type(2) {
    font-size: 9px;
  }
`;

export const UserItem: React.FC<{
  user: User;
  viewType?: 'slim' | 'iconOnly';
  realName?: boolean;
  isBold?: boolean;
  showPrefix?: boolean;
}> = ({ user, viewType = 'slim', realName = false, isBold = false, showPrefix = false }) => {
  const showName = realName
    ? `${user.last_name}${user.first_name}`
    : user?.spreader_name ?? `${user.last_name}${user.first_name}`;

  return (
    <>
      {viewType === 'iconOnly' && (
        <ItemContainer>
          <Icon>
            <SimpleToolTip tooltipText={showName} fontSize="10px">
              <UserIconContainer>
                <UserIcon src={user.icon_image} size={28} />
              </UserIconContainer>
            </SimpleToolTip>
          </Icon>
        </ItemContainer>
      )}
      {viewType === 'slim' && (
        <ItemContainer>
          <Icon>
            <SimpleToolTip tooltipText={showName} fontSize="10px">
              <UserIconContainer>
                <UserIcon src={user.icon_image ?? undefined} size={28} />
              </UserIconContainer>
            </SimpleToolTip>
          </Icon>
          <SlimName isBold={isBold}>
            <span>{showName}</span>
            {showPrefix && <span>さん</span>}
          </SlimName>
        </ItemContainer>
      )}
    </>
  );
};
