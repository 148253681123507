import { useCallback, useEffect, useState, useContext } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'utils/yup';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { CompanyEditRepository } from 'interfaceAdapter/repositories/CompanyEdit';
import { NewTeamRepository } from 'interfaceAdapter/repositories/NewTeam';

const schema = yup
  .object()
  .shape({
    name: yup.string().default('').optional(),
    meta_description: yup.string().default('').max(255),
    corporate_number: yup.string().required(),
    copy: yup.string().default('').optional().max(100),
    address: yup.string().default('').required(),
    zipcode: yup.string().default('').required(),
    url: yup
      .string()
      .matches(/^(http(s)?:\/\/.*)?$/, '正しく入力してください')
      .default('')
      .required()
      .max(200),
    president_name: yup.string().default('').optional().max(100),
    capital: yup
      .number()
      .transform((convertedValue, value) => (value === '' ? null : convertedValue))
      .optional()
      .nullable()
      .typeError('正しく入力してください'),
    employee: yup
      .number()
      .transform((convertedValue, value) => (value === '' ? null : convertedValue))
      .optional()
      .nullable()
      .typeError('正しく入力してください'),
    about_company: yup.string().default('').optional().max(2000),
    icon_image: yup.string().default('').optional(),
    logo_display_enable: yup.boolean().default(true).required(),
    herp_webhook_url: yup
      .string()
      .matches(/^(http(s)?:\/\/.*)?$/, '正しく入力してください')
      .default('')
      .optional()
      .max(255),
    pics: yup.array(yup.string().required()).default([]).required(),
    urls: yup
      .array(
        yup.object({
          text: yup
            .string()
            .matches(/^(http(s)?:\/\/.*)?$/, '正しく入力してください')
            .default(''),
        }),
      )
      .default([{ text: '' }]),
    slack_webhook_url: yup
      .string()
      .matches(/^(http(s)?:\/\/.*)?$/, '正しく入力してください')
      .default('')
      .optional()
      .max(255),
  })
  .required();
type Form = yup.InferType<typeof schema>;

export const useTeamProfileSetup = () => {
  const diContainer = useContext(diContainerContext);
  const companyEditRepository = diContainer.resolve(CompanyEditRepository);
  const teamCreateRepository = diContainer.resolve(NewTeamRepository);
  const [loading, setLoading] = useState(true);
  const [requesting, setRequesting] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const navigate = useNavigate();

  const {
    control,
    setValue,
    watch,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<Form>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const arrayFields = {
    urls: useFieldArray({ control, name: 'urls' }),
  };

  const appendField = useCallback((field: 'urls') => {
    arrayFields[field].append({ text: '' });
  }, []);

  const removeEmptyFields = useCallback((field: 'urls') => {
    // 削除対象のindex取得
    const target = getValues(field).reduce<number[]>((acc, field, index) => {
      if (index > 0 && field.text === '') {
        return [...acc, index];
      }
      return acc;
    }, []);
    // 後ろから削除
    target.reverse().forEach((index) => arrayFields[field].remove(index));
  }, []);

  const onSubmit = handleSubmit(async (data) => {
    setRequesting(true);
    try {
      const urls = getValues('urls');
      const filteredUrls = urls.filter((url) => url.text !== '');

      const teamProfile = {
        ...data,
        urls: filteredUrls.map((url) => url.text),
        capital: data.capital ? data.capital : null,
        employee: data.employee ? data.employee : null,
        copy: data.copy ? data.copy : null,
        president_name: data.president_name ? data.president_name : null,
        herp_webhook_url: data.herp_webhook_url ? data.herp_webhook_url : null,
        slack_webhook_url: data.slack_webhook_url ? data.slack_webhook_url : null,
      };
      teamCreateRepository.setTeamProfile(teamProfile);
      navigate('/workgroup/create/confirm/');
    } finally {
      setRequesting(false);
    }
  });

  const onPicsUpload = useCallback(async (fileUrl: string, index: number) => {
    const pics = getValues('pics');

    if (pics[index]) {
      pics[index] = fileUrl;
    } else {
      pics.push(fileUrl);
    }

    setValue('pics', pics);
  }, []);

  const testSlackWebhook = async () => {
    try {
      setRequesting(true);
      const url = getValues('slack_webhook_url');
      const res = await companyEditRepository.testSlackWebhook(url);
      setSnackbarMessage(
        res
          ? '送信しました。届いていない場合はURLが正しいかご確認下さい'
          : '失敗しました。時間を置いて再度お試し下さい',
      );
      setShowSnackbar(true);
    } finally {
      setRequesting(false);
    }
  };

  useEffect(() => {
    const initialFetch = async () => {
      const result = await teamCreateRepository.get();
      if (result) {
        setValue('name', result.name);
        setValue('meta_description', result.meta_description);
        setValue('zipcode', result.zipcode ?? '');
        setValue('address', result.address ?? '');
        setValue('corporate_number', result.corporate_number ?? '');
        setValue('url', result.url ?? '');
        setValue('president_name', result.president_name ?? '');
        setValue('capital', result.capital ?? '');
        setValue('employee', result.employee ?? '');
        setValue('about_company', result.about_company ?? '');
        setValue('icon_image', result.icon_image ?? '');
        setValue('herp_webhook_url', result.herp_webhook_url ?? '');
        setValue('slack_webhook_url', result.slack_webhook_url ?? '');
        setValue('pics', result.pics ?? []);
        setValue(
          'urls',
          result.urls ? result.urls.map((url: string) => ({ text: url })) : [{ text: '' }],
        );
        setValue('logo_display_enable', result.logo_display_enable ?? true);
        setValue('copy', result.copy ?? '');
      } else {
        navigate('/workgroup/create/');
      }
      setLoading(false);
    };
    initialFetch();
  }, []);

  return {
    loading,
    requesting,
    errors,
    control,
    showSnackbar,
    snackbarMessage,
    arrayFields,
    watch,
    setValue,
    onSubmit,
    onPicsUpload,
    testSlackWebhook,
    setShowSnackbar,
    appendField,
    removeEmptyFields,
  };
};
