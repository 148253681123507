import { Controller } from 'react-hook-form';
import styled from '@emotion/styled';
import { IProject } from 'domain/entities/Project/Project';
import { useWritingMatchPointForm } from './useWritingMatchPointForm';
import { ValidationError } from 'ui/components/user/elements/ValidationError/ValidationError';
import { TextArea } from 'ui/components/user/elements/TextArea/TextArea';
import { Input } from 'ui/components/user/elements/Input/Input';
import { Checkbox } from 'ui/components/user/elements/Checkbox/Checkbox';
import { Button } from 'ui/components/user/elements/Button/Button';
import { FormLabel } from 'ui/components/user/elements/FormLabel/FormLabel';
import { mqUser } from 'utils/responsive';

const Container = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
`;

const Description = styled.div`
  color: #1e1e1e;
  font-size: 13.7px;
  line-height: 1.6;
`;

const Break = styled.br`
  ${mqUser('SP')} {
    display: none;
  }
`;

const Hidden = styled.div`
  display: none;
`;

const Detail = styled.div`
  color: var(--gray-dark-2);
  font-size: 14px;
  line-height: 1.6;
  white-space: pre-wrap;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin: 0 auto;
  gap: 16px;
  width: 100%;
  justify-content: space-between;
`;

const NeedLogin = styled.div`
  color: #1e1e1e;
  text-align: center;
  font-size: 13px;
  font-weight: var(--font-weight--bolder);
  line-height: 1.6;
  margin-top: 20px;
`;

const ButtonWithText = styled.div`
  color: #1e1e1e;
  text-align: center;
  font-size: 12px;
  line-height: 1.6;
  gap: 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const MatchPoints = styled.div`
  gap: 15px;
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 10px;
  }
`;

const Warning = styled.p`
  margin: -16px 0 0;
  color: var(--spr-warn);
  text-align: center;
`;

/**
 * 招待URLから遷移した際、マッチポイントを記載するフォーム
 */
export const WritingMatchPointForm: React.FC<{
  project: IProject;
}> = ({ project }) => {
  const {
    loading,
    isLogin,
    control,
    formState: { errors },
    applyViaIntroduction,
    goToLogin,
    goToRegister,
    handleCheckboxChange,
    checkedStates,
    hashKeys,
  } = useWritingMatchPointForm(project);

  return (
    <>
      <Container>
        <FormLabel title="マッチポイント" isRequired />
        <Description>当てはまる項目にチェックを入れ、補足する説明を記載してください。</Description>
        <Hidden>
          <Controller
            name="projectId"
            control={control}
            defaultValue={project.id ? project.id : undefined}
            render={({ field }) => (
              <Input {...field} type="hidden" value={project.id ? project.id : ''} />
            )}
          />
        </Hidden>

        {project.match_points && project.match_points.length > 0 && (
          <MatchPoints>
            {project.match_points.map((match_point, index) => {
              return (
                <div key={index}>
                  <Controller
                    name={`matchPoints.${index}.checked`}
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        id={`matchPoints${index}`}
                        checked={field.value}
                        disabled={project.has_self_applied}
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                          handleCheckboxChange(index, e.target.checked);
                        }}
                      >
                        {match_point}
                      </Checkbox>
                    )}
                  />
                  {checkedStates[hashKeys[index]] && (
                    // チェックされた場合のみテキストエリアを表示
                    <Controller
                      name={`matchPoints.${index}.answer`}
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextArea
                          {...field}
                          rows={6}
                          fontSize="14px"
                          resize="vertical"
                          placeholderColor="#C4C4C4"
                          bgColor="#FBFAFA"
                          maxLength={400}
                          showLength
                          disabled={project.has_self_applied}
                        />
                      )}
                    />
                  )}
                  {errors.matchPoints && (
                    <ValidationError message={errors.matchPoints[index]?.answer?.message} />
                  )}
                </div>
              );
            })}
            {errors.matchPoints?.root && (
              <ValidationError message={errors.matchPoints.root.message} />
            )}
          </MatchPoints>
        )}
      </Container>

      {project?.match_point_text && (
        <Container>
          <FormLabel title="詳細内容" isRequired />
          <Description>以下について担当の方は教えて欲しいと思っています。</Description>
          <Detail>{project.match_point_text}</Detail>

          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <TextArea
                {...field}
                id="description"
                rows={8}
                fontSize="14px"
                resize="vertical"
                bgColor="#FBFAFA"
                maxLength={400}
                showLength
                disabled={project.has_self_applied}
              />
            )}
          />
          {errors.description && <ValidationError message={errors.description.message} />}
        </Container>
      )}
      {isLogin ? (
        <>
          <Button
            status="primary"
            width="auto"
            padding="16px"
            fontSize="19px"
            disabled={loading || project.has_self_applied}
            onClick={applyViaIntroduction}
          >
            送信する
          </Button>
          {project.has_self_applied && <Warning>このセッションに応募済みです</Warning>}
        </>
      ) : (
        <>
          <NeedLogin>
            上記の内容でセッションに応募するために、
            <Break />
            Spreadyにログインする必要があります。
          </NeedLogin>
          <ButtonContainer>
            <ButtonWithText>
              初めてご利用の方
              <Button status="primary" fontSize="14px" padding="16px 10px" onClick={goToRegister}>
                プロフィール入力して応募
              </Button>
            </ButtonWithText>
            <ButtonWithText>
              Spreadyアカウントをお持ちの方
              <Button status="primary" fontSize="14px" padding="16px 10px" onClick={goToLogin}>
                ログインして応募
              </Button>
            </ButtonWithText>
          </ButtonContainer>
        </>
      )}
    </>
  );
};
