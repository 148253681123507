import { yupResolver } from '@hookform/resolvers/yup';
import { authContext } from 'application/contexts/useAuth';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { CastCodeRepository } from 'interfaceAdapter/repositories/CastCode';
import { MatchPointsRepository } from 'interfaceAdapter/repositories/MatchPoints';
import { useContext, useEffect, useState } from 'react';
import { Control, FormState, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import yup from 'utils/yup';
import { generateHashKey } from 'utils/string';
import { IProject } from 'domain/entities/Project/Project';
import { ApplicationRepository } from 'interfaceAdapter/repositories/Application';

const schema = yup
  .object({
    projectId: yup.number().required(),
    matchPoints: yup
      .array()
      .of(
        yup
          .object({
            question: yup.string().required(),
            answer: yup
              .string()
              .defined()
              .when('checked', {
                is: (checked: boolean) => checked,
                then: (schema) => schema.required(),
              }),
            checked: yup.boolean().required().default(false),
          })
          .required(),
      )
      .required()
      .test('checked-at-least-one', 'マッチポイントを1つ以上選択してください', (array) =>
        array.some((item) => item.checked),
      ),
    description: yup.string().required().default(''),
  })
  .required();

type ApplicationForm = yup.InferType<typeof schema>;

interface Return {
  /** フォームのコントロール */
  control: Control<ApplicationForm>;
  /** フォームの状態 */
  formState: FormState<ApplicationForm>;
  /** 処理中 */
  loading: boolean;
  /** ログイン中 */
  isLogin: boolean;
  /** 招待で応募する処理 */
  applyViaIntroduction: () => Promise<void>;
  /** ログインに遷移する処理 */
  goToLogin: () => Promise<void>;
  /** 新規登録に遷移する処理 */
  goToRegister: () => Promise<void>;
  handleCheckboxChange: (index: number, isChecked: boolean) => void;
  checkedStates: { [key: string]: boolean };
  hashKeys: { [index: string]: string };
}

export const useWritingMatchPointForm = (project: IProject): Return => {
  const navigate = useNavigate();
  const params = useParams();
  const castCode = params.castCode;
  if (!castCode) {
    throw new Error('castCodeが指定されていません');
  }

  const diContainer = useContext(diContainerContext);
  const applicationRepository = diContainer.resolve(ApplicationRepository);
  const castCodeRepository = diContainer.resolve(CastCodeRepository);
  const matchPointsRepositoryFactory =
    diContainer.resolve<(castCode: string) => MatchPointsRepository>('IMatchPointsRepository');

  const { checkToken } = useContext(authContext);
  const { control, handleSubmit, formState, setValue, clearErrors } = useForm<ApplicationForm>({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  });

  const [loading, setLoading] = useState(false);
  const [isLogin, setIsLogin] = useState(false);

  const checkCanEnter = async () => {
    setIsLogin(await checkToken(false));
  };

  const applyViaIntroduction = handleSubmit(async ({ matchPoints, description }) => {
    const filteredMatchPoints = matchPoints
      .filter((item) => item.checked && item.answer)
      .map((item) => ({
        question: item.question,
        answer: item.answer,
      }));

    try {
      await applicationRepository.applyViaIntroduction({
        description,
        inviteCode: castCode,
        matchPoints: filteredMatchPoints,
      });
      castCodeRepository.set(castCode);
      navigate(`/cast-complete/?redirect=/user/casting/casted/`);
    } catch (e) {
      // エラー通知に気づかせるため
      window.scrollTo({ top: 0, behavior: 'smooth' });
      throw e;
    } finally {
      setLoading(false);
    }
  });

  const goToLogin = handleSubmit(async ({ matchPoints, description }) => {
    const res = saveSessionStorage(matchPoints, description);
    if (!res) {
      return;
    }
    navigate(`/login/?cast_code=${castCode}`);
  });

  const goToRegister = handleSubmit(async ({ matchPoints, description }) => {
    const res = saveSessionStorage(matchPoints, description);
    if (!res) {
      return;
    }
    navigate(`/register/`);
  });

  // 紹介に必要なデータをストレージに保存
  const saveSessionStorage = (
    matchPoints: { question: string; answer: string; checked: boolean }[],
    description: string,
  ) => {
    if (!castCode) {
      return false;
    }
    const filteredMatchPoints = matchPoints
      .filter((item) => item.checked && item.answer)
      .map((item) => ({
        question: item.question,
        answer: item.answer,
      }));

    // 紹介に必要なデータをストレージに格納
    castCodeRepository.set(castCode);
    const matchPointsRepository = matchPointsRepositoryFactory(castCode);
    matchPointsRepository.set(filteredMatchPoints, description);
    return true;
  };

  useEffect(() => {
    checkCanEnter();
  }, []);

  const [checkedStates, setCheckedStates] = useState<{ [key: string]: boolean }>({});
  const [hashKeys, setHashKeys] = useState<{ [index: string]: string }>({});

  useEffect(() => {
    const createHashKeys = async () => {
      if (!project.match_points) return;
      const keys: { [index: string]: string } = {};

      for (let i = 0; i < project.match_points.length; i++) {
        const hashKey = await generateHashKey(project.match_points[i], i);
        keys[i.toString()] = hashKey;
      }
      setHashKeys(keys);

      const formFields = project.match_points.map((match_point, index) => {
        return {
          question: match_point,
          answer: '',
          checked: false,
          hash: generateHashKey(match_point, index),
        };
      });
      setValue('matchPoints', formFields);
    };

    if (project.match_points) {
      createHashKeys();
    }
  }, [project.match_points]);

  const handleCheckboxChange = (index: number, isChecked: boolean) => {
    clearErrors('matchPoints');
    const hashKey = hashKeys[index.toString()];
    if (hashKey) {
      setCheckedStates((prev) => ({
        ...prev,
        [hashKey]: isChecked,
      }));
    }
  };

  return {
    control,
    formState,
    loading,
    isLogin,
    applyViaIntroduction,
    goToLogin,
    goToRegister,
    handleCheckboxChange,
    checkedStates,
    hashKeys,
  };
};
