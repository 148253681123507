import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  ICreateVideoDownloadLinkClient,
  IPostInput,
  IPostResponse,
} from 'interfaceAdapter/gatewaySchemas/ICreateVideoDownloadLinkClient';

export class CreateVideoDownloadLinkClient implements ICreateVideoDownloadLinkClient {
  async post(input: IPostInput): Promise<IPostResponse> {
    const PATH = `${API_LOCATION_SPREADY}/video_call/video_call_recording_files/${input.video_call_recording_file_id}/create_download_link`;
    const { token } = input;

    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };

    try {
      const response = await fetch(`${PATH}`, {
        method,
        headers,
      });
      const { message, data } = await response.json();

      if (!response.ok) {
        throw new Error(message);
      }

      return {
        message,
        data,
      };
    } catch (e) {
      if (e instanceof Error) {
        throw new Error(e.message);
      }
      throw new Error('処理に失敗しました');
    }
  }
}
