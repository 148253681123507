import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'utils/yup';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { CompanyUserRepository } from 'interfaceAdapter/repositories/CompanyUser';
import { useNavigate } from 'react-router-dom';

const schema = yup
  .object({
    email: yup.string().default('').required().email(),
    agreed: yup.boolean().default(false).oneOf([true], '規約に同意してください').required(),
  })
  .required();

type SendEmailForm = yup.InferType<typeof schema>;

export const useSendEmail = () => {
  const navigate = useNavigate();
  const diContainer = useContext(diContainerContext);
  const companyUserRepository = diContainer.resolve(CompanyUserRepository);
  const [loading, setLoading] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<SendEmailForm>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      email: '',
    },
  });

  const sendMail = handleSubmit(async ({ email }) => {
    setLoading(true);
    try {
      await companyUserRepository.registerSendMail(email);
      navigate('/business/register/email/complete/');
    } finally {
      setLoading(false);
    }
  });

  return {
    control,
    errors,
    loading,
    sendMail,
  };
};
