import dayjs from 'dayjs';
import { PROJECT_TITLE_OPINION } from 'domain/entities/Project/Project';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ProjectContext } from 'ui/components/company/patterns/SessionManageForm/ProjectContext.type';
import { useProjectFormManager } from '../useProjectFormManager';

export const useDuplicatedDraft = (): ProjectContext => {
  // 下書き時点なので公開前であることを示す
  const isBeforePublished = true;

  const {
    companyUserQuery,
    createDraftProject,
    companyProjectQuery,
    setProject,
    setCompanyUsers,
    draftProjectIdReservationRepository,

    setValue,
    privacyOptions,
    projectCategoryOptions,
    companyUserOptions,
    control,
    watch,
    getValues,
    trigger,
    formState,
    arrayFields,
    appendField,
    removeEmptyFields,
    showPrompt,
    project,
    projectForPreview,
    requesting,
    snackbarMessage,
    setSnackbarMessage,
    fileName,
    draftId,
    onImageUpload,
    onSubmit,
    makePending,
    reopen,
    close,
    deleteConfirmModal,
    showDeleteConfirm,
    setShowDeleteConfirm,
    deleteDraftProject,
  } = useProjectFormManager({ isBeforePublished });

  const { projectId } = useParams();
  if (!projectId) {
    throw new Error('セッションが見つかりませんでした');
  }

  useEffect(() => {
    createDraftProject().then((createdDraftId) => {
      // 開発モードでの重複下書き作成ガードを削除
      draftProjectIdReservationRepository.remove();

      // 既存プロジェクトから情報を取ってきて下書きのステートにセット
      companyProjectQuery.get(parseInt(String(projectId), 10)).then((p) => {
        const targetPerson = `【複製】${p.target_person ?? ''}`;
        setProject({
          ...p,
          target_person: targetPerson,
        });
        setValue('start_at', p.start_at ? dayjs(p.start_at).format('YYYY/M/D') : '');
        setValue('end_at', p.end_at ? dayjs(p.end_at).format('YYYY/M/D') : '');
        setValue('management_user_ids', p.management_users?.map((u) => u.id) ?? []);
        setValue('category', p.category);
        setValue('main_pic', p.main_pic);
        setValue('target_person', targetPerson);
        setValue('topic_of_interest', p.topic_of_interest ?? '');
        setValue('title_complement_text', p.title_complement_text ?? '');
        setValue('title_opinion_type', p.title_opinion_type ?? PROJECT_TITLE_OPINION.ASK);
        setValue('targets', p.targets?.map((t) => ({ text: t })) ?? []);
        setValue('themes', p.themes?.map((t) => ({ text: t })) ?? []);
        setValue('background', p.background ?? '');
        setValue('goal', p.goal ?? '');
        setValue('outro', p.outro ?? '');
        setValue('match_points', p.match_points?.map((t) => ({ text: t })) ?? []);
        setValue('match_point_text', p.match_point_text ?? '');
        setValue('share_enabled', p.share_enable ?? true);
        setValue('interview_location', p.interview_location ?? '');
        setValue('chat_template_text', p.chat_template_text ?? '');
        setValue('summary', p.summary ?? '');
        setValue('number_of_people', p.number_of_people === null ? undefined : p.number_of_people);
        setValue('privacy', p.privacy ? 1 : 0);
        setValue('draft_id', createdDraftId);
      });
    });
    companyUserQuery.getAll().then((users) => setCompanyUsers(users));
  }, []);

  return {
    companyUserOptions,
    privacyOptions,
    categoryOptions: projectCategoryOptions,
    control,
    watch,
    getValues,
    setValue,
    trigger,
    formState,
    arrayFields,
    appendField,
    removeEmptyFields,
    showPrompt,
    project,
    projectForPreview,
    requesting,
    snackbarMessage,
    setSnackbarMessage,
    isBeforePublished,
    fileName,
    draftId,
    onImageUpload,
    onSubmit,
    makePending,
    reopen,
    close,
    deleteConfirmModal,
    showDeleteConfirm,
    setShowDeleteConfirm,
    deleteDraftProject,
  };
};
