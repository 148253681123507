import { useCallback, useContext, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { IMeetRequest } from 'domain/entities/MeetRequest/MeetRequest';
import { CompanyMeetRequestRepository } from 'interfaceAdapter/repositories/CompanyMeetRequest';
import { diContainerContext } from 'application/contexts/useDiContainer';

export interface MeetingAtProps {
  meetRequest: IMeetRequest;
  onSave: (meetRequest: IMeetRequest) => void;
}

export const useSetMeetingAt = ({ meetRequest, onSave }: MeetingAtProps) => {
  const diContainer = useContext(diContainerContext);
  const companyMeetRequestRepository = diContainer.resolve(CompanyMeetRequestRepository);

  const [requesting, setRequesting] = useState(false);
  const [date, setDate] = useState(
    meetRequest.meeting_at ? dayjs(meetRequest.meeting_at).format('YYYY/M/D') : '',
  );
  const [showSnackbar, setShowSnackbar] = useState(false);

  const disabled = useMemo(() => {
    if (requesting) {
      return true;
    }
    if (date === '' && !dayjs(date).isValid()) {
      return true;
    }
    return false;
  }, [requesting, date]);

  const save = useCallback(async () => {
    setRequesting(true);
    try {
      const res = await companyMeetRequestRepository.updateMeetingAt(
        meetRequest.id,
        date === '' ? null : dayjs(date).format('YYYY-MM-DD'),
      );
      setShowSnackbar(true);
      onSave(res.meetRequest);
    } finally {
      setRequesting(false);
    }
  }, [meetRequest, date]);

  return {
    date,
    setDate,
    showSnackbar,
    setShowSnackbar,
    disabled,
    save,
  };
};
