import { ILocalVideoTrack } from 'agora-rtc-react';
import { useEffect } from 'react';

export const LocalScreenShare = ({
  localScreenTrack,
  screenShareOn,
}: {
  localScreenTrack: ILocalVideoTrack | null;
  screenShareOn: boolean;
}) => {
  useEffect(() => {
    if (localScreenTrack) {
      localScreenTrack.play('local-screen', { fit: 'contain' });
    }
  }, [localScreenTrack]);

  if (!localScreenTrack || !localScreenTrack.enabled) {
    return null;
  }
  if (!screenShareOn) {
    return null;
  }

  return (
    <div
      id="local-screen"
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: '#101A25',
        position: 'relative',
        borderRadius: '10px',
        border: '1px solid #1e2931',
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: '50%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        Your Video Here
      </div>
    </div>
  );
};
