import { diContainerContext } from 'application/contexts/useDiContainer';
import { CompanyVideoCallRecordingRepository } from 'interfaceAdapter/repositories/CompanyVideoCallRecordingRepository';
import { VideoCallRecordingRepository } from 'interfaceAdapter/repositories/VideoCallRecordingRepository';
import { useContext, useEffect, useState } from 'react';

/**
 * joinCompletedがtrueになる(=入室が完了する)たびに録画開始リクエストをする
 * 1人目の参加者の場合は録画開始され、2人目以降の参加者の場合録画がすでに始まっているため何も起こらない
 *
 * ネットワークが不安定な場合など、一時的に接続が切れた場合に起こることは2通り
 * - チャンネル参加者が0人になって一定時間経過(※)してから、SDKのリトライ処理により再入室が走った場合
 *   -> 古いチャンネルは終了して録画も終了する。再入室処理時に新しいチャンネルが作られる
 *   -> joinCompletedがtrueになったタイミングで再度録画開始リクエストが走り、新しいチャンネルに対する録画が開始する
 * - チャンネル参加者が1人以上残っているか、0人だけどまだチャンネルが残っている状態で、SDKのリトライ処理により再入室が走った場合
 *   -> チャンネルが残っているため、録画は続いている
 *   -> joinCompletedがtrueになったタイミングで再度録画開始リクエストが走り、録画は動いているので何も起こらない
 *
 * ※ 参加者が0人になってからチャンネルが廃棄されるまでの時間は, agora apiのstart_recording実行時に渡す maxIdleTime パラメータによって決まる
 */
export const useStartRecordingAfterJoin = ({
  joinCompleted,
  channelName,
  isBusinessUser,
  leave,
}: {
  joinCompleted: boolean;
  channelName: string;
  isBusinessUser: boolean;
  leave: () => void;
}) => {
  const diContainer = useContext(diContainerContext);
  const videoCallRecordingRepository = diContainer.resolve(VideoCallRecordingRepository);
  const companyVideoCallRecordingRepository = diContainer.resolve(
    CompanyVideoCallRecordingRepository,
  );
  const [recordingStarted, setRecordingStarted] = useState(false);

  useEffect(() => {
    if (!joinCompleted) return;

    startRecording();
  }, [joinCompleted]);

  /**
   * APIに録画開始リクエストをする
   *
   * エラーが返ってきた場合はユーザーにエラーを表示して再入室を促す
   * ビデオチャットをしているのに録画がされていない状態を防ぐねらいがある
   */
  const startRecording = async () => {
    try {
      const promise = isBusinessUser
        ? companyVideoCallRecordingRepository.post({ channelName })
        : videoCallRecordingRepository.post({ channelName });
      await promise;

      setRecordingStarted(true);

      return;
    } catch (e) {
      e instanceof Error ? console.error(e.message) : console.error(e);
      leave();
      // alertの前に退出処理によるUI更新を完了させたいのでalertは次のイベントループに回す
      setTimeout(() => {
        alert('エラーが発生しました。お手数ですが再度入室をお願いいたします。');
      }, 10);
    }
  };

  return { recordingStarted };
};
