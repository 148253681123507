import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  ICompanyVideoCallRecordingClient,
  IPostInput,
  IPostResponse,
} from 'interfaceAdapter/gatewaySchemas/ICompanyVideoCallRecordingClient';

export class CompanyVideoCallRecordingClient implements ICompanyVideoCallRecordingClient {
  async post(input: IPostInput): Promise<IPostResponse> {
    const PATH = `${API_LOCATION_SPREADY}/video_call/start_recording`;
    const { token } = input;

    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };
    const body = JSON.stringify({
      channel_name: input.channelName,
    });

    try {
      const response = await fetch(`${PATH}`, {
        method,
        headers,
        body,
      });
      const { status } = response;
      const { message } = await response.json();

      if (!response.ok) {
        throw new Error(message);
      }

      return {
        message,
        status,
      };
    } catch (e) {
      if (e instanceof Error) {
        throw new Error(e.message);
      }
      throw new Error('処理に失敗しました');
    }
  }
}
