import AgoraRTM, { type RTMClient } from 'agora-rtm-sdk';
import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * Signaling = RTM (Real-Time Messaging)
 */

export type RtmPayload =
  | {
      type: 'userName';
      message: {
        uid: number;
        userName: string;
      };
    }
  | {
      type: 'ping';
      message: {
        uid: number;
        userName: string;
      };
    }
  | {
      type: 'destroy';
      message: {
        uid: number;
      };
    };

export const setupRTMClient = async ({
  uid,
  rtmUId,
  token,
  channelName,
  userName,
}: {
  uid: number;
  rtmUId: string;
  token: string;
  channelName: string;
  userName: string;
}): Promise<RTMClient> => {
  // Send a userName to a channel
  const publishMyName = async () => {
    const payload: RtmPayload = {
      type: 'userName',
      message: {
        uid,
        userName,
      },
    };
    const publishMessage = JSON.stringify(payload);
    const publishOptions = { channelType: 'MESSAGE' } as const;
    try {
      await rtm.publish(channelName, publishMessage, publishOptions);
    } catch (status) {
      console.log(status);
    }
  };

  // Initialize the RTM client.
  if (!process.env.REACT_APP_AGORA_APP_ID) {
    throw new Error('REACT_APP_AGORA_APP_ID is not set');
  }
  const rtm = new AgoraRTM.RTM(process.env.REACT_APP_AGORA_APP_ID, rtmUId);
  // Log in the RTM server.
  try {
    const result = await rtm.login({ token });
    console.log({ loginResult: result });
    publishMyName();
  } catch (status) {
    console.log('appId', process.env.REACT_APP_AGORA_APP_ID);
    console.log({ status });
  }
  // Subscribe to a channel.
  try {
    await rtm.subscribe(channelName);
  } catch (status) {
    console.log(status);
  }

  return rtm;
};

export const useSignaling = (args: {
  channelName: string;
  token: string;
  rtmUid: string;
  userName: string;
  uid: number;
  onUserNameReceived: (payload: Extract<RtmPayload, { type: 'userName' }>['message']) => void;
}) => {
  const [rtm, setRtm] = useState<RTMClient | null>(null);
  const init = useRef(false);

  const publishDestroy = useCallback(async () => {
    if (!rtm) {
      return;
    }
    const payload: RtmPayload = {
      type: 'destroy',
      message: {
        uid: args.uid,
      },
    };
    const publishMessage = JSON.stringify(payload);
    const publishOptions = { channelType: 'MESSAGE' } as const;
    try {
      await rtm.publish(args.channelName, publishMessage, publishOptions);
    } catch (status) {
      console.log(status);
    }
  }, [rtm]);

  const publishMyName = async (args: {
    rtm: RTMClient;
    uid: number;
    userName: string;
    channelName: string;
  }) => {
    const payload: RtmPayload = {
      type: 'userName',
      message: {
        uid: args.uid,
        userName: args.userName,
      },
    };
    const publishMessage = JSON.stringify(payload);
    const publishOptions = { channelType: 'MESSAGE' } as const;
    try {
      await args.rtm.publish(args.channelName, publishMessage, publishOptions);
    } catch (status) {
      console.log(status);
    }
  };

  const publishPing = async (args: {
    rtm: RTMClient;
    uid: number;
    userName: string;
    channelName: string;
  }) => {
    const payload: RtmPayload = {
      type: 'ping',
      message: {
        uid: args.uid,
        userName: args.userName,
      },
    };
    const publishMessage = JSON.stringify(payload);
    const publishOptions = { channelType: 'MESSAGE' } as const;
    try {
      await args.rtm.publish(args.channelName, publishMessage, publishOptions);
    } catch (status) {
      console.log(status);
    }
  };

  useEffect(() => {
    if (init.current) {
      return;
    }

    const initRTM = async () => {
      const rtm = await setupRTMClient({
        uid: args.uid,
        rtmUId: args.rtmUid.toString(),
        token: args.token,
        channelName: args.channelName,
        userName: args.userName,
      });
      setRtm(rtm);

      // 自身がメッセージを受け取れる状態であることを全員に通知
      publishPing({ rtm, channelName: args.channelName, uid: args.uid, userName: args.userName });

      rtm.addEventListener('message', (event) => {
        const decoded = JSON.parse(event.message.toString()) as RtmPayload;

        if (decoded.type === 'ping') {
          // 誰かが自身がJoinしたタイミングで発するPingを受け取った場合、自身の名前を送信する
          // 自身のrtm自体が初期化されてから他ユーザーの名前を受け取るための仕組み
          publishMyName({
            rtm,
            uid: args.uid,
            userName: args.userName,
            channelName: args.channelName,
          });
          return;
        } else if (decoded.type === 'userName') {
          // 他ユーザーの名前を受け取った場合
          args.onUserNameReceived(decoded.message);
          return;
        } else if (decoded.type === 'destroy') {
          // 他ユーザーが退室した場合にカスタムイベントを送信して、自身の退出などの事後処理を期待する
          const event = new CustomEvent('spr-channel-destroy', { detail: decoded.message });
          window.dispatchEvent(event);
          return;
        }
      });
    };

    initRTM();

    init.current = true;

    return () => {
      if (!rtm) {
        return;
      }
      rtm.removeAllListeners();
    };
  }, []);

  return {
    publishDestroy,
  };
};
