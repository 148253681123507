import { VIEWPORT_SIZES_USER } from 'utils/responsive';

const getKeys = <T extends { [key: string]: unknown }>(obj: T): (keyof T)[] => {
  return Object.keys(obj);
};

type ROW_AND_COLS = { [maxUserCount in number]: { row: number; col: number } };

/**
 * maxUserCountまでは何行何列で表示するか
 */
const ROW_AND_COLS: ROW_AND_COLS = {
  2: { row: 1, col: 3 },
  4: { row: 2, col: 3 },
  6: { row: 2, col: 3 },
  [Number.MAX_VALUE]: { row: 3, col: 4 },
};

/**
 * maxUserCountまでは何行何列で表示するか
 */
const ROW_AND_COLS_TABLET: ROW_AND_COLS = {
  2: { row: 1, col: 2 },
  4: { row: 2, col: 2 },
  6: { row: 2, col: 3 },
  [Number.MAX_VALUE]: { row: 3, col: 4 },
};

/**
 * maxUserCountまでは何行何列で表示するか
 * モバイル用
 **/
const ROW_AND_COLS_MOBILE: ROW_AND_COLS = {
  2: { row: 2, col: 1 },
  3: { row: 3, col: 1 },
  4: { row: 2, col: 2 },
  6: { row: 3, col: 2 },
  [Number.MAX_VALUE]: { row: 6, col: 2 },
};

const SIZE_AND_ROW_COLS: { [key in keyof typeof VIEWPORT_SIZES_USER]: ROW_AND_COLS } = {
  WIDE: ROW_AND_COLS,
  STANDARD: ROW_AND_COLS_TABLET,
  NARROW: ROW_AND_COLS_TABLET,
  SP: ROW_AND_COLS_MOBILE,
};

export const getCurrentRowAndColSetting = ({
  displayedVideoCount,
  size,
}: {
  displayedVideoCount: number;
  size: keyof typeof VIEWPORT_SIZES_USER;
}) => {
  const setting = SIZE_AND_ROW_COLS[size];
  const maxUserCount = getKeys(setting)
    .filter((v) => v >= displayedVideoCount)
    .sort((a, b) => a - b)[0];

  return setting[maxUserCount];
};

export const getGridTemplateColumns = ({
  displayedVideoCount,
  size,
}: {
  displayedVideoCount: number;
  size: keyof typeof VIEWPORT_SIZES_USER;
}) => {
  const setting = getCurrentRowAndColSetting({ displayedVideoCount, size });
  return Array.from({ length: setting.col }, () => '1fr').join(' ');
};

export const getGridTemplateRows = ({
  displayedVideoCount,
  size,
}: {
  displayedVideoCount: number;
  size: keyof typeof VIEWPORT_SIZES_USER;
}) => {
  const setting = getCurrentRowAndColSetting({ displayedVideoCount, size });
  return Array.from({ length: setting.row }, () => '1fr').join(' ');
};
