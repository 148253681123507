import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useStartVideoCall } from 'ui/components/user/features/StartVideoCall/useStartVideoCall';

export const StartVideoCall = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const chatRoomUnitId = query.get('chat_room_unit_id');
  const { handleStartVideoCall } = useStartVideoCall(chatRoomUnitId);

  if (!chatRoomUnitId) {
    return <div>URLが正しくありません。再度前のページよりお試しください。</div>;
  }
  const ref = useRef(false);

  useEffect(() => {
    if (ref.current) {
      return;
    }

    ref.current = true;

    handleStartVideoCall();
  }, []);

  return <></>;
};
