import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IMeetRequestMessageTemplateClient,
  IPutInput,
  IDeleteInput,
  IPutResponse,
  IDeleteResponse,
} from 'interfaceAdapter/gatewaySchemas/IMeetRequestMessageTemplateClient';

export class MeetRequestMessageTemplateClient implements IMeetRequestMessageTemplateClient {
  async put(input: IPutInput): Promise<IPutResponse> {
    const { token, id, title, body } = input;
    const PATH = `${API_LOCATION_SPREADY}/company/meet_requests/message_templates/${id}`;
    const method = 'PUT';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };

    try {
      const response = await fetch(`${PATH}`, {
        method,
        headers,
        body: JSON.stringify({ title, body }),
      });
      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }

  async delete(input: IDeleteInput): Promise<IDeleteResponse> {
    const { token, id } = input;
    const PATH = `${API_LOCATION_SPREADY}/company/meet_requests/message_templates/${id}`;
    const method = 'DELETE';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };

    try {
      const response = await fetch(`${PATH}`, {
        method,
        headers,
      });
      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
