import { useCallback, useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { mqUser } from 'utils/responsive';
import { CastCodeRepository } from 'interfaceAdapter/repositories/CastCode';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { authContext } from 'application/contexts/useAuth';
import { IProjectQuery } from 'application/querySchemas/IProjectQuery';
import ThankYou from 'ui/images/user/thankyou.png';
import { PublicHeader } from 'ui/components/user/features/PublicHeader/PublicHeader';

const Body = styled.div`
  padding: 60px;
  text-align: center;

  ${mqUser('SP')} {
    padding: 60px 16px 40px;
  }
`;

const Img = styled.img`
  width: auto;
  height: 20vh;
  max-height: 300px;
  aspect-ratio: 1351/621;

  ${mqUser('SP')} {
    width: 80vw;
    max-width: 400px;
    height: auto;
  }
`;

const Title = styled.p`
  margin-top: 50px;
  font-size: var(--font-x-large);
`;

const Text = styled.p`
  margin-top: 30px;
  font-size: var(--font-medium);
`;

/**
 * 応募･紹介 完了ページ
 */
export const CastComplete: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const diContainer = useContext(diContainerContext);
  const castCodeRepository = diContainer.resolve(CastCodeRepository);
  const projectQuery = diContainer.resolve<IProjectQuery>('IProjectQuery');
  const { castInfo, removeCastInfo } = useContext(authContext);

  const fetchAndRedirect = useCallback(async () => {
    const redirect = searchParams.get('redirect');
    if (!redirect) {
      return navigate('/user/');
    }

    /**
     * 一度ログインを挟んだ場合、castInfo.cast_code が存在する
     * ログイン済みでそのまま応募した場合、castCodeRepository を利用する
     * TODO: 両者で cast_code の扱いをもっと統一したい
     */
    const castCode = castInfo.cast_code || castCodeRepository.get();

    const { project } = await projectQuery.fetchByCode(castCode);

    removeCastInfo();

    setTimeout(() => {
      navigate(`${redirect}?project_id=${project.id}`);
    }, 3000);
  }, [castInfo.cast_code]);

  useEffect(() => {
    fetchAndRedirect();
  }, []);

  return (
    <>
      <PublicHeader title="応募完了" />
      <Body>
        <Img src={ThankYou} alt="Thank you" />
        <Title>セッションへの応募が完了しました！</Title>
        <Text>セッション詳細画面に自動で遷移します...</Text>
      </Body>
    </>
  );
};
