import { diContainerContext } from 'application/contexts/useDiContainer';
import { snackbarContext } from 'application/contexts/useSnackbar';
import { TeamBusinessPlanInquireRepository } from 'interfaceAdapter/repositories/TeamBusinessPlanInquireRepository';
import { useContext, useEffect, useState } from 'react';

export const useInquireBusinessPlan = () => {
  const diContainer = useContext(diContainerContext);
  const repo = diContainer.resolve(TeamBusinessPlanInquireRepository);
  const [loading, setLoading] = useState(false);
  const {
    setSnackbarMessage,
    setShowSnackbar,
    setSnackBarCallback,
    showSnackbar,
    snackbarMessage,
  } = useContext(snackbarContext);
  const [result, setResult] = useState<boolean | null>(null);

  const submit = async () => {
    setLoading(true);
    try {
      const { message } = await repo.post();
      setResult(!message);
    } catch (e) {
      // エラー通知に気づかせるため
      window.scrollTo({ top: 0, behavior: 'smooth' });
      throw e;
    } finally {
      setTimeout(() => setLoading(false), 3000);
    }
  };

  useEffect(() => {
    if (result) {
      setSnackbarMessage('お問い合わせが完了しました。担当者からのご連絡をお待ちください。');
      setSnackBarCallback(() => {});
      setShowSnackbar(true);
      setResult(null);
    }
  }, [result]);

  return {
    loading,
    showSnackbar,
    setShowSnackbar,
    snackbarMessage,
    submit,
  };
};
