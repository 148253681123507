import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import 'index.css';
import 'normalize.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import 'react-image-crop/dist/ReactCrop.css';
import 'reflect-metadata';
import reportWebVitals from 'reportWebVitals';
import RouterConfig from 'RouterConfig';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { ErrorBoundary } from 'ui/components/user/elements/ErrorBoundary/ErrorBoundary';
import { GlobalSnackbar } from 'ui/components/user/elements/SnackBar/GlobalSnackbar';
import { Variables } from 'Variables';

dayjs.locale('ja');
dayjs.extend(isSameOrAfter);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale="ja"
        dateFormats={{ monthAndYear: 'YYYY年M月' }}
        localeText={{
          previousMonth: '前月', // < のツールチップ
          nextMonth: '次月', // > のツールチップ
        }}
      >
        <Variables />
        <GlobalSnackbar />
        <RouterConfig />
      </LocalizationProvider>
    </ErrorBoundary>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
