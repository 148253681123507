import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from '@emotion/styled';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import SearchIcon from '@mui/icons-material/Search';
import { ICastingUser } from 'domain/entities/Casting/Casting';
import { User } from 'domain/entities/User/User';
import { UserIcon } from 'ui/components/company/elements/UserIcon/UserIcon';
import { SimpleToolTip } from 'ui/components/company/features/SimpleToolTip/SimpleToolTip';

const Panel = styled.div`
  width: 49%;
  display: flex;
`;

const UserItemContainer = styled.div`
  padding: 16px 20px 20px;
  border-radius: 8px;
  background-color: var(--spr-back1);
  width: 100%;
  box-sizing: border-box;
`;

const UserItemHead = styled.div`
  display: flex;
  justify-content: space-between;
`;

const UserTag = styled.div`
  width: 100px;
  color: var(--spr-white);
  padding: 3px;
  border-radius: 4px;
  text-align: center;
  margin-bottom: 2px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${(props: { userType: 'casted' | 'spreader' | 'meetRequest' }) =>
    props.userType === 'casted'
      ? 'var(--spr-accent-strong)'
      : props.userType === 'spreader'
      ? 'var(--spr-black)'
      : 'var(--spr-third-strong)'};
`;

const SearchButton = styled.button`
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14),
    0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 0 15px;
  line-height: 34px;
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }

  svg {
    user-select: none;
    background-repeat: no-repeat;
    display: inline-block;
    fill: currentColor;
    height: 24px;
    width: 24px;
    overflow: hidden;
    vertical-align: middle;
  }
`;

const UserNameContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 2px 0;
  margin-bottom: 8px;
`;

const UserIconContainer = styled.div`
  ${(props: { width?: string }) => props.width && `width: ${props.width};`}
  display: flex;
`;

const UserName = styled.span`
  font-size: 16px;
  font-weight: 700;
`;

const UserAge = styled.span`
  font-size: var(--font-default);
`;

const UserJobContainer = styled.div`
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.3;
`;

const Separator = styled.div`
  display: inline-block;
  margin-inline: 4px;
`;

const USER_TYPE_TXT = {
  casted: '被紹介者',
  spreader: 'スプレッダー',
  meetRequest: '会いたい',
};

/**
 * 紹介一覧に表示するユーザー情報
 */
export const CastingUser: React.FC<{
  user: User | ICastingUser;
  userType: 'casted' | 'spreader';
  showAcaricAlert?: true;
}> = ({ user, showAcaricAlert, userType = 'casted' }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const openUserDrawer = () => {
    if (user.id) {
      searchParams.set('user', user.id.toString());
      navigate(`?${searchParams.toString()}`);
    }
  };

  return (
    <Panel>
      <UserItemContainer>
        <UserItemHead>
          <div style={{ display: 'flex', alignItems: '', gap: '1rem' }}>
            <UserTag userType={userType}>{USER_TYPE_TXT[userType]}</UserTag>
            {showAcaricAlert && (user as ICastingUser).is_from_acaric && (
              <SimpleToolTip
                position="right"
                tooltipText="本紹介の被紹介者は株式会社アカリク経由のユーザーです。ユーザーヒアリングは通常通り行っていただけます。<br />万一、雇用契約や業務委託登用に繋がった場合、株式会社アカリクに紹介フィーが発生いたします。"
              >
                <div>
                  <PriorityHighIcon
                    sx={{
                      color: 'var(--spr-warn)',
                      border: '1px solid  var(--spr-warn)',
                      width: '2rem',
                      height: '2rem',
                      borderRadius: '4px',
                    }}
                  />
                </div>
              </SimpleToolTip>
            )}
          </div>
          <SearchButton onClick={openUserDrawer}>
            <SearchIcon sx={{ color: 'var(--spr-black)', width: '2rem' }} />
          </SearchButton>
        </UserItemHead>

        <UserNameContainer>
          <SimpleToolTip tooltipText={`${user.last_name}${user.first_name}`} fontSize="11px">
            <UserIconContainer width="38px">
              <UserIcon src={user.icon_image} size={28} />
            </UserIconContainer>
          </SimpleToolTip>
          <UserName>
            {user.last_name}
            {user.first_name}
          </UserName>
          <UserAge>（{user.age}）</UserAge>
        </UserNameContainer>
        <UserJobContainer>
          業界：{user.industry_segment_parent}
          <Separator>/</Separator>
          {user.industry_segment_child}
        </UserJobContainer>
        <UserJobContainer>
          職種：{user.job_segment_parent}
          <Separator>/</Separator>
          {user.job_segment_child}
        </UserJobContainer>
        <UserJobContainer>所属企業：{user.company_name ?? '-'}</UserJobContainer>
        <UserJobContainer>役職名：{user.company_job ?? '-'}</UserJobContainer>
      </UserItemContainer>
    </Panel>
  );
};
