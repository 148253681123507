import { useEffect, useRef, useState } from 'react';

export const useMediaPermissions = () => {
  const [permissionError, setPermissionError] = useState<string | null>(null);
  const [checkFinished, setCheckFinished] = useState(false);
  const ref = useRef(false);

  useEffect(() => {
    if (ref.current) {
      return;
    }
    ref.current = true;

    checkPermission();
  }, []);

  const checkPermission = () => {
    // カメラとマイクの権限を同時にチェック
    navigator.mediaDevices
      .getUserMedia({
        video: true,
        audio: true,
      })
      .then((stream) => {
        // 権限が許可された

        // ストリームを停止
        stream.getTracks().forEach((track) => track.stop());
        return null;
      })
      .catch((err) => {
        console.error('Permission check error:', err);
        if (err instanceof Error == false) {
          setPermissionError('権限の確認中にエラーが発生しました');
        } else {
          if (err.name === 'NotAllowedError') {
            // ユーザーが明示的に拒否、またはシステムによってブロックされている
            setPermissionError(genNotAllowedErrorMessage());
          } else if (err.name === 'NotFoundError') {
            // デバイスが見つからない
            setPermissionError('カメラまたはマイクが見つかりません。デバイスを確認してください。');
          } else {
            setPermissionError(`権限の確認中にエラーが発生しました: ${err.message}`);
          }
        }
      })
      .finally(() => {
        setCheckFinished(true);
      });
  };

  /**
   * ブラウザアプリによるマイクとカメラの使用が拒否されている場合にユーザーに表示するメッセージを作る
   *
   * Note:
   *   - iOSでブラウザアプリに対するカメラ・マイクの使用が拒否されている場合に、許可するための設定方法がやや煩雑であり、設定方法がわからないユーザーを救うことがこのメソッドの主旨である
   *   - androidの場合は拒否設定になっていても許可を求めるポップアップが出るので困らずスムーズに使えると思われる
   *   - PCの場合は基本的にポップアップが出るはずである。過去にspreadyのページに対して拒否する操作を明示的に行なっている場合はその限りではないが、その可能性は低い
   */
  const genNotAllowedErrorMessage = () => {
    const baseMessage = 'カメラとマイクの使用をブラウザに許可する設定が必要です。';

    const userAgent = window.navigator.userAgent.toLowerCase();
    // iOSの場合は一度設定アプリを開いて許可の操作をすることが必須となる
    if (userAgent.includes('iphone') || userAgent.includes('ipad')) {
      return (
        baseMessage +
        '設定アプリを開いて「アプリ」を選択後に現在お使いのブラウザ（SafariやChromeなど）を選択してカメラとマイクの権限を「許可」に変更してください。'
      );
    }

    // androidの場合は現在の設定が「拒否」になっていても都度許可を求めるポップアップが出るので、このメッセージは保険的な意味合いが強い
    if (userAgent.includes('android')) {
      return (
        baseMessage +
        '設定アプリを開いて「アプリ」を選択後に現在お使いのブラウザ（Chromeなど）を選択, 「権限」を選択してカメラとマイクの権限を「許可」に変更してください。'
      );
    }

    return baseMessage + 'ブラウザの設定でカメラとマイクの使用を許可してください。';
  };

  return {
    permissionError,
    checkFinished,
  };
};
