import { IChatRoomCompanySchema } from 'domain/entities/factories/ChatRoom';
import { IProject } from 'domain/entities/Project/Project';
import { User } from 'domain/entities/User/User';
import { TeamRoleType } from 'domain/entities/Team/TeamRole';

export const ROOM_TYPE_PREFIX = {
  /** 会いたいリクエスト */
  MEET_REQUEST: 'mr_',
  /** キャスティング */
  CASTING: 'p_',
  /** お問い合わせ */
  SUPPORT: 'u_',
} as const;

export const CHAT_TYPE = {
  /** 会いたいリクエスト */
  MEET_REQUEST: 'meet_request',
  /** 応募 */
  CASTING: 'casting',
  /** お問い合わせ */
  SUPPORT: 'support',
} as const;

export const CHAT_LABEL = {
  SPREADER: 'spreader',
  CASTING: 'casting',
  MEET_REQUEST: 'meet_request',
} as const;
export type ChatLabel = (typeof CHAT_LABEL)[keyof typeof CHAT_LABEL];

export const CHAT_LABEL_MAP = {
  [CHAT_LABEL.SPREADER]: '紹介',
  [CHAT_LABEL.CASTING]: '応募',
  [CHAT_LABEL.MEET_REQUEST]: 'リクエスト',
} as const;

export interface IChatRoomHost {
  id: number;
  last_name: string;
  first_name: string;
  icon_image: string | null;
  status: number;
  department: string | null;
  job: string | null;
  team_role?: TeamRoleType | null;
}

export interface IChatRoom {
  /** ルームUnitID */
  chat_room_unit_id: number;
  /** ルームID */
  room_id: string;
  /** チャット種別 */
  chat_type: string;
  /** 未読 */
  unread: boolean;
  /** 経過日数 */
  passed_days: number | null;
  /** 経過時間（表示用） */
  passed_time: string;
  /** ラベル */
  label: ChatLabel;
  /** 自身が参加しているか */
  my_join: boolean;
  /** 紹介したユーザー */
  spreader: User | null;
  /** 紹介されたユーザー */
  introduced_user: User | null;
  /** 紹介日時 */
  assigned_at: Date | null;
  /** 企業ユーザー */
  from_company_user: IChatRoomHost | null;
  /** 案件 */
  project: IProject | null;
  /** 案件紹介者ID */
  project_spreader_id?: number;
  /** ミートアップ日時 */
  meetup_date_at: Date | null;
  /** 最終メッセージ */
  last_message_text: string;
  /** 最終メッセージ日時 */
  last_message_sent_at: Date | null;
  /** ワークグループ情報 */
  company: IChatRoomCompanySchema | null;
  /** アカリクから来たユーザーかどうか */
  is_introduced_user_from_acaric?: boolean;

  chatType: 'cast' | 'mr';
}

export class ChatRoom implements IChatRoom {
  constructor(
    public chat_room_unit_id: number,
    public room_id: string,
    public chat_type: string,
    public unread: boolean,
    public passed_days: number | null,
    public passed_time: string,
    public label: ChatLabel,
    public my_join: boolean,
    public spreader: User | null,
    public introduced_user: User | null,
    public assigned_at: Date | null,
    public from_company_user: IChatRoomHost | null,
    public project: IProject | null,
    public meetup_date_at: Date | null,
    public last_message_text: string,
    public last_message_sent_at: Date | null,
    public chatType: 'cast' | 'mr',
    public company: IChatRoomCompanySchema | null,
    public project_spreader_id?: number,
    public is_introduced_user_from_acaric?: boolean,
  ) {}
}
