import React from 'react';

/**
 * number : pinned video index
 * null : no pinned video
 */
type VideoPinContextType = {
  pinnedVideoIndex: number | null;
  setPinnedVideoIndex: (index: number | null) => void;
};

const VideoPinContext = React.createContext<VideoPinContextType | null>(null);

export const VideoPinProvider = ({ children }: { children: React.ReactNode }) => {
  const [pinnedVideoIndex, _setPinnedVideoIndex] = React.useState<number | null>(null);

  const setPinnedVideoIndex = (index: number | null) => {
    _setPinnedVideoIndex((prev: number | null) => {
      if (prev === index) {
        return null;
      }
      return index;
    });
  };

  return (
    <VideoPinContext.Provider value={{ pinnedVideoIndex, setPinnedVideoIndex }}>
      {children}
    </VideoPinContext.Provider>
  );
};

export const useVideoPin = () => {
  const context = React.useContext(VideoPinContext);
  if (context === null) {
    throw new Error('useVideoPin must be used within a VideoPinProvider');
  }

  return context;
};
