import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { CompanyUserItem } from 'ui/components/company/features/CompanyUserItem/CompanyUserItem';
import { MessageItem } from 'ui/components/company/features/MessageItem/MessageItem';
import { RoomMeetRequest } from 'ui/components/company/features/RoomMeetRequest/RoomMeetRequest';
import { RoomProjectSummary } from 'ui/components/company/features/RoomProjectSummary/RoomProjectSummary';
import { StartVideoCallLink } from 'ui/components/company/features/StartVideoCall/StartVideoCallLink';
import { ChatInput } from 'ui/components/company/patterns/ChatInput/ChatInput';
import { UserItem } from 'ui/components/company/patterns/UserItem/UserItem';
import { MemberEdit } from 'ui/components/company/screens/modals/MemberEdit/MemberEdit';
import { VideoCallDeviceTest } from 'ui/components/company/screens/modals/VideoDeviceTest/VideoCallDeviceTest';
import { VideoCallRecordings } from 'ui/components/video/features/Recording/VideoCallRecordings';
import { MeetupDateRemind } from '../../screens/modals/MeetupDateRemind/MeetupDateRemind';
import { ChatDetailProps, useChatDetail } from './useChatDetail';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 20px;
  padding-right: 10px;
  gap: 2rem;
`;

const MemberWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const MembersTitle = styled.div`
  font-size: 10px;
`;

const DialogOpener = styled.div`
  padding-left: 3px;
  font-size: 9px;
  text-decoration: underline;
  cursor: pointer;
`;

const MessageWrapper = styled.div`
  height: 100%;
  padding-inline: 16px;
  overflow-y: auto;
  flex: 1;
`;

const Meta = styled.div`
  margin-block: 20px;
  text-align: center;
  color: var(--gray-dark-1);
`;

const NoData = styled.div`
  margin-top: 20px;
  text-align: center;
`;

const NotJoined = styled.div`
  height: 80px;
  margin: 0 -20px -20px;
  padding: 8px;
  background-color: var(--spr-back1);
`;

export const ChatDetail: React.FC<ChatDetailProps> = (props) => {
  const {
    ref,
    user,
    roomId,
    messages,
    members,
    meetRequests,
    joined,
    memberEditModal,
    remindModal,
    remindModalProps,
    fetchMembers,
    onSendMessage,
    fetchRooms,
    removeMessage,
  } = useChatDetail(props);

  return (
    <Wrapper>
      <Header>
        <div style={{ display: 'flex', gap: '.5rem' }}>
          {props.room && (
            <div>
              <StartVideoCallLink chatRoomUnitId={props.room?.chat_room_unit_id} />
            </div>
          )}
          <VideoCallDeviceTest />
          <VideoCallRecordings chat_room_unit_id={props.room?.chat_room_unit_id} />
        </div>
        <MemberWrapper>
          {members.companyUsers.length > 0 && <MembersTitle>参加者</MembersTitle>}
          {members.users.map((user) => (
            <UserItem key={user.id} user={user} realName viewType="iconOnly" />
          ))}
          {members.companyUsers.map((user) => (
            <CompanyUserItem key={user.id} user={user} viewType="iconOnly" />
          ))}
          {roomId && <DialogOpener onClick={memberEditModal.openModal}>追加/編集</DialogOpener>}
        </MemberWrapper>
      </Header>

      <MessageWrapper ref={ref}>
        {/* 応募・紹介 */}
        {props.room?.chatType === 'cast' && props.room?.project && (
          <RoomProjectSummary project={props.room.project} />
        )}
        {/* 会いたいリクエスト */}
        {props.room?.chatType === 'mr' &&
          meetRequests.map((meetRequest) => (
            <RoomMeetRequest key={meetRequest.id} meetRequest={meetRequest} />
          ))}
        {/* 株式会社アカリク経由のユーザーと面談する場合のアラート */}
        <ul style={{ display: 'flex', gap: '.7rem', flexDirection: 'column' }}>
          {props.room?.is_introduced_user_from_acaric && (
            <li>
              <b>本紹介の被紹介者は株式会社アカリク経由のユーザーです</b>
              。ユーザーヒアリングは通常通り行っていただけます。
              雇用契約や業務委託登用に繋がった場合、株式会社アカリクに紹介フィーが発生いたします。
            </li>
          )}
          {props.room?.project?.company?.video_plan !== 'BUSINESS' &&
          props.room?.company?.video_plan !== 'BUSINESS' ? (
            <li>
              <b>現在ビデオ通話無料プランです</b>
              。「ビデオ通話を開始」からが可能です。通話は自動録画され、72時間以内であれば再生が可能です。他のサービスのURLを共有することなくご利用いただけます。
              <Link
                target="_blank"
                style={{ textDecoration: 'underline' }}
                to="/workgroup/how-to-video-call/"
              >
                詳しくはこちら
              </Link>
            </li>
          ) : null}
        </ul>
        {messages.length === 0 && <NoData>メッセージがまだありません</NoData>}
        {messages.length > 0 && <Meta>先頭のメッセージ</Meta>}
        {messages.map((message) => (
          <MessageItem
            key={`${message.timestamp}_${message.user_type}_${message.user_id}`}
            message={message}
            companyUser={user}
            removeMessage={removeMessage}
          />
        ))}
        {messages.length > 0 && <Meta>これ以上メッセージはありません</Meta>}
      </MessageWrapper>

      {joined ? (
        <ChatInput roomId={roomId ?? ''} onSend={onSendMessage} />
      ) : (
        <NotJoined>
          メッセージの送信／未読の通知の受信は、右上の参加者編集よりご自身をこのチャットルームに参加させてください。
        </NotJoined>
      )}

      {memberEditModal.createModal(
        <MemberEdit
          roomId={roomId ?? ''}
          members={members.companyUsers}
          fetchMembers={fetchMembers}
          closeModal={memberEditModal.closeModal}
        />,
      )}

      {remindModal.createModal(
        <MeetupDateRemind
          {...remindModalProps}
          close={remindModal.closeModal}
          onUpdateMeetingAtComplete={fetchRooms}
        />,
      )}
    </Wrapper>
  );
};
