import styled from '@emotion/styled';
import { IMicrophoneAudioTrack } from 'agora-rtc-react';
import { useEffect, useState } from 'react';
import { mqUser } from 'utils/responsive';

const IndicatorWrapper = styled.div`
  display: flex;
  column-gap: 8px;
  color: grey;
  align-items: center;
`;

const Indicators = styled.div`
  display: flex;
  column-gap: 1px;
`;

const IndicatorLabel = styled.div`
  margin: 0;
`;

const BaseIndicator = styled.div`
  width: 40px;
  height: 15px;
  border-radius: 2px;

  ${mqUser('SP')} {
    width: 25px;
    height: 15px;
  }
`;

const IndicatorGreen = styled(BaseIndicator)`
  background-color: limegreen;
`;

const IndicatorGrey = styled(BaseIndicator)`
  background-color: grey;
`;

export const VolumeIndicator = ({ audioTrack }: { audioTrack: IMicrophoneAudioTrack | null }) => {
  const [volume, setVolume] = useState(0);

  useEffect(() => {
    if (!audioTrack) {
      return;
    }

    // ユーザーが発声した音量を取得する
    const timerId = setInterval(() => {
      const volume = audioTrack.getVolumeLevel();
      setVolume(volume);
    }, 100);

    return () => {
      clearInterval(timerId);
    };
  }, [audioTrack]);

  const indicators = [];
  for (let i = 0.1; i < 0.9; i += 0.1) {
    // 0.5未満の場合は環境音のみの音で喋っていないと思われる
    if (volume < 0.5) {
      indicators.push(<IndicatorGrey key={i} />);
      continue;
    }

    indicators.push(i < volume ? <IndicatorGreen key={i} /> : <IndicatorGrey key={i} />);
  }

  return (
    <IndicatorWrapper>
      <IndicatorLabel>入力レベル: </IndicatorLabel>
      <Indicators>{indicators}</Indicators>
    </IndicatorWrapper>
  );
};
