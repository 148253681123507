import styled from '@emotion/styled';
import { memo } from 'react';
import { VIDEO_CALL_HEADER_HEIGHT_PX } from 'ui/components/video/panes/Header/Header';
import { mqUser } from 'utils/responsive';

export const VIDEO_CALL_FOOTER_HEIGHT_PX = 80;

const Wrapper = styled.div`
  min-width: 100vw;
  width: 100vw;
  min-height: 100svh;
  display: grid;
  background-color: #000;
  grid-template-rows:
    minmax(50px, ${VIDEO_CALL_HEADER_HEIGHT_PX}px)
    auto
    minmax(50px, ${VIDEO_CALL_FOOTER_HEIGHT_PX}px);

  ${mqUser('NARROW')} {
    grid-template-rows:
      minmax(0, 0)
      auto
      minmax(0, 0);
  }
  ${mqUser('SP')} {
    grid-template-rows:
      minmax(0, 0)
      auto
      minmax(0, 0);
  }
`;

export const VideoCallLayout = memo(function VideoCallLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return <Wrapper>{children}</Wrapper>;
});
