import { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'utils/yup';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { companyUserContext } from 'application/contexts/useCompanyUser';
import { CompanyUserRepository } from 'interfaceAdapter/repositories/CompanyUser';
import { CompanyUserQuery } from 'interfaceAdapter/queries/CompanyUser';
import { ICompanyUser } from 'domain/entities/CompanyUser/CompanyUser';
import { TEAM_ROLE_TYPE, TeamRoleType } from 'domain/entities/Team/TeamRole';

const schema = yup
  .object()
  .shape({
    team_role: yup.string().oneOf(Object.values(TEAM_ROLE_TYPE)).required(),
  })
  .required();

type UserRoleEditForm = yup.InferType<typeof schema>;

export const useUserRoleEditForm = () => {
  const diContainer = useContext(diContainerContext);
  const companyUserRepository = diContainer.resolve<CompanyUserRepository>(CompanyUserRepository);
  const companyUserQuery = diContainer.resolve<CompanyUserQuery>(CompanyUserQuery);
  /** 現在ログイン中のユーザー */
  const { user: editor } = useContext(companyUserContext);
  const params = useParams();
  const { control, handleSubmit, formState, setValue } = useForm<UserRoleEditForm>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  /** 編集対象のユーザー */
  const [targetUser, setTargetUser] = useState<ICompanyUser | null>(null);
  const [requesting, setRequesting] = useState(false);

  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);

  const isFormAvailable = useMemo(() => {
    return (
      editor.team_role === TEAM_ROLE_TYPE.SUPPORT_USER ||
      editor.team_role === TEAM_ROLE_TYPE.ADMINISTRATOR
    );
  }, [editor.team_role]);

  const selectableRoles = useMemo(() => {
    if (!targetUser) {
      return [];
    }
    // サポートユーザーを変更することはできない
    if (targetUser.team_role === TEAM_ROLE_TYPE.SUPPORT_USER) {
      return [{ value: TEAM_ROLE_TYPE.SUPPORT_USER, label: 'サポートユーザ' }];
    }
    // 管理者からはメンバーor管理者に変更可能
    if (targetUser.team_role === TEAM_ROLE_TYPE.ADMINISTRATOR) {
      return [
        { value: TEAM_ROLE_TYPE.MEMBER, label: 'メンバー' },
        { value: TEAM_ROLE_TYPE.ADMINISTRATOR, label: '管理者' },
      ];
    }
    // メンバーはメンバーを変更できない
    if (
      targetUser.team_role === TEAM_ROLE_TYPE.MEMBER &&
      editor.team_role === TEAM_ROLE_TYPE.MEMBER
    ) {
      return [{ value: TEAM_ROLE_TYPE.MEMBER, label: 'メンバー' }];
    }
    // 管理者またはサポートユーザーの権限を保つ場合、ユーザーをユーザーor管理者に変更可能
    if (
      targetUser.team_role === TEAM_ROLE_TYPE.MEMBER &&
      (editor.team_role === TEAM_ROLE_TYPE.ADMINISTRATOR ||
        editor.team_role === TEAM_ROLE_TYPE.SUPPORT_USER)
    ) {
      return [
        { value: TEAM_ROLE_TYPE.MEMBER, label: 'メンバー' },
        { value: TEAM_ROLE_TYPE.ADMINISTRATOR, label: '管理者' },
      ];
    }
    return [];
  }, [targetUser?.team_role, editor.team_role]);

  const onSubmit = handleSubmit(async (form) => {
    setRequesting(true);

    if (!params.userId || isNaN(Number(params.userId))) {
      return;
    }

    try {
      const id = Number(params.userId);
      await companyUserRepository.editTeamRole(id, form.team_role);
      setShowSuccessSnackbar(true);
    } finally {
      setRequesting(false);
    }
  });

  useEffect(() => {
    const id = parseInt(params.userId ?? '', 10);
    if (isNaN(id)) {
      return;
    }
    companyUserQuery.get(id).then((target) => {
      setTargetUser(target);
      setValue('team_role', target.team_role as TeamRoleType);
    });
  }, []);

  return {
    isFormAvailable,
    targetUser,
    control,
    formState,
    selectableRoles,
    requesting,
    showSuccessSnackbar,
    setShowSuccessSnackbar,
    onSubmit,
  };
};
