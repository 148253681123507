import { diContainerContext } from 'application/contexts/useDiContainer';
import { UserCheckToken } from 'application/usecases/UserCheckToken';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { RecordingAnnouncement } from 'ui/components/video/screens/RecordingAnnouncement/RecordingAnnouncement';
import { VideoCall as _VideoCall } from 'ui/components/video/patterns/VideoCall/VideoCall';

export const VideoCall = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const videoToken = query.get('token');
  const rtmToken = query.get('rtmToken');
  const rtmUid = query.get('rtmUid');
  const uid = query.get('uid');
  const screenShareVideoToken = query.get('screenShareToken');
  const screenShareUid = query.get('screenShareUid');
  const channelName = query.get('channelName');
  const diContainer = useContext(diContainerContext);
  const [loading, setLoading] = useState(true);
  const [userName, setUserName] = useState('');

  if (
    !videoToken ||
    !rtmToken ||
    !rtmUid ||
    !uid ||
    !screenShareVideoToken ||
    !screenShareUid ||
    Number.isNaN(parseInt(uid)) ||
    !channelName
  ) {
    return <div>URLが正しくありません。再度前のページよりお試しください。</div>;
  }

  const userCheckToken = diContainer.resolve(UserCheckToken);

  useEffect(() => {
    const initialFetch = async () => {
      const result = await userCheckToken.execute(false);
      if (result === false) {
        return;
      }
      setUserName(`${result.last_name} ${result.first_name}`);
      setLoading(false);
    };
    initialFetch();
  }, []);

  if (loading || !userName) {
    return null;
  }

  return (
    <>
      <_VideoCall
        token={videoToken}
        rtmToken={rtmToken}
        rtmUid={rtmUid}
        uid={Number(uid)}
        screenShareToken={screenShareVideoToken}
        screenShareUid={Number(screenShareUid)}
        channelName={channelName}
        isBusinessUser={false}
        userName={userName}
      />

      <RecordingAnnouncement forUserType="user" />
    </>
  );
};
