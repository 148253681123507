import AgoraRTC, { IMicrophoneAudioTrack } from 'agora-rtc-react';
import { useEffect, useRef, useState } from 'react';
import { PanelSelect } from 'ui/components/video/panes/ControlPanel/ControlPanel';

export const AudioDeviceSelector = ({ micTrack }: { micTrack: IMicrophoneAudioTrack | null }) => {
  const init = useRef(false);
  const [micDevices, setMicDevices] = useState<MediaDeviceInfo[]>();
  const [selectedDeviceLabel, setSelectedDeviceLabel] = useState<string>(
    micTrack?.getTrackId() ?? '',
  );
  const setAudioDevicesHandler = async () => {
    const devices = await AgoraRTC.getDevices();
    setMicDevices(devices.filter((device) => device.kind === 'audioinput'));
  };

  const handleChange = (value: string) => {
    const deviceId = micDevices?.find((device) => device.label === value)?.deviceId;
    if (!deviceId) {
      return;
    }
    micTrack?.setDevice(deviceId);
  };

  AgoraRTC.onMicrophoneChanged = async () => {
    setAudioDevicesHandler();
  };

  micTrack?.on('track-updated', (track) => {
    // Changeイベントではなくtrack-updatedイベントを使い、Agora側の変更を正とする
    console.log('track-updated', track, micDevices);

    setSelectedDeviceLabel(track.label);
  });

  useEffect(() => {
    setAudioDevicesHandler();
  }, []);

  useEffect(() => {
    if (init.current) {
      return;
    }
    if (!micTrack) {
      return;
    }
    console.log('micTrack.getTrackId()', micTrack.getTrackId(), micTrack.getTrackLabel());

    setSelectedDeviceLabel(micTrack.getTrackLabel());
    init.current = true;
  }, [micTrack]);

  //   if (!micTrack) {
  //     return null;
  //   }

  return (
    <PanelSelect
      label="音声デバイス"
      onChange={handleChange}
      options={micDevices?.map((device) => ({ value: device.label, label: device.label })) || []}
      value={selectedDeviceLabel}
    />
  );
};
