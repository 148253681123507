import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { Controller } from 'react-hook-form';
import { ImageUpload } from 'ui/components/company/features/ImageUpload/ImageUpload';
import { FormSectionTitle } from 'ui/components/company/elements/FormSectionTitle/FormSectionTitle';
import { ActionButton } from 'ui/components/company/elements/ActionButton/ActionButton';
import { FormInput } from 'ui/components/company/elements/FormInput/FormInput';
import { FormCheckbox } from 'ui/components/company/elements/FormCheckbox/FormCheckbox';
import { FormTextArea } from 'ui/components/company/elements/FormTextArea/FormTextArea';
import { FormError } from 'ui/components/company/elements/FormError/FormError';
import { MultiInput } from 'ui/components/company/features/MultipleInput/MultipleInput';
import { SnackBar } from 'ui/components/company/elements/SnackBar/SnackBar';
import { useCompanyRegisterForm } from './useCompanyRegisterForm';

const Wrapper = styled.div`
  padding: 40px 28px;
  border-radius: 12px;
  background-color: var(--white);
`;

const SmallText = styled.p`
  margin: 8px 0 0;
  font-weight: 300;
  font-size: var(--font-default);
`;

const Button = styled.button<{ disabled?: boolean }>`
  margin-top: 15px;
  align-items: center;
  padding: 12px 20px;
  border: 1px solid var(--gray);
  border-radius: 38px;
  appearance: none;
  background-color: ${({ disabled }) => (disabled ? 'var(--gray-light-2)' : 'var(--white)')};
  color: ${({ disabled }) => (disabled ? 'var(--gray-dark-1)' : 'var(--accent-dark)')};
  font-size: var(--font-medium);
  font-weight: var(--font-bold);
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  &:not(:disabled):hover {
    opacity: 0.7;
  }
`;

const Description = styled.div`
  background-color: var(--accent-paler);
  border-radius: 20px;
  padding: 20px 30px;
  font-size: var(--font-x-large);
  line-height: 1.8;

  b {
    font-weight: 800;
  }
`;

/**
 * 企業情報入力フォーム
 *
 */
export const CompanyRegisterForm: React.FC = () => {
  const {
    requesting,
    errors,
    control,
    showSnackbar,
    snackbarMessage,
    arrayFields,
    confirmProfileUrl,
    watch,
    setValue,
    onSubmit,
    onPicsUpload,
    removeEmptyFields,
    testSlackWebhook,
    setShowSnackbar,
    appendField,
  } = useCompanyRegisterForm();

  const navigate = useNavigate();
  const picsData = watch('pics');

  return (
    <Wrapper>
      <SnackBar
        open={showSnackbar}
        setOpen={setShowSnackbar}
        message={snackbarMessage}
        callback={() => navigate(confirmProfileUrl)}
      />
      <Description>
        ここで入力した企業情報をもとに<b>ワークグループが作成</b>されます。
        <br />
        ワークグループには<b>他のメンバーを招待</b>できます。
        <br />
        インタビュー募集案件を作成してワークグループ内の<b>メンバーと共有</b>しましょう。
      </Description>
      {Object.keys(errors).length > 0 && (
        <FormError
          wide
          message="入力エラーがあります。入力内容をご確認ください。"
          style={{ marginBottom: '40px' }}
        />
      )}
      <FormSectionTitle
        title="企業名"
        titleSize="18px"
        isRequired
        style={{ margin: '32px 0 8px' }}
      />
      <Controller
        name="name"
        control={control}
        render={({ field }) => <FormInput {...field} disabled />}
      />
      <FormSectionTitle
        title="ワークグループ識別名"
        titleSize="18px"
        style={{ margin: '32px 0 8px' }}
      />
      <Controller
        name="meta_description"
        control={control}
        render={({ field }) => (
          <FormTextArea
            {...field}
            rows={2}
            resize="vertical"
            placeholder="ワークグループ識別名を入力"
            hasError={!!errors.meta_description?.message}
          />
        )}
      />
      <SmallText>
        ワークグループを複数作成した場合の識別用として、プロジェクト名やチーム名を入力してご利用ください。個人ユーザーには公開されません。
      </SmallText>
      <FormError message={errors.meta_description?.message} style={{ marginBottom: '8px' }} />

      <FormSectionTitle
        title="郵便番号"
        titleSize="18px"
        isRequired
        style={{ margin: '32px 0 8px' }}
      />
      <Controller
        name="zipcode"
        control={control}
        render={({ field }) => (
          <FormInput
            {...field}
            placeholder="000-0000"
            width={'80%'}
            hasError={!!errors.zipcode?.message}
            disabled
          />
        )}
      />
      <FormError message={errors.zipcode?.message} style={{ marginBottom: '8px' }} />

      <FormSectionTitle
        title="御社所在地"
        titleSize="18px"
        isRequired
        style={{ margin: '32px 0 8px' }}
      />
      <Controller
        name="address"
        control={control}
        render={({ field }) => (
          <FormInput
            {...field}
            placeholder="東京都渋谷区恵比寿"
            hasError={!!errors.address?.message}
            disabled
          />
        )}
      />
      <FormError message={errors.address?.message} style={{ marginBottom: '8px' }} />

      <FormSectionTitle
        title="コーポレートサイトURL"
        titleSize="18px"
        isRequired
        style={{ margin: '32px 0 8px' }}
      />
      <Controller
        name="url"
        control={control}
        render={({ field }) => (
          <FormInput
            {...field}
            placeholder="https://spready.co.jp/"
            hasError={!!errors.url?.message}
          />
        )}
      />
      <FormError message={errors.url?.message} style={{ marginBottom: '8px' }} />

      <FormSectionTitle
        title="企業ロゴ（アイコン画像）"
        titleSize="18px"
        style={{ margin: '32px 0 8px' }}
      />
      <ImageUpload
        imagePath={watch('icon_image') ?? ''}
        directory="profile"
        fileName={'icon'}
        fileCategory="company"
        disabled={requesting}
        width={180}
        height={180}
        withPreview
        onComplete={(fileUrl) => setValue('icon_image', fileUrl)}
      />
      <SmallText>※ 推奨サイズ：縦横400px以上、形式：JPG、PNG、2MB未満</SmallText>
      <FormError message={errors.icon_image?.message} style={{ marginBottom: '8px' }} />

      <FormSectionTitle
        title="利用企業ご紹介サイトへの企業ロゴの掲載許可"
        titleSize="18px"
        style={{ margin: '32px 0 8px' }}
      />
      <Controller
        name="logo_display_enable"
        control={control}
        render={({ field }) => (
          <FormCheckbox
            id="logo_display_enable"
            checked={field.value}
            disableBorder
            onChange={() => field.onChange(!field.value)}
          >
            使用可
          </FormCheckbox>
        )}
      />

      <FormSectionTitle
        title="会社説明（案件ページ下に表示されます）"
        titleSize="18px"
        style={{ margin: '32px 0 8px' }}
      />
      <Controller
        name="about_company"
        control={control}
        render={({ field }) => (
          <FormTextArea
            {...field}
            rows={4}
            placeholder="会社説明を入力"
            hasError={!!errors.about_company?.message}
          />
        )}
      />
      <SmallText>改行が使えます。URLはリンクされます。</SmallText>
      <FormError message={errors.about_company?.message} style={{ marginBottom: '8px' }} />

      <FormSectionTitle title="会社説明画像1" titleSize="18px" style={{ margin: '32px 0 8px' }} />
      <ImageUpload
        imagePath={picsData && picsData.length > 0 ? picsData[0] : ''}
        directory="company"
        fileName="company_pic1"
        fileCategory="company"
        placeholder="image"
        aspectRatio={16 / 9}
        width={480}
        height={270}
        disabled={requesting}
        withPreview
        isSquare
        onComplete={(path) => onPicsUpload(path, 0)}
      />
      <SmallText>※ 推奨サイズ：横480px以上、縦270px以上。縦:横=16:9</SmallText>

      <FormSectionTitle title="会社説明画像2" titleSize="18px" style={{ margin: '32px 0 8px' }} />
      <ImageUpload
        imagePath={picsData && picsData.length > 1 ? picsData[1] : ''}
        directory="company"
        fileName="company_pic2"
        fileCategory="company"
        placeholder="image"
        aspectRatio={16 / 9}
        width={480}
        height={270}
        disabled={requesting}
        withPreview
        isSquare
        onComplete={(path) => onPicsUpload(path, 1)}
      />
      <SmallText>※ 推奨サイズ：横480px以上、縦270px以上。縦:横=16:9</SmallText>
      <FormError message={errors.pics?.message} style={{ marginBottom: '8px' }} />

      <FormSectionTitle title="企業紹介URL" titleSize="18px" style={{ margin: '32px 0 8px' }} />
      <MultiInput
        label=""
        fields={arrayFields['urls'].fields}
        control={control}
        errors={errors}
        keyName="urls"
        placeholder="https://spready.co.jp/"
        appendText="URLを追加"
        append={() => appendField('urls')}
        remove={() => removeEmptyFields('urls')}
      />
      <FormError message={errors.urls?.message} style={{ marginBottom: '8px' }} />

      <FormSectionTitle
        title="企業のコピーや理念など"
        titleSize="18px"
        style={{ margin: '32px 0 8px' }}
      />
      <Controller
        name="copy"
        control={control}
        render={({ field }) => <FormInput {...field} hasError={!!errors.copy?.message} />}
      />
      <FormError message={errors.copy?.message} style={{ marginBottom: '8px' }} />

      <FormSectionTitle
        title="HERP連携用WebHookURL"
        titleSize="18px"
        style={{ margin: '32px 0 8px' }}
      />
      <Controller
        name="herp_webhook_url"
        control={control}
        render={({ field }) => (
          <FormInput {...field} hasError={!!errors.herp_webhook_url?.message} />
        )}
      />
      <FormError message={errors.herp_webhook_url?.message} style={{ marginBottom: '8px' }} />

      <FormSectionTitle
        title="Slack通知用WebHookURL"
        titleSize="18px"
        style={{ margin: '32px 0 8px' }}
      />
      <Controller
        name="slack_webhook_url"
        control={control}
        render={({ field }) => (
          <FormInput {...field} hasError={!!errors.slack_webhook_url?.message} />
        )}
      />
      <FormError message={errors.slack_webhook_url?.message} style={{ marginBottom: '8px' }} />
      <Button disabled={requesting} onClick={testSlackWebhook}>
        連携テストする
      </Button>

      <ActionButton
        bgColor="secondary-dark"
        style={{ margin: '32px auto 0' }}
        disabled={requesting}
        onClick={onSubmit}
      >
        次へ
      </ActionButton>
    </Wrapper>
  );
};
