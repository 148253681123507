import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IPostInput,
  IPostResponse,
  IVideoTokenClient,
} from 'interfaceAdapter/gatewaySchemas/IVideoTokenClient';

export class VideoTokenClient implements IVideoTokenClient {
  async post(input: IPostInput): Promise<IPostResponse> {
    const PATH = `${API_LOCATION_SPREADY}/video_call/create_token`;
    const { token } = input;
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN': token,
    };
    const method = 'POST';
    const body = JSON.stringify({
      chat_room_unit_id: input.chatRoomUnitId,
    });
    try {
      const response = await fetch(`${PATH}`, {
        method,
        headers,
        body,
      });
      const { status } = response;
      const { data, message } = await response.json();

      if (status !== 200) {
        throw new Error(message);
      }

      return {
        data: {
          token: data.token,
          rtmToken: data.rtm_token,
          rtmUid: data.rtm_uid,
          uid: data.uid,
          screenShareToken: data.screen_share_token,
          screenShareUid: data.screen_share_uid,
          channelName: data.channel_name,
        },
        message,
        status,
      };
    } catch (e) {
      if (e instanceof Error) {
        throw new Error(e.message);
      }
      throw new Error('処理に失敗しました');
    }
  }
}
