import { inject, injectable } from 'tsyringe';

import {
  type ICompanyUpdateVideoRecordingLayoutClient,
  IPostResponse,
} from 'interfaceAdapter/gatewaySchemas/ICompanyUpdateVideoRecordingLayoutClient';
import { ICompanyVideoRecordingLayoutRepository } from 'application/repositorySchemas/ICompanyVideoRecordingLayoutRepository';
import { type ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';

@injectable()
export class CompanyVideoRecordingLayoutRepository
  implements ICompanyVideoRecordingLayoutRepository
{
  constructor(
    @inject('ICompanyUserTokenRepository')
    public readonly companyUserToken: ICompanyUserTokenRepository,
    @inject('ICompanyUpdateVideoRecordingLayoutClient')
    public readonly client: ICompanyUpdateVideoRecordingLayoutClient,
  ) {}

  public async update({
    channelName,
    uidListInChannel,
  }: {
    channelName: string;
    uidListInChannel: number[];
  }): Promise<IPostResponse> {
    const response = await this.client.post({
      token: this.companyUserToken.get() || '',
      channelName,
      uidListInChannel,
    });

    if (response.message) {
      throw new Error(response.message);
    }

    return response;
  }
}
