import { IAgoraRTCClient } from 'agora-rtc-react';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { CompanyVideoRecordingLayoutRepository } from 'interfaceAdapter/repositories/CompanyVideoRecordingLayoutRepository';
import { VideoRecordingLayoutRepository } from 'interfaceAdapter/repositories/VideoRecordingLayoutRepository';
import { useContext, useEffect } from 'react';
import debounce from 'lodash.debounce';

/**
 * 自分以外のユーザーの入退室イベントを捕捉して, 参加者が変わるたびに録画動画のレイアウト更新APIをコールする
 * 参加者の中でuidが最小のユーザーだけがAPIコールを行い、それ以外のユーザーは何もしない
 */
export const useUpdateRecordingLayout = ({
  client,
  uid,
  channelName,
  isBusinessUser,
}: {
  client: IAgoraRTCClient;
  uid: number;
  channelName: string;
  isBusinessUser: boolean;
}) => {
  const diContainer = useContext(diContainerContext);
  const videoRecordingLayoutRepository = diContainer.resolve(VideoRecordingLayoutRepository);
  const companyVideoRecordingLayoutRepository = diContainer.resolve(
    CompanyVideoRecordingLayoutRepository,
  );

  useEffect(() => {
    // 自分以外のユーザーの入退室イベントのイベントリスナー
    // 複数人がほぼ同時に入退室した場合にAPIコールを連発したくないので、debounceを使って制御している
    // 連続で実行された場合、連続した最後の実行から1秒sleepを挟んで実行される
    // sleepの後に実行されるのは連続した実行の中で最後のもののみ
    const listener = debounce(async () => {
      // 自分以外の入室ユーザーのuidを取得
      const remoteUsers = client.remoteUsers.map((ru) => Number(ru.uid));

      // 参加者の中でuidが一番小さい人だけがAPIコールをする
      if (uid !== Math.min(uid, ...remoteUsers)) {
        return;
      }

      // 録画動画のレイアウトを更新
      await callUpdateRecordingLayout([uid, ...remoteUsers]);
    }, 1000);

    client.on('user-joined', listener);
    client.on('user-left', listener);

    return () => {
      client.off('user-joined', listener);
      client.off('user-left', listener);
    };
  }, []);

  const callUpdateRecordingLayout = async (uidListInChannel: number[]) => {
    const promise = isBusinessUser
      ? companyVideoRecordingLayoutRepository.update({ channelName, uidListInChannel })
      : videoRecordingLayoutRepository.update({ channelName, uidListInChannel });
    await promise;
  };
};
