import styled from '@emotion/styled';
import { User } from 'domain/entities/User/User';
import MailIcon from 'ui/images/company/meet-request/mail.svg';
import { SimpleToolTip } from 'ui/components/company/features/SimpleToolTip/SimpleToolTip';
import { UserIcon } from 'ui/components/company/elements/UserIcon/UserIcon';
import { useSpreaderCard } from './useSpreaderCard';

const Wrapper = styled.div`
  padding: 12px 12px 14px;
  border: solid 1px var(--gray-light-2);
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    background-color: var(--gray-light-2);
  }
`;

const Head = styled.div`
  display: flex;
  align-items: center;
`;

const UserName = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  width: 140px;
  margin-left: 4px;
  font-weight: 700;
  font-size: 14px;
  overflow: hidden;
`;

const Action = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-left: auto;
  padding: 6px 8px;
  border-radius: 4px;
  background-color: var(--spr-black);
  color: var(--spr-white);
  font-size: var(--font-default);
  line-height: var(--font-default);
  font-weight: 700;
  white-space: nowrap;
  cursor: pointer;
  transition: opacity 0.2s ease-out;
  &:hover {
    opacity: 0.8;
  }
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
  filter: invert(1);
`;

const Label = styled.div`
  margin-top: 8px;
  font-size: var(--font-default);
  line-height: 14px;
  color: var(--gray-dark-2);
`;

const Text = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-size: var(--font-medium);
  line-height: 18px;
  color: var(--gray-dark-3);
  overflow: hidden;
`;

const Segments = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  margin-top: 10px;
`;

const Segment = styled.div`
  padding: 2px 8px;
  background-color: var(--gray-light-3);
  border-radius: 2px;
  font-size: var(--font-default);
  color: var(--gray-dark-2);
`;

interface Props {
  user: User;
}

/**
 * スプレッダーをカードスタイルで表示
 */
export const SpreaderCard: React.FC<Props> = ({ user }) => {
  const { canSendMeetRequest, openUserDetail, openMeetRequestModal } = useSpreaderCard(
    user.id as number,
  );
  return (
    <Wrapper onClick={openUserDetail}>
      <Head>
        <SimpleToolTip tooltipText={user.spreader_name}>
          <UserIcon src={user.icon_image} size={28} />
        </SimpleToolTip>
        <UserName>{user.spreader_name}</UserName>
        {canSendMeetRequest ? (
          <Action onClick={openMeetRequestModal}>
            <Icon src={MailIcon} alt="メッセージアイコン" />
            会いたい
          </Action>
        ) : null}
      </Head>

      <Label>所属企業</Label>
      <Text>{!user.company_name ? '-' : user.company_name}</Text>

      <Label>役職</Label>
      <Text>{!user.company_job ? '-' : user.company_job}</Text>

      <Segments>
        <Segment>
          {!user.industry_segment_parent
            ? '-'
            : !user.industry_segment_child
            ? user.industry_segment_parent
            : `${user.industry_segment_parent}/${user.industry_segment_child}`}
        </Segment>
        <Segment>
          {!user.job_segment_parent
            ? '-'
            : !user.job_segment_child
            ? user.job_segment_parent
            : `${user.job_segment_parent}/${user.job_segment_child}`}
        </Segment>
      </Segments>
    </Wrapper>
  );
};
