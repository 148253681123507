import styled from '@emotion/styled';
import { IProject, PROJECT_CATEGORY } from 'domain/entities/Project/Project';
import { CompanyDetail } from 'ui/components/company/patterns/CompanyDetail/CompanyDetail';
import { ProjectDesc } from 'ui/components/company/patterns/ProjectDesc/ProjectDesc';
import { ProjectManagementUsers } from 'ui/components/company/patterns/ProjectManagementUsers/ProjectManagementUsers';
import { ProjectReview } from 'ui/components/company/patterns/ProjectReview/ProjectReview';
import { ProjectShare } from 'ui/components/company/patterns/ProjectShare/ProjectShare';
import videoIcon from 'ui/images/com/videocam.svg';
import { mqUser } from 'utils/responsive';
import { useProjectDetail } from './useProjectDetail';

const Wrapper = styled.div<{ editing: boolean }>`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  padding: ${(props) => (props.editing ? '16px' : null)};
  padding-bottom: var(--height-footer);
  border-radius: 12px;
  background: ${(props) => (props.editing ? 'var(--gray-light-3);' : null)};
`;

const Interviewer = styled.div`
  margin: 15px 0 5px;
`;

const ImgContainer = styled.div`
  text-align: center;
  overflow: hidden;

  img {
    width: 100%;
    border-radius: 8px;

    ${mqUser('SP')} {
      width: 100vw;
      @supports (width: 100svw) {
        width: 100svw;
      }
    }
  }
`;

const DummyImg = styled.div`
  height: 200px;
  border-radius: 8px;
  background-color: var(--gray);
  color: var(--white);
  text-align: center;
  line-height: 200px;
  font-size: var(--font-large);
`;

const Title = styled.div<{ editing: boolean }>`
  overflow: hidden;
  color: var(--gray-black);
  text-overflow: ellipsis;
  font-size: ${(props) => (props.editing ? '20px' : '24px')};
  font-weight: var(--font-weight--bolder);
  line-height: 1.6;
`;

const CompanyName = styled.div`
  color: var(--gray-dark-2);
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
`;

const Tags = styled.div`
  display: flex;
  gap: 5px;
  margin-top: -30px;
`;

const Tag = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 3px 8px;
  border-radius: 5px;
  background-color: var(--spr-${(props) => props.color});
  color: var(--spr-white);
  font-size: 14px;
  line-height: 16px;
  margin-top: 10px;
`;

const VideoIcon = styled.img`
  width: 16px;
  height: 16px;
  filter: invert(100%);
`;

const Company = styled.div`
  margin-top: 26px;
  padding: 24px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.5);

  h3 {
    color: #b4b4b4;
    font-size: 14px;
    font-weight: var(--font-weight--bolder);
    line-height: 1.6;
    margin: 0;
  }
`;

const Relative = styled.div`
  position: relative;
`;

const Balloon = styled.div<{ noData: boolean }>`
  padding: 16px 24px;
  font-size: 14px;
  background: var(--spr-white);
  position: relative;
  top: -30px;
  width: calc(100% - 32px);
  left: 50%;
  transform: translateX(-50%);
  border-radius: 8px;
  opacity: ${(props) => (props.noData ? 0.3 : 1)};

  &:before {
    content: '';
    position: absolute;
    top: -22px;
    right: 24px;
    border: 8px solid transparent;
    border-bottom: 15px solid var(--spr-white);
  }

  div {
    color: var(--spr-primary-dark);
    font-weight: var(--font-weight--bolder);
    margin-bottom: 10px;
  }

  p {
    margin: 0;
  }
`;

/**
 * プレビュー用：プロジェクト詳細
 */
export const ProjectDetail: React.FC<{
  project: IProject;
  // セッションの作成･編集画面の場合
  editing?: boolean;
}> = ({ project, editing = false }) => {
  const { ref, onCompanyClick } = useProjectDetail();

  return (
    <Wrapper editing={editing}>
      <div>
        {/* タイトル */}
        <Title editing={editing}>
          {project.title || '（タイトルを入力するとここに表示されます）'}
        </Title>
        <CompanyName onClick={onCompanyClick}>
          {project.privacy ? '企業情報：非公開' : project.company?.name}
        </CompanyName>
      </div>
      <Relative>
        {/* メイン画像 */}
        <ImgContainer>
          {project.main_pic ? (
            <img src={project.main_pic} alt={project.main_pic ? project.title : ''} />
          ) : (
            <DummyImg>メイン画像が表示されます</DummyImg>
          )}
        </ImgContainer>
        <Balloon noData={!project.summary}>
          <div>会いたい方へ、メッセージ</div>
          {project.summary ? (
            <p dangerouslySetInnerHTML={{ __html: project.summary.replace(/\n/g, '<br>') }} />
          ) : (
            <p>（メッセージを入力するとここに表示されます）</p>
          )}
        </Balloon>
      </Relative>

      {/* タグ */}
      {!editing ? (
        <Tags className="Tags">
          {project.category === PROJECT_CATEGORY.EVENT && <Tag color="accent">イベント参加</Tag>}
          {project.interview_location && (
            <Tag color="accent">
              {project.interview_location !== 'オフライン' && (
                <VideoIcon src={videoIcon} alt="ビデオアイコン" />
              )}
              {project.interview_location}
            </Tag>
          )}
        </Tags>
      ) : null}

      {/* シェア */}
      {!editing && <ProjectShare project={project} />}

      {/* プロジェクト詳細 */}
      <ProjectDesc project={project} editing={editing} />

      {/* シェア */}
      {!editing && <ProjectShare project={project} />}

      {/* 依頼企業 */}
      {!editing && (
        <Company ref={ref}>
          {!project.privacy && (
            <>
              <h3>依頼企業について</h3>
              <CompanyDetail company={project.company} />
            </>
          )}
          {/* インタビューアー */}
          <Interviewer>
            <h3>このセッションの担当者</h3>
            <ProjectManagementUsers
              project={project}
              title={project.category === PROJECT_CATEGORY.EVENT ? 'イベント主催者' : undefined}
              onCompanyClick={onCompanyClick}
            />
          </Interviewer>
        </Company>
      )}

      {/* セッションレビュー */}
      <ProjectReview reviews={project.introduced_user_reviews} />
    </Wrapper>
  );
};
