import styled from '@emotion/styled';
import ThankYou from 'ui/images/company/register/thankyou.png';

import { PublicHeader } from 'ui/components/company/features/PublicHeader/PublicHeader';

const Body = styled.div`
  padding: 60px;
  text-align: center;
`;

const Img = styled.img`
  width: auto;
  height: 20vh;
  max-height: 300px;
  aspect-ratio: 1351/621;
`;

const CompleteText = styled.div`
  margin: 20px 0;
  font-size: var(--font-size--medium);
  line-height: 1.5;
`;

/**
 * アカウント登録 メール送信完了の表示
 */
export const CompleteSendEmail = () => {
  return (
    <>
      <PublicHeader title="メール送信完了" />
      <Body>
        <Img src={ThankYou} alt="Thank you" />
        <CompleteText>
          確認用のメールを送信しました。
          <br />
          メールに記載されている認証URLをクリックして、本登録を行なってください。
        </CompleteText>
      </Body>
    </>
  );
};
