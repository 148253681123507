import { type ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';
import { inject, injectable } from 'tsyringe';

import { type ICompanyVideoCallRecordingRepository } from 'application/repositorySchemas/ICompanyVideoCallRecordingRepository';
import {
  type ICompanyVideoCallRecordingClient,
  IPostResponse,
} from 'interfaceAdapter/gatewaySchemas/ICompanyVideoCallRecordingClient';
import { type ICompanyVideoCallRecordingFilesClient } from 'interfaceAdapter/gatewaySchemas/ICompanyVideoCallRecordingFileClient';
import { type ICreateVideoDownloadLinkClient } from 'interfaceAdapter/gatewaySchemas/ICreateVideoDownloadLinkClient';

@injectable()
export class CompanyVideoCallRecordingRepository implements ICompanyVideoCallRecordingRepository {
  constructor(
    @inject('ICompanyUserTokenRepository')
    public readonly companyUserToken: ICompanyUserTokenRepository,
    @inject('ICompanyVideoCallRecordingClient')
    public readonly client: ICompanyVideoCallRecordingClient,
    @inject('ICompanyVideoCallRecordingFileClient')
    public readonly filesClient: ICompanyVideoCallRecordingFilesClient,
    @inject('ICreateVideoDownloadLinkClient')
    public readonly createVideoDownloadLinkClient: ICreateVideoDownloadLinkClient,
  ) {}

  public async post({ channelName }: { channelName: string }): Promise<IPostResponse> {
    const response = await this.client.post({
      token: this.companyUserToken.get() || '',
      channelName,
    });

    if (response.message) {
      throw new Error(response.message);
    }

    return response;
  }

  public async listRecordingFiles({
    chatRoomUnitId,
  }: {
    chatRoomUnitId: number;
  }): Promise<Array<{ id: number; recordingStartedAt: string }>> {
    const response = await this.filesClient.get({
      token: this.companyUserToken.get() || '',
      chat_room_unit_id: chatRoomUnitId,
    });

    if (response.message) {
      throw new Error(response.message);
    }

    return response.data.map((d) => ({
      id: d.id,
      recordingStartedAt: d.recording_started_at,
    }));
  }

  public async createDownloadLink({
    videoCallRecordingFileId,
  }: {
    videoCallRecordingFileId: number;
  }): Promise<{ downloadLink: string }> {
    const response = await this.createVideoDownloadLinkClient.post({
      token: this.companyUserToken.get() || '',
      video_call_recording_file_id: videoCallRecordingFileId,
    });

    if (response.message) {
      throw new Error(response.message);
    }

    return {
      downloadLink: response.data.download_link,
    };
  }
}
