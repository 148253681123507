import { Controller } from 'react-hook-form';
import styled from '@emotion/styled';
import ok from 'ui/images/form/validation_ok.png';
import ng from 'ui/images/form/validation_ng.png';
import { Button } from 'ui/components/company/elements/Button/Button';
import { Input } from 'ui/components/company/elements/Input/Input';
import { FormLabel } from 'ui/components/company/elements/FormLabel/FormLabel';
import { SnackBar } from 'ui/components/company/elements/SnackBar/SnackBar';
import { ImageUpload } from 'ui/components/company/features/ImageUpload/ImageUpload';
import { useUserProfileEdit } from './useUserProfileEdit';

const Wrapper = styled.div`
  max-width: 600px;
  margin: 36px auto 80px;
`;

const IconWrapper = styled.div`
  display: flex;

  align-items: center;
  gap: 20px;
  margin: 10px;
  padding-inline: 10px;
`;

const IconDesc = styled.p`
  margin: 0;
  margin-left: 10px;
  margin-bottom: 50px;
  font-size: 9px;
  line-height: 1.4em;
`;
const InputWrapper = styled.div`
  position: relative;
  display: flex;
  gap: 12px;
  align-items: center;
  margin-top: 16px;
  margin-bottom: ${(props: { noMarginBottom?: boolean }) => (props.noMarginBottom ? 0 : 54)}px;
`;

const FormIcon = styled.div`
  width: 24px;
  height: 24px;
  background-image: url(${(props: { isValid: boolean }) => (props.isValid ? ok : ng)});
  background-size: 100% 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const RedText = styled.p`
  color: var(--warn);
  text-align: center;
`;

/**
 * 企業ユーザーのプロフィール編集フォーム

 */
export const UserProfileEditForm: React.FC = () => {
  const {
    isFormAvailable,
    editor,
    control,
    watch,
    formState: { errors },
    requesting,
    showSuccessSnackbar,
    setShowSuccessSnackbar,
    onImageUpload,
    onSubmit,
  } = useUserProfileEdit();

  return (
    <Wrapper>
      <SnackBar
        open={showSuccessSnackbar}
        setOpen={setShowSuccessSnackbar}
        message="プロフィールを変更しました"
      />

      <FormLabel title="アイコン画像" />
      <IconWrapper>
        <ImageUpload
          imagePath={watch('icon_image')}
          directory="profile"
          fileName={`icon${editor.id}`}
          fileCategory="company"
          disabled={requesting}
          withPreview
          width={128}
          height={128}
          onComplete={onImageUpload}
        />
      </IconWrapper>
      <IconDesc>正方形の画像をアップしてください。縦横比が変わる場合があります。</IconDesc>

      <FormLabel title="お名前" isRequired />
      <InputWrapper noMarginBottom>
        <Controller
          name="last_name"
          control={control}
          render={({ field }) => (
            <Input {...field} placeholder="姓" error={errors.last_name?.message} />
          )}
        />
        <FormIcon isValid={!errors.last_name} />
      </InputWrapper>
      <InputWrapper>
        <Controller
          name="first_name"
          control={control}
          render={({ field }) => (
            <Input {...field} placeholder="名" error={errors.first_name?.message} />
          )}
        />
        <FormIcon isValid={!errors.first_name} />
      </InputWrapper>

      <FormLabel title="部署" isRequired />
      <InputWrapper>
        <Controller
          name="department"
          control={control}
          render={({ field }) => (
            <Input {...field} placeholder="部署" error={errors.department?.message} />
          )}
        />
        <FormIcon isValid={!errors.department} />
      </InputWrapper>

      <FormLabel title="役職" />
      <InputWrapper>
        <Controller
          name="job"
          control={control}
          render={({ field }) => (
            <Input {...field} placeholder="役職" error={errors.job?.message} />
          )}
        />
        <FormIcon isValid={!errors.job} />
      </InputWrapper>

      <FormLabel title="電話番号" isRequired />
      <InputWrapper>
        <Controller
          name="phone_number"
          control={control}
          render={({ field }) => (
            <Input {...field} placeholder="090-0000-0000" error={errors.phone_number?.message} />
          )}
        />
        <FormIcon isValid={!errors.phone_number} />
      </InputWrapper>

      <ButtonWrapper>
        <Button button_type="dark" disabled={!isFormAvailable || requesting} onClick={onSubmit}>
          保存
        </Button>
      </ButtonWrapper>
      {!isFormAvailable && (
        <RedText>現在のロールでは、このユーザーのプロフィール編集ができません</RedText>
      )}
    </Wrapper>
  );
};
