import { Controller, useForm } from 'react-hook-form';
import styled from '@emotion/styled';
import yup from 'utils/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextArea } from 'ui/components/company/elements/TextArea/TextArea';
import { Button } from 'ui/components/company/elements/Button/Button';
import { Input } from 'ui/components/company/elements/Input/Input';

const Wrapper = styled.div`
  width: min(600px, 80vw);
  padding: 24px;
`;

const Title = styled.h3`
  margin: 0;
  font-size: var(--font-x-large);
  font-weight: var(--font-semi-bold);
`;

const FormItem = styled.div`
  margin: 24px 0 40px;
`;

const Label = styled.span`
  display: inline-block;
  margin-bottom: 8px;
  font-size: var(--font-medium);
  font-weight: var(--font-semi-bold);
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 60px;
`;

const schema = yup.object().shape({
  title: yup.string().max(100, '100文字以内で入力してください').required('名前を入力してください'),
  body: yup
    .string()
    .max(1000, '1000文字以内で入力してください')
    .required('メッセージ内容を入力してください'),
});

type Form = yup.InferType<typeof schema>;

interface Props {
  // 編集時は既存のメッセージ内容が渡される
  title?: string;
  body?: string;

  onCancel: () => void;
  onSave: (title: string, body: string) => void;
}

/**
 * 会いたいリクエスト 定型メッセージの編集モーダル
 */
export const MeetRequestTemplateMessageForm = ({
  title = '',
  body = '',
  onCancel,
  onSave,
}: Props) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<Form>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: { title, body },
  });

  return (
    <Wrapper>
      <Title>定型メッセージを{body ? '編集' : '作成'}</Title>

      <FormItem>
        <Label>名前</Label>
        <Controller
          name="title"
          control={control}
          render={({ field }) => (
            <Input placeholder="名前（100文字以内）" error={errors.title?.message} {...field} />
          )}
        />
      </FormItem>

      <FormItem>
        <Label>メッセージ内容</Label>
        <Controller
          name="body"
          control={control}
          render={({ field }) => (
            <TextArea
              rows={8}
              max={1000}
              resize="vertical"
              placeholder="メッセージ内容（1000文字以内）"
              error={errors.body?.message}
              {...field}
            />
          )}
        />
      </FormItem>

      <Buttons>
        <Button button_type="transparent_blue" width="120px" onClick={onCancel}>
          キャンセル
        </Button>
        <Button
          button_type="blue"
          width="120px"
          disabled={!isValid}
          onClick={handleSubmit((data) => onSave(data.title, data.body))}
        >
          保存
        </Button>
      </Buttons>
    </Wrapper>
  );
};
