import { useCallback, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CompanyMeetRequestQuery } from 'interfaceAdapter/queries/CompanyMeetRequest';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { User } from 'domain/entities/User/User';
import {
  IMeetRequest,
  MEET_REQUEST_STATUS,
  MeetRequestStatus,
} from 'domain/entities/MeetRequest/MeetRequest';
import { ISpreaderFilterForm } from 'ui/components/company/patterns/SpreaderFilter/useSpreaderFilter';
import { MEET_REQUEST_TAB } from 'ui/components/company/features/MeetRequestTabs/MeetRequestTabs';

const SPREADERS_LIMIT = 30;
const MEET_REQUEST_LIMIT = 10;

export const useMeetRequestLayout = () => {
  const diContainer = useContext(diContainerContext);
  const companyMeetRequestQuery =
    diContainer.resolve<CompanyMeetRequestQuery>(CompanyMeetRequestQuery);

  const [searchParams, setSearchParams] = useSearchParams();
  const [requesting, setRequesting] = useState(false);
  const [currentTab, setCurrentTab] = useState<string>(MEET_REQUEST_TAB.ALL);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [spreaders, setSpreaders] = useState<User[]>([]);
  const [meetRequests, setMeetRequests] = useState<IMeetRequest[]>([]);
  const [filterConditions, setFilterConditions] = useState<ISpreaderFilterForm | null>(null);

  const fetchSpreaders = useCallback(
    async (data?: ISpreaderFilterForm) => {
      setRequesting(true);
      try {
        const res = await companyMeetRequestQuery.getSpreaders(
          SPREADERS_LIMIT,
          SPREADERS_LIMIT * (page - 1),
          data ? data.company_name : null,
          data ? data.job_name : null,
          data ? data.start_age : null,
          data ? data.end_age : null,
          data ? data.industry.parent : null,
          data ? data.industry.child : null,
          data ? data.job.parent : null,
          data ? data.job.child : null,
        );
        setSpreaders(res.users);
        setTotalPage(res.totalPageCount);
      } finally {
        setRequesting(false);
      }
    },
    [page, filterConditions],
  );

  const fetchMeetRequests = useCallback(
    async (status: MeetRequestStatus) => {
      setRequesting(true);
      try {
        const res = await companyMeetRequestQuery.getMeetRequests(
          status,
          MEET_REQUEST_LIMIT,
          MEET_REQUEST_LIMIT * (page - 1),
        );
        setMeetRequests(res.meetRequests);
        setTotalPage(res.totalPageCount);
      } finally {
        setRequesting(false);
      }
    },
    [page],
  );

  const changeTab = useCallback((tab: string) => {
    setCurrentTab(tab);
    setPage(1);
    setSearchParams({ type: tab });
  }, []);

  const setFilterAndFetch = (data: ISpreaderFilterForm) => {
    setFilterConditions(data);
    setPage(1);
    fetchSpreaders(data);
  };

  // 初回設定
  useEffect(() => {
    const tabType = searchParams.get('type');
    if (!tabType) {
      return;
    }
    // @ts-expect-error クエリ文字列なので、未知の内容となる
    if (!Object.values(MEET_REQUEST_TAB).includes(tabType)) {
      return;
    }
    setCurrentTab(tabType);
  }, []);

  // 表示データのフェッチ
  useEffect(() => {
    if (currentTab === MEET_REQUEST_TAB.ALL) {
      fetchSpreaders(filterConditions || undefined);
    }
    if (currentTab === MEET_REQUEST_TAB.WAITING_ACCEPT) {
      fetchMeetRequests(MEET_REQUEST_STATUS.WAITING_ACCEPT);
    }
    if (currentTab === MEET_REQUEST_TAB.CONNECTED) {
      fetchMeetRequests(MEET_REQUEST_STATUS.CONNECTED);
    }
  }, [currentTab, page]);

  return {
    requesting,
    currentTab,
    setCurrentTab: changeTab,
    page,
    setPage,
    totalPage,
    setTotalPage,
    setFilterAndFetch,
    spreaders,
    meetRequests,
  };
};
