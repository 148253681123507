import { inject, injectable } from 'tsyringe';
import type { ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';
import { IMeetRequestMessageTemplateQuery } from 'application/querySchemas/IMeetRequestMessageTemplateQuery';
import { type IMeetRequestMessageTemplatesClient } from 'interfaceAdapter/gatewaySchemas/IMeetRequestMessageTemplatesClient';
import { type IMeetRequestMessageTemplate } from 'domain/entities/MeetRequest/MeetRequestMessageTemplate';
import { type IMeetRequestMessageTemplateFactory } from 'domain/entities/factories/MeetRequestMessageTemplate';

@injectable()
export class MeetRequestMessageTemplateQuery implements IMeetRequestMessageTemplateQuery {
  constructor(
    @inject('ICompanyUserTokenRepository')
    public readonly companyUserToken: ICompanyUserTokenRepository,
    @inject('IMeetRequestMessageTemplatesClient')
    public readonly messageTemplateClient: IMeetRequestMessageTemplatesClient,
    @inject('IMeetRequestMessageTemplateFactory')
    public readonly messageTemplateFactory: IMeetRequestMessageTemplateFactory,
  ) {}

  public async fetch(): Promise<IMeetRequestMessageTemplate[]> {
    const { data, message } = await this.messageTemplateClient.get({
      token: this.companyUserToken.get() || '',
    });

    if (message) {
      throw new Error(message);
    }

    return data.message_templates.map((messageTemplate) =>
      this.messageTemplateFactory.build(messageTemplate),
    );
  }
}
