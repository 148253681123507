import { type IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import { inject, injectable } from 'tsyringe';

import { type IVideoCallRecordingRepository } from 'application/repositorySchemas/IVideoCallRecordingRepository';
import {
  type IVideoCallRecordingClient,
  IPostResponse,
} from 'interfaceAdapter/gatewaySchemas/IVideoCallRecordingClient';

@injectable()
export class VideoCallRecordingRepository implements IVideoCallRecordingRepository {
  constructor(
    @inject('IUserTokenRepository')
    public readonly userToken: IUserTokenRepository,
    @inject('IVideoCallRecordingClient')
    public readonly client: IVideoCallRecordingClient,
  ) {}

  public async post({ channelName }: { channelName: string }): Promise<IPostResponse> {
    const response = await this.client.post({
      token: this.userToken.get() || '',
      channelName,
    });

    if (response.message) {
      throw new Error(response.message);
    }

    return response;
  }
}
