import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IPutInput,
  IPutResponse,
  ICompanyUserTeamRoleClient,
} from 'interfaceAdapter/gatewaySchemas/ICompanyUserTeamRoleClient';

export class CompanyUserTeamRoleClient implements ICompanyUserTeamRoleClient {
  async put(input: IPutInput): Promise<IPutResponse> {
    const { token, id, teamRole } = input;
    const PATH = `${API_LOCATION_SPREADY}/company/user/${id}/team_role`;
    const method = 'PUT';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };
    const body = JSON.stringify({ team_role: teamRole });
    try {
      const response = await fetch(PATH, {
        method,
        headers,
        body,
      });
      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
