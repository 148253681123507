import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'ui/components/company/elements/Button/Button';
import { useDownloadVideoCallRecordings } from 'ui/components/company/patterns/ChatDetail/useDownloadVideoCallRecordings';
import { useModal } from 'ui/components/company/screens/modals/useModal';
import { mq } from 'utils/responsive';
import { VideoPlayer } from './VideoPlayer';

const ModalWrapper = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  // gap: 1rem;
  max-height: 80vh;
  overflow-y: auto;
  ${mq('SP')} {
    width: 90vw;
    @supports (width: 100svw) {
      width: 90svw;
    }
  }
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  gap: 1rem;
  font-size: 16px;
  border-bottom: 1px solid var(--gray-light-2);
  padding: 0.5rem;
`;

const RecordText = styled.span`
  color: var(--gray-dark-1);
`;

export const VideoCallRecordings = ({
  chat_room_unit_id,
}: {
  chat_room_unit_id: number | undefined;
}) => {
  const { videoFiles, canDownload, getDownloadLink } =
    useDownloadVideoCallRecordings(chat_room_unit_id);
  const [playerOpened, setPlayerOpened] = useState(false);
  const [playerTargetRecordingFileId, setPlayerTargetRecordingFileId] = useState<number | null>(
    null,
  );

  const { openModal, createModal, closeModal } = useModal();

  const handleDownload = async (recording_id: number) => {
    const downloadLink = await getDownloadLink(recording_id);
    const a = document.createElement('a');
    a.href = downloadLink;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handlePlay = async (recordingFileId: number) => {
    setPlayerTargetRecordingFileId(recordingFileId);
    closeModal();
    setPlayerOpened(true);
  };

  return (
    <>
      {playerTargetRecordingFileId && (
        <VideoPlayer
          recordingFileId={playerTargetRecordingFileId}
          onClose={() => {
            setPlayerOpened(false);
            setPlayerTargetRecordingFileId(null);
          }}
          opened={playerOpened}
        />
      )}

      {canDownload &&
        createModal(
          <ModalWrapper>
            <div style={{ marginBottom: '1rem' }}>
              ダウンロード機能や有料プランに関するヘルプは
              <Link
                target="_blank"
                style={{ textDecoration: 'underline' }}
                to="/workgroup/how-to-video-call"
              >
                こちら
              </Link>
            </div>
            {videoFiles.map((f) => {
              const d = dayjs(f.recordingStartedAt).format('YYYY年M月D日 HH時mm分');
              return (
                <Row key={f.id}>
                  <div>
                    {d}
                    <RecordText>の録画</RecordText>{' '}
                  </div>
                  <div style={{ display: 'flex', gap: '.5rem' }}>
                    <Button
                      width="max-content"
                      button_type="transparent_blue"
                      onClick={() => handlePlay(f.id)}
                    >
                      再生
                    </Button>
                    <Button
                      width="max-content"
                      button_type="transparent_blue"
                      onClick={() => handleDownload(f.id)}
                    >
                      ダウンロード
                    </Button>
                  </div>
                </Row>
              );
            })}
          </ModalWrapper>,
        )}

      {canDownload && (
        <Button width="max-content" button_type="pale_blue" onClick={openModal}>
          録画ファイル一覧
        </Button>
      )}
    </>
  );
};
