import { inject, injectable } from 'tsyringe';
import { IMeetRequestMessageTemplateRepository } from 'application/repositorySchemas/IMeetRequestMessageTemplateRepository';
import { type ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';
import { type IMeetRequestMessageTemplateClient } from 'interfaceAdapter/gatewaySchemas/IMeetRequestMessageTemplateClient';
import { type IMeetRequestMessageTemplatesClient } from 'interfaceAdapter/gatewaySchemas/IMeetRequestMessageTemplatesClient';

@injectable()
export class MeetRequestMessageTemplateRepository implements IMeetRequestMessageTemplateRepository {
  constructor(
    @inject('ICompanyUserTokenRepository')
    public readonly companyUserToken: ICompanyUserTokenRepository,
    @inject('IMeetRequestMessageTemplateClient')
    public readonly messageTemplateClient: IMeetRequestMessageTemplateClient,
    @inject('IMeetRequestMessageTemplatesClient')
    public readonly messageTemplatesClient: IMeetRequestMessageTemplatesClient,
  ) {}

  public async create(title: string, body: string) {
    const { message } = await this.messageTemplatesClient.post({
      token: this.companyUserToken.get() || '',
      title,
      body,
    });

    if (message) {
      throw new Error(message);
    }
  }

  public async edit(id: number, title: string, body: string) {
    const { message } = await this.messageTemplateClient.put({
      token: this.companyUserToken.get() || '',
      id,
      title,
      body,
    });

    if (message) {
      throw new Error(message);
    }
  }

  public async remove(id: number) {
    const { message } = await this.messageTemplateClient.delete({
      token: this.companyUserToken.get() || '',
      id,
    });

    if (message) {
      throw new Error(message);
    }
  }
}
