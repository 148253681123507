export const Leaved = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        padding: '20px',
        fontSize: 'var(--font-size--large)',
        lineHeight: '1.5',
      }}
    >
      <div>退出しました</div>
      <div>このタブを閉じて問題ありません</div>
    </div>
  );
};
