import { Controller } from 'react-hook-form';
import styled from '@emotion/styled';
import ok from 'ui/images/form/validation_ok.png';
import ng from 'ui/images/form/validation_ng.png';
import { Button } from 'ui/components/company/elements/Button/Button';
import { Input } from 'ui/components/company/elements/Input/Input';
import { FormLabel } from 'ui/components/company/elements/FormLabel/FormLabel';
import { SnackBar } from 'ui/components/company/elements/SnackBar/SnackBar';
import { useUserCredentialsEdit } from './useUserEditCredentialsForm';

const Wrapper = styled.div`
  max-width: 600px;
  margin: 70px auto;
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  gap: 12px;
  align-items: center;
  margin-top: 16px;
  margin-bottom: ${(props: { noMarginBottom?: boolean }) => (props.noMarginBottom ? 0 : 54)}px;
`;

const FormIcon = styled.div`
  width: 24px;
  height: 24px;
  background-image: url(${(props: { isValid: boolean }) => (props.isValid ? ok : ng)});
  background-size: 100% 100%;
`;

const Small = styled.span`
  position: absolute;
  left: 4px;
  bottom: -20px;
  display: inline-block;
  margin-top: 8px;
  font-size: 11px;
  line-height: 18px;
  color: #0009;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

/**
 * 企業系 ユーザー認証情報編集フォーム
 */
export const UserEditCredentialsForm: React.FC = () => {
  const {
    control,
    formState: { errors },
    requesting,
    showSuccessSnackbar,
    setShowSuccessSnackbar,
    onSubmit,
  } = useUserCredentialsEdit();

  return (
    <Wrapper>
      <SnackBar
        open={showSuccessSnackbar}
        setOpen={setShowSuccessSnackbar}
        message="認証情報の変更を完了しました"
      />

      <FormLabel title="Email" isRequired />
      <InputWrapper>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <Input {...field} placeholder="email" error={errors.email?.message} />
          )}
        />
        <FormIcon isValid={!errors.email} />
      </InputWrapper>

      <FormLabel title="パスワード" />
      <InputWrapper>
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              type="password"
              placeholder="password"
              error={errors.password?.message}
            />
          )}
        />
        <FormIcon isValid={!errors.password} />
        <Small>変更する場合のみ入力してください</Small>
      </InputWrapper>

      <ButtonWrapper>
        <Button button_type="dark" disabled={requesting} onClick={onSubmit}>
          変更
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};
