export const ConnectingNow = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        fontSize: 'var(--font-size--large)',
        lineHeight: '1.5',
        backgroundColor: '#000',
        color: '#fff',
        height: '100vh',
      }}
    >
      <div>接続中...</div>
      <div>しばらくお待ちください</div>
    </div>
  );
};
