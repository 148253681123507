import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { companyUserContext } from 'application/contexts/useCompanyUser';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { CompanyUserLogout } from 'application/usecases/CompanyUserLogout';
import { TEAM_ROLE_NAMES } from 'domain/entities/Team/TeamRole';
import { UserIcon } from 'ui/components/company/elements/UserIcon/UserIcon';

const User = styled.div`
  cursor: pointer;
  margin-left: auto;
`;

// メニュー表示時にスクロールできないようにする
const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100vh;
  @supports (height: 100svh) {
    height: 100svh;
  }
  background-color: transparent;
  overscroll-behavior: contain;
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  touch-action: none;
  z-index: var(--tooltip);

  &:before {
    content: '';
    width: 1px;
    height: calc(100vh + 1px);
    @supports (height: 100svh) {
      height: calc(100svh + 1px);
    }
    display: flex;
  }
`;

const Popover = styled.div`
  display: inline-block;
  position: fixed;
  height: auto;
  z-index: var(--z-company-header-popover);
  pointer-events: auto;
  background-color: var(--spr-white);
  border-radius: 4px;
  top: calc(var(--height-company-pc-header) - 8px);
  right: 8px;
  transform-origin: right top;
  margin-right: 8px;
  box-shadow: 0 0 4px #a9a9a9;
  visibility: ${(props: { isHidden: boolean }) => (props.isHidden ? 'hidden' : 'visible')};
  opacity: ${(props: { isHidden: boolean }) => (props.isHidden ? 0 : 1)};
  transition: opacity 0.1s ease-in;
`;

const Profile = styled.div`
  display: grid;
  max-width: 300px;
  min-width: 180px;
  padding: 16px 20px;
`;

const JobAndDepartment = styled.div`
  margin-bottom: 4px;
  font-size: var(--font-default);
  line-height: 1.2;
  text-align: center;
`;

const Name = styled.div`
  font-size: var(--font-medium);
  text-align: center;
`;

const Role = styled.div`
  margin-top: 8px;
  font-size: var(--font-default);
  text-align: center;
`;

const Item = styled.div`
  padding: 12px 16px;
  border-top: 1px solid var(--gray-light-2);
  font-size: var(--font-default);
  text-align: center;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

/**
 * ログイン中ユーザーの情報、およびリンクをポップオーバー表示
 */
export const UserProfilePopover: React.FC = () => {
  const navigate = useNavigate();
  const diContainer = useContext(diContainerContext);
  const companyUserLogout = diContainer.resolve(CompanyUserLogout);
  const { user, resetUser } = useContext(companyUserContext);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const jobAndDepartment = [user.department, user.job].filter((v) => v).join(' ');

  const handleLogout = async () => {
    await companyUserLogout.execute();
    resetUser();
    navigate('/business/');
  };

  return (
    <>
      <User onClick={() => setPopoverOpen(!popoverOpen)}>
        <UserIcon src={user.icon_image} size={32} />
      </User>

      {popoverOpen && <Overlay onClick={() => setPopoverOpen(false)} />}

      <Popover isHidden={!popoverOpen}>
        <Profile>
          {jobAndDepartment.length > 0 && <JobAndDepartment>{jobAndDepartment}</JobAndDepartment>}
          <Name>
            {user.last_name} {user.first_name}
          </Name>
          {user.team_role && <Role>（ロール: {TEAM_ROLE_NAMES[user.team_role]}）</Role>}
        </Profile>
        <Item
          onClick={() => {
            navigate(`/workgroup/users/edit/${user.id}/`);
            setPopoverOpen(false);
          }}
        >
          プロフィール編集
        </Item>
        <Item
          onClick={() => {
            navigate(`/workgroup/edit_credentials/`);
            setPopoverOpen(false);
          }}
        >
          メールアドレス・パスワード変更
        </Item>
        <Item onClick={handleLogout}>ログアウト</Item>
      </Popover>
    </>
  );
};
