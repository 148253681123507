import styled from '@emotion/styled';
import { UID } from 'agora-rtc-react';
import { memo } from 'react';
import { mqUser } from 'utils/responsive';

export const VIDEO_CALL_HEADER_HEIGHT_PX = 50;

const Wrapper = styled.div`
  width: 100%;
  height: ${VIDEO_CALL_HEADER_HEIGHT_PX}px;
  background-color: #101a25;
  color: white;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  font-size: 1.2rem;
  gap: 10px;

  ${mqUser('NARROW')} {
    display: none;
  }
`;

export const Header = memo(function Header({ remoteUserUids }: { remoteUserUids: UID[] }) {
  return (
    <Wrapper>
      あなた以外に {remoteUserUids.filter((id) => Number(id) < 1000000000).length} 人が参加中
    </Wrapper>
  );
});
