import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IPostInput,
  IPostResponse,
  ITeamBusinessPlanInquireClient,
} from 'interfaceAdapter/gatewaySchemas/ITeamBusinessPlanInquireClient';

export class TeamBusinessPlanInquireClient implements ITeamBusinessPlanInquireClient {
  async post(input: IPostInput): Promise<IPostResponse> {
    const PATH = `${API_LOCATION_SPREADY}/company/business_plan/inquire`;
    const { token } = input;
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };
    const method = 'POST';
    try {
      const response = await fetch(`${PATH}`, {
        method,
        headers,
      });
      const { status } = response;
      const { data, message } = await response.json();

      return {
        data,
        message,
        status,
      };
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
