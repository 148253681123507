import * as yup from 'yup';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { isIpAddress } from './ipAddress';

dayjs.extend(customParseFormat);

declare module 'yup' {
  interface StringSchema {
    dateLike(format?: string): StringSchema;
    phone(): StringSchema;
    zipCode(): StringSchema;
    katakanaOnly(): StringSchema;
    ipAddress(): StringSchema;
  }
}

const jpLocale: yup.LocaleObject = {
  mixed: {
    required: (param: { label?: string }) =>
      `${param.label ? param.label + 'は' : 'この項目は'}入力が必須です`,
  },
  string: {
    length: (param: { length: number }) => `${param.length}文字で入力してください`,
    min: (param: { min: number }) => `${param.min}文字以上で入力してください`,
    max: (param: { max: number }) => `${param.max}文字以下で入力してください`,
    email: 'メールアドレスを入力してください',
    matches: () => '正しく入力してください',
  },
  number: {
    min: (param: { min: number }) => `${param.min}以上の数値を入力してください`,
    max: (param: { max: number }) => `${param.max}以下の数値を入力してください`,
  },
  date: {
    min: (param: { min: string | Date }) => `${param.min}より後の日付を入力してください`,
    max: (param: { max: string | Date }) => `${param.max}より前の日付を入力してください`,
  },
  array: {
    min: (param: { min: number }) => `${param.min}個以上を選択してください`,
  },
};

yup.setLocale(jpLocale);

yup.addMethod(yup.string, 'dateLike', function (format = 'YYYY/MM/DD') {
  return this.test({
    name: 'dateLike',
    message: '正しい日付を入力してください',
    test: (value) => (value === '' ? true : dayjs(value, format, true).isValid()),
  });
});

yup.addMethod(yup.string, 'phone', function () {
  return this.test({
    name: 'phone',
    message: '正しい電話番号を入力してください',
    test: (value) =>
      value === undefined || value === '' ? true : /^[0-9]{1,9}-[0-9]{1,9}-[0-9]{1,9}$/.test(value),
  });
});

yup.addMethod(yup.string, 'zipCode', function () {
  return this.test({
    name: 'zipCode',
    message: '正しい郵便番号を入力してください',
    test: (value) =>
      value === undefined || value === '' ? true : /^[0-9]{3}-[0-9]{4}$/.test(value),
  });
});

yup.addMethod(yup.string, 'katakanaOnly', function () {
  return this.test({
    name: 'katakanaOnly',
    message: 'カタカナのみで入力してください',
    test: (value) => (value === undefined || value === '' ? true : /^[ァ-ヶー]+$/.test(value)),
  });
});

yup.addMethod(yup.string, 'ipAddress', function () {
  return this.test({
    name: 'ipAddress',
    message: '正しいIPアドレスを入力してください',
    test: (value) => (value === undefined || value === '' ? true : isIpAddress(value)),
  });
});

export default yup;
