import { companyUserContext } from 'application/contexts/useCompanyUser';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { CompanyUserCheckToken } from 'application/usecases/CompanyUserCheckToken';
import { COMPANY_STATUS } from 'domain/entities/Company/Company';
import { REGISTRATION_STEPS } from 'domain/entities/CompanyUser/CompanyUser';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

/**
 * 認証状態の確認と、ログイン後ページの共通処理
 */
export const useCompanyStandard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const diContainer = useContext(diContainerContext);
  const companyUserCheckToken = diContainer.resolve(CompanyUserCheckToken);
  const { setUser, setUrlAfterLogin } = useContext(companyUserContext);

  const [canEnter, setCanEnter] = useState(false);

  useEffect(() => {
    const initialFetch = async () => {
      const result = await companyUserCheckToken.execute(true);
      if (result === false) {
        return;
      }

      // ユーザー登録ステップが完了していない場合はリダイレクト
      if (result.registration_step === REGISTRATION_STEPS.NEED_USER_REGISTRATION_STEP) {
        return navigate('/business/register/user/profile/');
      }
      if (result.registration_step === REGISTRATION_STEPS.NEED_COMPANY_REGISTRATION_STEP) {
        return navigate('/business/register/workgroup/profile/');
      }
      if (result.registration_step === REGISTRATION_STEPS.NEED_REGISTRATION_CONFIRMATION) {
        return navigate('/business/register/confirm_profile/');
      }

      setUser(result);
    };
    initialFetch();
  }, []);

  useEffect(() => {
    const checkCanEnter = async () => {
      const result = await companyUserCheckToken.execute(true);
      if (result === false) {
        // 認証が必要
        setUrlAfterLogin(location.pathname);
        return navigate('/business/', { replace: true });
      }

      if (
        (result.company?.status === COMPANY_STATUS.TERMINATED ||
          result.company?.status === COMPANY_STATUS.TERMINATED_BY_SPREADY) &&
        location.pathname !== '/workgroup/switch/'
      ) {
        // Teamが無効化されている
        return navigate('/workgroup/switch/', { replace: true });
      }

      setUser(result);
      setCanEnter(true);
    };
    checkCanEnter();
  }, [location]);

  return { canEnter };
};
