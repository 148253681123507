import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'utils/yup';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { companyUserContext } from 'application/contexts/useCompanyUser';
import { CompanyUserRepository } from 'interfaceAdapter/repositories/CompanyUser';
import { CompanyUserQuery } from 'interfaceAdapter/queries/CompanyUser';

const schema = yup
  .object()
  .shape(
    {
      email: yup.string().email().default('').required(),
      password: yup.string().when('password', {
        is: (password: string) => password === '',
        then: (schema) => schema.optional(),
        otherwise: (schema) => schema.default('').min(9).max(30).required(),
      }),
    },
    [['password', 'password']],
  )
  .required();

type Form = yup.InferType<typeof schema>;

export const useUserCredentialsEdit = () => {
  const diContainer = useContext(diContainerContext);
  const companyUserQuery = diContainer.resolve<CompanyUserQuery>(CompanyUserQuery);
  const companyUserRepository = diContainer.resolve<CompanyUserRepository>(CompanyUserRepository);
  const { user, setUser } = useContext(companyUserContext);
  const { control, handleSubmit, formState, setValue, watch } = useForm<Form>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  const [requesting, setRequesting] = useState(false);
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);

  const onSubmit = handleSubmit(async (form) => {
    setRequesting(true);
    try {
      await companyUserRepository.editCredentials(form.email, form.password ?? '');
      setShowSuccessSnackbar(true);

      const newUser = await companyUserQuery.checkToken(true);
      if (newUser) {
        setUser(newUser);
      }
    } finally {
      setRequesting(false);
    }
  });

  useEffect(() => {
    setValue('email', user.email);
    setValue('password', '');
  }, []);

  return {
    control,
    watch,
    formState,
    requesting,
    showSuccessSnackbar,
    setShowSuccessSnackbar,
    onSubmit,
  };
};
