import styled from '@emotion/styled';
import { UserEditCredentialsForm } from 'ui/components/company/patterns/UserEditCredentialsForm/UserEditCredentialsForm';
import { PageTitle } from 'ui/components/company/elements/PageTitle/PageTitle';

const Wrapper = styled.div`
  padding: 30px;
`;

/**
 * 企業ユーザー 認証情報編集レイアウト
 */
export const UserEditCredentials = () => {
  return (
    <Wrapper>
      <PageTitle title="メールアドレス・パスワード変更" />
      <UserEditCredentialsForm />
    </Wrapper>
  );
};
