import * as yup from 'yup';
import { ITeam, Team } from 'domain/entities/Team/Team';
import { companySchema } from 'domain/entities/factories/Company';

// Todo: Companyを削除したら、companySchemaの内容をこちらに持ってくる
export const teamSchema = companySchema;

export type ITeamSchema = yup.InferType<typeof teamSchema>;

export const myTeamSchema = teamSchema.pick([
  'id',
  'url_hash',
  'status',
  'name',
  'meta_description',
  'icon_image',
  'about_company',
]);

export type IMyTeamSchema = yup.InferType<typeof myTeamSchema>;

export interface ITeamFactory {
  buildMyTeam(input: IMyTeamSchema): ITeam;
}

export class TeamFactory implements ITeamFactory {
  public buildMyTeam(input: IMyTeamSchema) {
    return new Team(
      input.id,
      input.name,
      input.meta_description,
      '',
      0,
      0,
      input.icon_image ?? '',
      '',
      new Date(),
      '',
      input.status,
      input.url_hash === undefined ? null : input.url_hash,
      '',
      null,
      '',
      [],
      input.about_company ?? null,
    );
  }
}
