import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IGetInput,
  IGetResponse,
  IVideoStreamingManifestClient,
} from 'interfaceAdapter/gatewaySchemas/IVideoStreamingManifestClient';

export class VideoStreamingManifestClient implements IVideoStreamingManifestClient {
  async get(input: IGetInput): Promise<IGetResponse> {
    const PATH = `${API_LOCATION_SPREADY}/video_call/video_call_recording_files/${input.recordingFileId}/streaming_manifest`;
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': input.token,
    };
    const method = 'GET';
    try {
      const response = await fetch(`${PATH}`, { method, headers });
      const { status } = response;
      const { data, message } = await response.json();

      return {
        data,
        message,
        status,
      };
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
