import styled from '@emotion/styled';
import { CHAT_LABEL_MAP } from 'domain/entities/ChatRoom/ChatRoom';
import { Link } from 'react-router-dom';
import { BackButton } from 'ui/components/user/elements/BackButton/BackButton';
import { UserIcon } from 'ui/components/user/elements/UserIcon/UserIcon';
import { MessageInput } from 'ui/components/user/features/MessageInput/MessageInput';
import { MessageItem } from 'ui/components/user/features/MessageItem/MessageItem';
import { RoomMeetRequest } from 'ui/components/user/features/RoomMeetRequest/RoomMeetRequest';
import { RoomProjectSummary } from 'ui/components/user/features/RoomProjectSummary/RoomProjectSummary';
import { StartVideoCallLink } from 'ui/components/user/features/StartVideoCall/StartVideoCallLink';
import { VideoCallDeviceTest } from 'ui/components/user/screens/modals/VideoDeviceTest/VideoCallDeviceTest';
import { mqUser } from 'utils/responsive';
import { useRoomDetail } from './useRoomDetail';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - var(--height-user-pc-header) - 30px - 20px);
  @supports (height: 100svh) {
    height: calc(100svh - var(--height-user-pc-header) - 30px - 20px);
  }
  border-radius: 16px;
  background-color: var(--spr-white);
  box-shadow: 0px 2px 4px 0px #0000000d;

  ${mqUser('SP')} {
    flex: unset;
    position: absolute;
    top: 100vh;
    left: 0;
    @supports (width: 100svw) {
      width: 100svw;
    }
    height: calc(100vh - var(--height-user-pc-header) + 48px);
    border-radius: 16px 16px 0 0;

    overflow: hidden;
    z-index: var(--z-room-detail);
    transition: transform 0.2s ease-out;
    // 下からスッと登場
    transform: translateY(
      ${(props: { isOpen: boolean }) =>
        props.isOpen ? 'calc(-100vh + var(--height-user-pc-header) - 48px)' : '0'}
    );
    @supports (height: 100svh) {
      height: calc(100svh - var(--height-user-pc-header) + 48px);
      top: 100svh;
      transform: translateY(
        ${(props: { isOpen: boolean }) =>
          props.isOpen ? 'calc(-100svh + var(--height-user-pc-header) - 48px)' : '0'}
      );
    }
  }
`;

const Header = styled.div`
  display: grid;
  grid-template-areas: 'project label company';
  grid-template-columns: auto auto 1fr;
  place-items: center start;
  column-gap: 12px;
  row-gap: 8px;
  padding: 14px 16px;
  border-bottom: 1px solid var(--gray-light-2);
  ${mqUser('SP')} {
    grid-template-areas:
      'back project label'
      '. company company'
      '. videobutton videobutton';
    grid-template-columns: 24px 1fr auto;
    place-items: flex-start;
    column-gap: 8px;
    row-gap: 4px;
    padding: 12px;
  }
`;

const Back = styled(BackButton)`
  grid-area: back;
  display: none;
  ${mqUser('SP')} {
    display: block;
    margin-top: 4px;
  }
`;

const Project = styled.p`
  grid-area: project;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin: 0;
  line-height: 1.4;
  overflow: hidden;
  font-weight: var(--font-bold);
  font-size: var(--font-large);
`;

const Label = styled.div`
  grid-area: label;
  padding: 6px 8px;
  background-color: var(--spr-primary-paler);
  border-radius: 4px;
  color: var(--spr-secondary);
  font-weight: var(--font-bold);
  font-size: var(--font-medium);
  white-space: nowrap;
`;

const Company = styled.div`
  grid-area: company;
  place-self: center end;
  display: flex;
  align-items: center;
  gap: 4px;
  margin-left: 16px;
  ${mqUser('SP')} {
    place-self: unset;
    margin-left: 0;

    img {
      display: none;
    }
  }

  p {
    display: inline-block;
    margin: 0;
    font-weight: var(--font-semi-bold);
    font-size: var(--font-medium);
    color: var(--gray-dark-3);
    white-space: nowrap;
  }
`;

const VideoButtonArea = styled.div`
  grid-area: videobutton;
  width: 100%;

  display: flex;
  gap: 1rem;
`;

const MembersWrapper = styled.div`
  position: sticky;
  top: 0;
  padding: 8px 0;
  background-color: rgba(255, 255, 255, 0.85);
`;

const Members = styled.div<{ total: number }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  // MemberIcon の translateX を相殺する
  transform: translateX(${(props) => (props.total - 1) * 3}px);
`;

const MemberIcon = styled.div<{ index: number }>`
  // アイコン同士をちょっとずつ重ねる
  transform: translateX(-${(props) => props.index * 3}px);
`;

const MessagesWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 0 16px;
  ${mqUser('SP')} {
    padding: 0 12px;
  }
`;

const VideoCallIntro = styled.div`
  width: 80%;
  margin: 16px auto;
  min-width: 300px;
  max-width: 600px;
  display: flex;
  gap: 0.7rem;
  flex-direction: column;
  padding: 16px;
  background-color: var(--spr-primary-paler);
  border-radius: 4px;
  color: var(--spr-secondary);
  font-size: var(--font-medium);
  line-height: 1.6;
`;

const ControlArea = styled.div`
  display: flex;
  gap: 0.5rem;
  padding: 16px;
  justify-content: flex-end;
  width: 100%;
`;

const MessageInputWrapper = styled.div`
  padding: 10px 16px 16px;
  ${mqUser('SP')} {
    padding: 10px 12px 12px;
  }
`;

const NoData = styled.div`
  margin-top: 20px;
  text-align: center;
`;

/**
 * チャットルーム内容 表示
 */
export const RoomDetail: React.FC = () => {
  const {
    user,
    room,
    isOpen,
    messages,
    members,
    meetRequests,
    sendMessage,
    removeMessage,
    onMessageSent,
    messagesRef,
    backToList,
  } = useRoomDetail();

  return (
    <Wrapper isOpen={isOpen}>
      {room && room.room_id && (
        <>
          <Header>
            <Back className="btn" onClick={backToList} />

            <Project>{room.project && room.project.title}</Project>

            <Label>{CHAT_LABEL_MAP[room.label]}</Label>

            {room?.company && (
              <Company>
                {room.project?.privacy ? (
                  <p>企業情報：非公開</p>
                ) : (
                  <>
                    <UserIcon
                      src={room.company.icon_image}
                      alt={room.company.name ?? ''}
                      size={30}
                    />
                    <p>{room.company.name}</p>
                  </>
                )}
              </Company>
            )}

            <VideoButtonArea>
              <VideoCallDeviceTest />
              <StartVideoCallLink chatRoomUnitId={room?.chat_room_unit_id} />
            </VideoButtonArea>
          </Header>

          <MessagesWrapper ref={messagesRef}>
            <ControlArea>
              <MembersWrapper>
                <Members total={members.companyUsers.length + members.users.length}>
                  参加者：
                  {members.companyUsers.map((companyUser, index) => (
                    <MemberIcon key={companyUser.id} index={index}>
                      <UserIcon
                        src={companyUser.icon_image}
                        alt={`${companyUser.last_name} ${companyUser.first_name}`}
                        size={32}
                      />
                    </MemberIcon>
                  ))}
                  {members.users.map((user, index) => (
                    <MemberIcon key={user.id} index={index + members.companyUsers.length}>
                      <UserIcon
                        src={user.icon_image}
                        alt={`${user.last_name} ${user.first_name}`}
                        size={32}
                      />
                    </MemberIcon>
                  ))}
                </Members>
              </MembersWrapper>
            </ControlArea>

            {/* 応募・紹介 */}
            {meetRequests.length === 0 ? <RoomProjectSummary project={room.project} /> : null}

            {/* 会いたいリクエスト */}
            {meetRequests.length > 0
              ? meetRequests.map((mr) => (
                  <RoomMeetRequest key={mr.created_at.getTime()} meetRequest={mr} />
                ))
              : null}

            <VideoCallIntro>
              <div>本セッションではSpreadyビデオ通話を利用する可能性があります</div>
              <div>
                企業からZoomやGoogle
                Meet等のURLのご案内がない場合は、ご面談時間までに画面上部の「カメラとマイクをテスト」ボタンから事前にデバイスのテストを行い、問題がないことをご確認ください。
                <Link
                  target="_blank"
                  style={{ textDecoration: 'underline' }}
                  to="https://spready.notion.site/3b311093f1184c9c835cbfe7e8ebaf0a"
                >
                  詳しくはこちら
                </Link>
              </div>
            </VideoCallIntro>

            {messages.length === 0 && <NoData>メッセージがまだありません</NoData>}
            {messages.map((message) => {
              // 衝突回避のためtimestamp単体ではやらないようにした
              const key = `${message.timestamp}_${message.user_type}_${message.user_id}`;
              return (
                <MessageItem
                  key={key}
                  message={message}
                  user={user}
                  removeMessage={removeMessage}
                />
              );
            })}
          </MessagesWrapper>

          <MessageInputWrapper>
            <MessageInput
              roomId={room.room_id}
              sendMessage={sendMessage}
              onComplete={onMessageSent}
            />
          </MessageInputWrapper>
        </>
      )}
    </Wrapper>
  );
};
