import styled from '@emotion/styled';
import PushPinIcon from '@mui/icons-material/PushPin';
import React from 'react';

const Wrapper = styled.div<{ pinned: boolean }>`
  position: absolute;
  bottom: 5px;
  right: 5px;
  z-index: 1;
  padding: 10px;
  background-color: ${({ pinned }: { pinned: boolean }) => (pinned ? 'yellow' : '#1e2931')};
  color: ${({ pinned }: { pinned: boolean }) => (pinned ? 'black' : 'white')};
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;
  width: 40px;
`;

export const TogglePin = React.memo(function TogglePin({
  pinned,
  onChange,
}: {
  pinned: boolean;
  onChange: () => void;
}) {
  return (
    <Wrapper
      pinned={pinned}
      onClick={(e) => {
        e.stopPropagation();
        onChange();
      }}
    >
      <PushPinIcon sx={{ color: 'inherit' }} />
    </Wrapper>
  );
});
