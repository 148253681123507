import { type IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import { inject, injectable } from 'tsyringe';

import {
  type IUpdateVideoRecordingLayoutClient,
  IPostResponse,
} from 'interfaceAdapter/gatewaySchemas/IUpdateVideoRecordingLayoutClient';
import { IVideoRecordingLayoutRepository } from 'application/repositorySchemas/IVideoRecordingLayoutRepository';

@injectable()
export class VideoRecordingLayoutRepository implements IVideoRecordingLayoutRepository {
  constructor(
    @inject('IUserTokenRepository')
    public readonly userToken: IUserTokenRepository,
    @inject('IUpdateVideoRecordingLayoutClient')
    public readonly client: IUpdateVideoRecordingLayoutClient,
  ) {}

  public async update({
    channelName,
    uidListInChannel,
  }: {
    channelName: string;
    uidListInChannel: number[];
  }): Promise<IPostResponse> {
    const response = await this.client.post({
      token: this.userToken.get() || '',
      channelName,
      uidListInChannel,
    });

    if (response.message) {
      throw new Error(response.message);
    }

    return response;
  }
}
