import { UID, useRTCClient } from 'agora-rtc-react';
import { useState } from 'react';
import { MuteIcon } from '../../elements/MuteIcon/MuteIcon';

export const RemoteUserMuted = ({ uid }: { uid: UID }) => {
  const client = useRTCClient();
  const [muted, setMuted] = useState(false);

  client.on('user-info-updated', (userId, eventType) => {
    if (eventType === 'mute-audio' && userId === uid) {
      // リモートユーザーがオーディオをミュートした
      setMuted(true);
    }
    if (eventType === 'unmute-audio' && userId === uid) {
      // リモートユーザーがオーディオのミュートを解除した
      setMuted(false);
    }
  });

  return <MuteIcon muted={muted} />;
};
