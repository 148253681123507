import { diContainerContext } from 'application/contexts/useDiContainer';
import { VideoTokenRepository } from 'interfaceAdapter/repositories/VideoTokenRepository';
import { useContext } from 'react';

export const useStartVideoCall = (chatRoomUnitIdStr: string | number | null | undefined) => {
  const diContainer = useContext(diContainerContext);
  const tokenRepository = diContainer.resolve(VideoTokenRepository);

  const getStartVideoCallPageLink = () => {
    return `/user/start-video-call/?chat_room_unit_id=${chatRoomUnitIdStr}`;
  };

  const handleStartVideoCall = async (): Promise<void> => {
    if (!chatRoomUnitIdStr) {
      return;
    }

    const chatRoomUnitId = Number(chatRoomUnitIdStr);
    if (Number.isNaN(chatRoomUnitId)) {
      return;
    }

    const result = await tokenRepository.post({ chatRoomUnitId });
    if (!result.data.token || !result.data.rtmToken || !result.data.rtmUid) {
      throw new Error('ビデオ開始に失敗しました');
    }

    location.href = `/user/video-call/?channelName=${result.data.channelName}&uid=${
      result.data.uid
    }&token=${encodeURIComponent(result.data.token)}&screenShareUid=${
      result.data.screenShareUid
    }&screenShareToken=${encodeURIComponent(
      result.data.screenShareToken,
    )}&rtmToken=${encodeURIComponent(result.data.rtmToken)}&rtmUid=${result.data.rtmUid}`;
  };

  return { getStartVideoCallPageLink, handleStartVideoCall };
};
