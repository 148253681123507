import { diContainerContext } from 'application/contexts/useDiContainer';
import { IVideoStreamingManifestQuery } from 'application/querySchemas/IVideoStreamingManifestQuery';
import { IGetResponse } from 'interfaceAdapter/gatewaySchemas/IVideoStreamingManifestClient';
import { useContext, useEffect, useState } from 'react';

export const useVideoStreamingManifestQuery = (args: { recordingFileId: number }) => {
  const diContainer = useContext(diContainerContext);
  const VideoStreamingManifestQuery = diContainer.resolve<IVideoStreamingManifestQuery>(
    'IVideoStreamingManifestQuery',
  );

  const [data, setData] = useState<IGetResponse['data']>();
  const [fetching, setFetching] = useState(false);

  const fetchVideoStreamingManifest = async () => {
    setFetching(true);
    const response = await VideoStreamingManifestQuery.get(args);

    setData(response.data);
    setFetching(false);
  };

  useEffect(() => {
    fetchVideoStreamingManifest();
  }, []);

  return {
    fetching,
    data,
    refetch: fetchVideoStreamingManifest,
  };
};
