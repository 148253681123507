import { type ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';
import { inject, injectable } from 'tsyringe';

import { type ITeamBusinessPlanInquireRepository } from 'application/repositorySchemas/ITeamBusinessPlanInquireRepository';
import {
  type ITeamBusinessPlanInquireClient,
  IPostResponse,
} from 'interfaceAdapter/gatewaySchemas/ITeamBusinessPlanInquireClient';

@injectable()
export class TeamBusinessPlanInquireRepository implements ITeamBusinessPlanInquireRepository {
  constructor(
    @inject('ICompanyUserTokenRepository')
    public readonly companyUserToken: ICompanyUserTokenRepository,
    @inject('ITeamBusinessPlanInquireClient')
    public readonly client: ITeamBusinessPlanInquireClient,
  ) {}

  public async post(): Promise<IPostResponse> {
    const response = await this.client.post({
      token: this.companyUserToken.get() || '',
    });

    if (response.message) {
      throw new Error(response.message);
    }
    return response;
  }
}
