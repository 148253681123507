import { IRemoteVideoTrack, RemoteVideoTrack, UID } from 'agora-rtc-react';
import { memo } from 'react';
import { RemoteUserMuted } from 'ui/components/video/features/RemoteUserMuted/RemoteUserMuted';
import { VideoIndicator } from 'ui/components/video/features/VideoIndicator/VideoIndicator';
import { TogglePin } from 'ui/components/video/features/VideoPin/TogglePin';
import { useVideoPin } from 'ui/components/video/features/VideoPin/useVideoPin';

// 呼び出し元コンポーネントの再描画の度に再描画しないようにするためにメモ化している
// 再描画が走ると映像が一瞬点滅するように見えてしまうため
export const RemoteVideo = memo(
  ({
    videoTrack,
    index,
    userName,
    uid,
  }: {
    videoTrack?: IRemoteVideoTrack;
    index: number;
    userName?: string;
    uid: UID;
  }) => {
    // const [fit, setFit] = React.useState<'cover' | 'contain'>('contain');
    const { pinnedVideoIndex, setPinnedVideoIndex } = useVideoPin();

    return (
      <div
        style={{
          position: 'relative',
          maxWidth: '100%',
          maxHeight: '100%',
          width: '100%',
          minWidth: 0,
          aspectRatio: '4 / 3',
        }}
      >
        {videoTrack ? (
          <>
            <RemoteVideoTrack
              style={{
                width: '100%',
                height: '100%',
                position: 'relative',
                borderRadius: '10px',
                border: '1px solid #1e2931',
                overflow: 'hidden',
              }}
              as="div"
              videoPlayerConfig={{ fit: 'contain' }}
              key={videoTrack.getTrackId()}
              track={videoTrack}
              play={true}
            />
          </>
        ) : null}

        <VideoIndicator uid={uid} />

        <div
          style={{
            position: 'absolute',
            top: '10px',
            left: '10px',
            fontSize: '1.5rem',
            display: 'flex',
            padding: '5px',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            color: 'white',
          }}
        >
          {userName}
        </div>

        <div
          style={{
            position: 'absolute',
            bottom: '10px',
            left: '10px',
            fontSize: '1.5rem',
            display: 'flex',
          }}
        >
          <RemoteUserMuted uid={uid} />
        </div>

        <TogglePin
          pinned={pinnedVideoIndex === index}
          onChange={() => {
            setPinnedVideoIndex(pinnedVideoIndex === index ? null : index);
          }}
        />

        {/* <ToggleFit
          value={fit}
          onChange={(value) => {
            setFit(value);
          }}
        /> */}
      </div>
    );
  },
);
RemoteVideo.displayName = 'RemoteVideo';
