import styled from '@emotion/styled';
import { CASTING_TYPE, castingsContext } from 'application/contexts/useCastings';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { statsContext } from 'application/contexts/useStats';
import { IUserNotificationQuery } from 'application/querySchemas/IUserNotificationQuery';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Pager } from 'ui/components/user/elements/Pager/Pager';
import { CastingCard } from 'ui/components/user/patterns/CastingCard/CastingCard';
import { CastingMessage } from 'ui/components/user/patterns/CastingMessage/CastingMessage';
import { useCastingList } from './useCastingList';

const Wrapper = styled.div`
  min-height: calc(100vh - var(--height-user-pc-header) - 150px - 180px);
  @supports (height: 100svh) {
    min-height: calc(100svh - var(--height-user-pc-header) - 150px - 180px);
  }
  margin-bottom: 16px;
`;

const NoData = styled.p`
  text-align: center;
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const ListItem = styled.li`
  margin-bottom: 20px;
`;

/**
 * 面談or紹介の一覧表示
 */
export const CastingList: React.FC = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const diContainer = useContext(diContainerContext);
  const userStatsQuery = diContainer.resolve<IUserNotificationQuery>('IUserNotificationQuery');
  const { fetchStats } = useContext(statsContext);

  const { castings: originalCastings, fetchData: fetchCastings } = useContext(castingsContext);

  const { page, setPage, maxPage, castings, castingType, refetch } = useCastingList({
    castings: originalCastings,
    fetchCastings,
    fetchStats: () => fetchStats(userStatsQuery.get),
  });

  return (
    <Wrapper>
      {castings.length === 0 ? (
        <NoData>対象のデータがありません</NoData>
      ) : (
        <>
          <List>
            {castings.map((casting) => (
              <ListItem key={casting.id}>
                <CastingCard
                  casting={casting}
                  castingType={castingType}
                  refetch={refetch}
                  defaultDrawerOpen={
                    query.get('id') === casting.id.toString() ||
                    query.get('project_id') === casting.project?.id?.toString()
                  }
                />
                {castingType === CASTING_TYPE.CASTING ? (
                  <CastingMessage casting={casting} displayType="new" refetch={refetch} />
                ) : null}
              </ListItem>
            ))}
          </List>
          <Pager page={page} maxPage={maxPage} onChange={setPage} />
        </>
      )}
    </Wrapper>
  );
};
