import { authContext } from 'application/contexts/useAuth';
import { useContext, useState } from 'react';
import { useForm, Control, FieldErrors } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'utils/yup';

const schema = yup
  .object({
    email: yup.string().required().email(),
    password: yup.string().required(),
  })
  .required();

type LoginForm = yup.InferType<typeof schema>;

interface UseLoginFormReturn {
  control: Control<LoginForm>;
  errors: FieldErrors<LoginForm>;
  loading: boolean;
  /** 自薦? */
  isInvited: boolean;
  /** 紹介? */
  isIntroduced: boolean;
  login: () => Promise<void>;
}

export const useLoginForm = (): UseLoginFormReturn => {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginForm>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
    },
  });
  const { castInfo, urlAfterLogin, loginByEmail } = useContext(authContext);
  const [loading, setLoading] = useState(false);

  const { isInvited, isIntroduced } = castInfo;

  const login = handleSubmit(async ({ email, password }) => {
    setLoading(true);
    try {
      await loginByEmail(email, password, castInfo);
      if (castInfo.isInvited) {
        return navigate(`/cast-complete/?redirect=/user/casting/casted/`);
      }
      if (castInfo.isIntroduced) {
        return navigate(`/cast-complete/?redirect=/user/casting/`);
      }
      navigate(urlAfterLogin);
    } catch (e) {
      // エラー通知に気づかせるため
      window.scrollTo({ top: 0, behavior: 'smooth' });
      throw e;
    } finally {
      setLoading(false);
    }
  });

  return {
    control,
    errors,
    loading,
    isInvited,
    isIntroduced,
    login,
  };
};
