export interface IMeetRequestMessageTemplate {
  id: number;
  title: string;
  body: string;
  created_at: Date;
}

export class MeetRequestMessageTemplate implements IMeetRequestMessageTemplate {
  constructor(
    public id: number,
    public title: string,
    public body: string,
    public created_at: Date,
  ) {}
}
