import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IPutInput,
  IPutResponse,
  ICompanyUserCredentialsClient,
} from 'interfaceAdapter/gatewaySchemas/ICompanyUserCredentialsClient';

const PATH = `${API_LOCATION_SPREADY}/company/user/credentials`;

export class CompanyUserCredentialsClient implements ICompanyUserCredentialsClient {
  async put(input: IPutInput): Promise<IPutResponse> {
    const { token, email, password } = input;
    const method = 'PUT';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };
    const body = JSON.stringify({ email, password });
    try {
      const response = await fetch(PATH, {
        method,
        headers,
        body,
      });
      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
