import { diContainerContext } from 'application/contexts/useDiContainer';
import { CompanyVideoCallRecordingRepository } from 'interfaceAdapter/repositories/CompanyVideoCallRecordingRepository';
import { useContext, useEffect, useState } from 'react';

type VideoFile = {
  id: number;
  recordingStartedAt: string;
};

export const useDownloadVideoCallRecordings = (chatRoomUnitId: number | undefined) => {
  const diContainer = useContext(diContainerContext);
  const videoCallRecordingRepository = diContainer.resolve(CompanyVideoCallRecordingRepository);
  const [videoFiles, setVideoFiles] = useState<VideoFile[]>([]);
  const canDownload = videoFiles.length > 0;

  useEffect(() => {
    load();
  }, [chatRoomUnitId]);

  const load = async () => {
    if (!chatRoomUnitId) {
      return;
    }

    const recordings = await videoCallRecordingRepository.listRecordingFiles({ chatRoomUnitId });
    setVideoFiles(recordings);
  };

  const getDownloadLink = async (videoCallRecordingFileId: number): Promise<string> => {
    const res = await videoCallRecordingRepository.createDownloadLink({ videoCallRecordingFileId });
    return res.downloadLink;
  };

  return { canDownload, videoFiles, getDownloadLink };
};
