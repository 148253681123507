import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { mqUser } from 'utils/responsive';
import { TEAM_ROLE_TYPE } from 'domain/entities/Team/TeamRole';
import { MyTeamList } from 'ui/components/company/patterns/MyTeamList/MyTeaList';
import { UserIcon } from 'ui/components/company/elements/UserIcon/UserIcon';
import { ActionButton } from 'ui/components/company/elements/ActionButton/ActionButton';
import { useTeamSwitch } from './useTeamSwitch';

const Wrapper = styled.div`
  height: calc(100vh - var(--height-company-pc-header));
  background-color: var(--primary);
  overflow-y: auto;
  margin: -16px -30px -60px;
  padding: 40px 20px 60px;
`;

const Content = styled.div`
  max-width: 1000px;
  margin-inline: auto;
`;

const Title = styled.div`
  margin-bottom: 20px;
  font-size: var(--font-xxxx-large);
  font-weight: var(--font-extra-bold);
  color: var(--spr-black);
  line-height: 1;
  text-align: center;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  padding: 40px 28px;
  border-radius: 12px;
  background-color: var(--white);
`;

const Desc = styled.div`
  width: 100%;
  margin-bottom: 40px;
  padding: 40px 30px 50px;
  border-radius: 20px;
  background-color: var(--accent-paler);
  ${mqUser('SP')} {
    padding: 20px 24px;
    text-align: center;
  }
`;

const DescTitle = styled.p`
  margin: 0 auto;
  font-size: var(--font-xxx-large);
  line-height: 1.8;
  color: var(--accent);
  font-weight: 900;
  text-align: center;
  ${mqUser('SP')} {
    margin: 12px 0 24px;
    font-size: var(--font-x-large);
  }
`;

const DescText = styled.p`
  margin: 22px auto 0;
  font-size: var(--font-x-large);
  line-height: 1.8;
  font-weight: 900;
  text-align: center;
  ${mqUser('SP')} {
    font-size: var(--font-medium);
  }
`;

const Row = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-start;
  width: 90%;
  margin-bottom: 30px;
`;

const Current = styled.div`
  padding: 16px 14px;
  border-radius: 4px;
  background-color: var(--primary-dark);
  font-size: var(--font-x-large);
  font-weight: var(--font-extra-bold);
  line-height: 1.6;
  text-align: center;
`;

const TeamInfo = styled.div`
  display: grid;
  grid-template-areas:
    'icon name'
    'desc desc';
  grid-template-columns: 40px 1fr;
  place-items: center start;
  column-gap: 8px;
`;

const TeamIcon = styled.div`
  grid-area: icon;
`;

const TeamName = styled.div`
  grid-area: name;
  font-size: var(--font-large);
  font-weight: var(--font-bold);
`;

const TeamDesc = styled.div`
  grid-area: desc;
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Text = styled.p`
  font-size: var(--font-xx-large);
  line-height: 1.5;
  font-weight: 400;
  text-align: center;
  margin: 0 auto 15px;
  ${mqUser('SP')} {
    font-size: var(--font-x-large);
  }
`;

export const TeamSwitch: React.FC = () => {
  const navigate = useNavigate();
  const { user, team, handleLogout } = useTeamSwitch();
  return (
    <Wrapper>
      <Content>
        <Title>ワークグループを選択する</Title>

        <Card>
          <Desc>
            <DescTitle>ワークグループ作成は無料です</DescTitle>
            <DescText>
              部署ごとにメンバーを変えて、インタビューを行うのに最適です。
              <br />
              招待してもらうことで他のワークグループに参加することもできます。
            </DescText>
          </Desc>
          <ActionButton
            onClick={() => navigate('/workgroup/create/')}
            bgColor="secondary-dark"
            style={{ padding: '26px 40px' }}
          >
            新しくワークグループを作成する
          </ActionButton>
        </Card>

        {user.isMyCompanyGetActive() && (
          <Card>
            <Row>
              <Current>現在のワークグループ</Current>
              <TeamInfo>
                <TeamIcon>
                  <UserIcon src={team.icon_image} size={40} />
                </TeamIcon>
                <TeamName>{team.name}</TeamName>

                {/* Todo: 説明文が無ければ、編集ページへのリンクを表示する */}
                <TeamDesc>
                  {team.meta_description.length > 0 ? team.meta_description : '（識別名 未設定）'}
                </TeamDesc>
              </TeamInfo>
            </Row>

            {user.permissions?.includes('member_management__invite') && (
              <ActionButton bgColor="white" onClick={() => navigate('/company/main/users/invite/')}>
                このワークグループにユーザーを招待する
              </ActionButton>
            )}
          </Card>
        )}

        <MyTeamList />

        {user.team_role !== TEAM_ROLE_TYPE.UNCERTIFIED ? (
          <Card>
            <Text>その他の機能やページにアクセスする</Text>
            <ActionButton bgColor="white" onClick={() => navigate('/workgroup/')}>
              ダッシュボードに移動
            </ActionButton>
          </Card>
        ) : (
          <Card>
            <ActionButton bgColor="white" onClick={handleLogout}>
              ログアウト
            </ActionButton>
          </Card>
        )}
      </Content>
    </Wrapper>
  );
};
