import { Ogp } from 'domain/types/ogp';
import { OptionPlan } from 'domain/valueObjects/companyApplication/companyApplication';

export enum COMPANY_STATUS {
  BEFORE_CONFIRMATION = 'before_confirmation',
  ACTIVE = 'active',
  TERMINATED_BY_SPREADY = 'terminated_by_spready',
  TERMINATED = 'terminated',
}

export enum COMPANY_CONTRACT_STATUS {
  TO_BE_CLOSED = 10,
  CONTRACTED = 20,
  NOT_IN_SERVICE = 30,
}

export interface ICompany {
  id: number;
  name: string;
  meta_description: string;
  address: string;
  capital: number | null;
  employee: number | null;
  icon_image: string;
  president_name: string | null;
  foundation_at: Date;
  url: string;
  status: COMPANY_STATUS;
  url_hash: string | null;
  zipcode?: string;
  herp_webhook_url?: string | null;
  background_pic?: string;
  pics?: string[];
  about_company?: string | null;
  url_ogps?: Ogp[];
  nps_score?: number;
  slack_webhook_url?: string;
  logo_display_enable?: boolean;
  logo_display_pushed?: boolean;
  search_words?: string | null;
  copy?: string | null;
  contract_start_at?: Date;
  contract_status?: COMPANY_CONTRACT_STATUS;
  plan?: OptionPlan;
  corporate_number?: string; // DBではTeamProfileに含まれる情報。企業ユーザー情報の修正時に必要なので、暫定追加した。
  contract_period?: { start_date: Date | null; end_date: Date | null };
  video_plan?: 'BUSINESS' | null;
}

export type ICastCompany = Omit<ICompany, 'foundation_at' | 'url_hash'>;

export class Company implements ICompany {
  constructor(
    public id: number,
    public name: string,
    public meta_description: string,
    public address: string,
    public capital: number | null,
    public employee: number | null,
    public icon_image: string,
    public president_name: string | null,
    public foundation_at: Date,
    public url: string,
    public status: COMPANY_STATUS,
    public url_hash: string | null,
    public zipcode?: string,
    public herp_webhook_url?: string | null,
    public background_pic?: string,
    public pics?: string[],
    public about_company?: string | null,
    public url_ogps?: Ogp[],
    public nps_score?: number,
    public slack_webhook_url?: string,
    public logo_display_enable?: boolean,
    public logo_display_pushed?: boolean,
    public search_words?: string | null,
    public copy?: string | null,
    public contract_start_at?: Date,
    public contract_status?: COMPANY_CONTRACT_STATUS,
    public plan?: OptionPlan,
    public corporate_number?: string,
    public contract_period?: { start_date: Date | null; end_date: Date | null },
    public video_plan?: 'BUSINESS' | null,
  ) {}
}
