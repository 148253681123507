import { Outlet } from 'react-router-dom';
import { useCallback, useContext, useEffect } from 'react';
import { useLocation, useNavigate, useParams, generatePath } from 'react-router-dom';
import { companyUserContext } from 'application/contexts/useCompanyUser';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { TEAM_ROLE_TYPE } from 'domain/entities/Team/TeamRole';
import { COMPANY_CONTRACT_STATUS } from 'domain/entities/Company/Company';
import { companyStatsContext } from 'application/contexts/useCompanyStats';
import { companyMessageContext } from 'application/contexts/useCompanyMessage';
import { ICompanyQuery } from 'application/querySchemas/ICompanyQuery';
import { ITeamSwitchRepository } from 'application/repositorySchemas/ITeamSwitchRepository';
import { ICompanyStatsQuery } from 'application/querySchemas/ICompanyStatsQuery';
import { companyAppContext } from 'application/contexts/useCompanyApp';
import { CompanyChatRoomQuery } from 'interfaceAdapter/queries/CompanyChatRoom';

/**
 * Business系 Team所属済みページの共通処理
 */
export const TeamUrlHashStandard: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { urlHash } = useParams();
  const diContainer = useContext(diContainerContext);
  const { setPageVisibility } = useContext(companyAppContext);
  const { user } = useContext(companyUserContext);
  const { fetchStats } = useContext(companyStatsContext);
  const { fetchUnreadIds } = useContext(companyMessageContext);
  const companyQuery = diContainer.resolve<ICompanyQuery>('ICompanyQuery');
  const companyStatsQuery = diContainer.resolve<ICompanyStatsQuery>('ICompanyStatsQuery');
  const companyChatRoomQuery = diContainer.resolve(CompanyChatRoomQuery);
  const teamSwitchRepository = diContainer.resolve<ITeamSwitchRepository>('ITeamSwitchRepository');

  const fetchData = useCallback(async () => {
    fetchStats(() => companyStatsQuery.get());
    fetchUnreadIds(() => companyChatRoomQuery.getUnreadChatRoomIds());

    const isContractViewable = await companyQuery.isContractViewable();
    setPageVisibility({ companyContract: isContractViewable });
  }, [companyStatsQuery, companyChatRoomQuery, companyQuery]);

  const checkUrlHash = async () => {
    if (user.id === 0) {
      return;
    }
    const urlHashes = user.switchable_teams?.map((team) => team.url_hash).filter(Boolean) ?? [];

    if (urlHashes.length === 0) {
      return navigate('/workgroup/switch/', { replace: true });
    }

    if (
      user.company !== null &&
      user.company.contract_status !== COMPANY_CONTRACT_STATUS.CONTRACTED &&
      urlHash === user.company.url_hash
    ) {
      return navigate('/workgroup/switch/', { replace: true });
    }

    const validUrlHash = urlHashes.includes(urlHash ?? '');
    if (validUrlHash && urlHash !== user.company?.url_hash) {
      // Teamの切り替えを行う
      try {
        await teamSwitchRepository.switch(urlHash as string);
        return window.location.reload();
      } catch (e) {
        console.error(e);
        // 切り替えに失敗した場合、現在のページに留まらせる
      }
    }

    if (user.company === null) {
      // CurrentTeamRoleAssignmentがない場合
      return navigate('/workgroup/switch/', { replace: true });
    }

    const invalidUrlHash = !urlHashes.includes(urlHash ?? '');
    if (invalidUrlHash && location.pathname.startsWith('/workgroup')) {
      // UrlHashの自動付与
      const newPathname = generatePath('/workgroup/:urlHash/*', {
        urlHash: user.company.url_hash,
        '*': location.pathname.split('/workgroup/')[1],
      });
      const newPath = `${newPathname.endsWith('/') ? newPathname : `${newPathname}/`}${
        location.search
      }`;
      return navigate(newPath, { replace: true });
    }

    if (user.team_role !== TEAM_ROLE_TYPE.SUPPORT_CHAT_PERMITTED) {
      fetchData();
    }
  };

  useEffect(() => {
    checkUrlHash();
  }, [location.pathname, user.id]);

  return <Outlet />;
};
