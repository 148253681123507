import { AIDenoiserExtension, IAIDenoiserProcessor } from 'agora-extension-ai-denoiser';
import AgoraRTC, { IMicrophoneAudioTrack } from 'agora-rtc-react';
import { useEffect, useRef, useState } from 'react';

export const useDenoiser = ({
  localMicrophoneTrack,
}: {
  localMicrophoneTrack: IMicrophoneAudioTrack | null;
}) => {
  const [extensionActive, setExtensionActive] = useState(false);

  const extension = useRef(
    new AIDenoiserExtension({
      /**
       * Wasmファイルは agora-extension-ai-denoiser 内に含まれているファイルをS3に配置している
       */
      assetsPath: process.env.REACT_APP_AGORA_DENOISER_WASM_PATH ?? '',
    }),
  );
  const processor = useRef<IAIDenoiserProcessor>();

  const enableDenoiser = async () => {
    if (processor.current && localMicrophoneTrack) {
      console.log('enableDenoiser');
      localMicrophoneTrack.pipe(processor.current).pipe(localMicrophoneTrack.processorDestination);
      await processor.current.enable();
      setExtensionActive(true);
    }
  };

  useEffect(() => {
    if (!localMicrophoneTrack) {
      return;
    }
    console.log('Initializing an ai noise processor...');

    const initExtension = async () => {
      AgoraRTC.registerExtensions([extension.current]);
      processor.current = extension.current.createProcessor();
      await processor.current.enable();
    };

    initExtension();

    enableDenoiser();

    return () => {
      processor.current?.unpipe();
      localMicrophoneTrack?.unpipe();
    };
  }, [localMicrophoneTrack]);

  const disableDenoiser = async () => {
    if (processor.current && localMicrophoneTrack) {
      localMicrophoneTrack.unpipe();
      await processor.current.disable();
      setExtensionActive(false);
    }
  };

  return {
    enableDenoiser,
    disableDenoiser,
    extensionActive,
  };
};
