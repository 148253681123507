import { UID, useRTCClient } from 'agora-rtc-react';
import React, { useEffect, useState } from 'react';

export const VideoIndicator = React.memo(function VideoIndicator({ uid }: { uid: UID }) {
  const client = useRTCClient();
  const [isSpeaking, setIsSpeaking] = useState(true);
  const [, setCurrentVolume] = useState(0);
  const VOLUMED_THRESHOLD = 30;

  const handleVolumeIndicator = (
    volumes: {
      level: number;
      uid: UID;
    }[],
  ) => {
    setCurrentVolume(volumes.find((v) => v.uid == uid)?.level ?? 0);
    if ((volumes.find((v) => v.uid == uid)?.level ?? 0) > VOLUMED_THRESHOLD) {
      setIsSpeaking(true);
    } else {
      setIsSpeaking(false);
    }
  };

  useEffect(() => {
    client.on('volume-indicator', handleVolumeIndicator);
    return () => {
      client.off('volume-indicator', handleVolumeIndicator);
    };
  }, [client]);

  return (
    <div
      style={{
        position: 'absolute',
        bottom: '0px',
        right: '0px',
        width: '100%',
        height: '100%',
        zIndex: 1,
        padding: '10px',
        borderRadius: '10px',
        outline: isSpeaking ? '2px solid yellow' : 'none',
      }}
    ></div>
  );
});
