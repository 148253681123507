import styled from '@emotion/styled';
import { UserDetail } from 'ui/components/company/panes/UserDetail/UserDetail';
import { useCompanyDrawer } from './useCompanyDrawer';

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100% - var(--height-sp-header));
  overflow: auto;
  padding-bottom: 100px;
`;

/**
 * クエリパラメータの指定に応じて、ユーザー詳細を表示するドロワー
 */
export const CompanyDrawer: React.FC = () => {
  const { userId, createDrawer } = useCompanyDrawer();

  return createDrawer(<Container>{userId && <UserDetail userId={userId} />}</Container>);
};
