import { Button } from 'ui/components/company/elements/Button/Button';
import { SnackBar } from 'ui/components/company/elements/SnackBar/SnackBar';
import { useInquireBusinessPlan } from 'ui/components/company/patterns/CompanyBusinessPlanInquire/useInquireBusinessPlan';
import ButtonImage from 'ui/images/company/video-call/ui-image.png';
import Pricing from 'ui/images/company/video-call/video-plan.png';

export const WorkgroupHowToVideoCall = () => {
  const { submit, loading, showSnackbar, setShowSnackbar, snackbarMessage } =
    useInquireBusinessPlan();

  return (
    <div style={{ fontSize: '16px', padding: '20px', lineHeight: '1.6' }}>
      <h1>Spreadyビデオ通話ヘルプページ</h1>
      <h2>Spreadyビデオ通話とは</h2>
      <div>
        ZoomやGoogle Meet等、他サービスのリンクを準備することなく、
        <b>Spreadyの中でビデオ通話をしていただける</b>機能です。
        <br />
        ブラウザやモバイル端末でもご利用いただけるため、他サービスのアプリ等をインストールされていない方でもご利用いただけます。
        <br />
        下記の事項をお読みの上、ご利用ください。
      </div>

      <h2>ビデオ通話の開始の仕方</h2>

      <ul>
        <li>
          事前に紹介の<b>「面談日」をご設定ください</b>
          。面談日当日のみビデオ通話を開始することができます。
        </li>
        <li>
          ビデオ通話はチャット画面の「ビデオ通話を開始」ボタンから開始できます。企業側、個人側問わず、どなたでも通話を開始できます。
        </li>
        <div style={{ width: '500px', margin: '10px 0 5px' }}>
          <img src={ButtonImage} alt="" />
        </div>
        <li>
          事前に「カメラとマイクをテスト」ボタンからテストをし、ご利用の端末から正常に音声や映像を届けられるかをご確認ください
        </li>
        <li>
          また、セッションの編集画面で「面談場所」に「Spreadyビデオ通話」を選択いただくと、個人の方向けの表示で、Spreadyビデオ通話をご利用することが表示されます（※面談場所に選択しない場合でもSpreadyビデオ通話をご利用いただくことができます）
        </li>
      </ul>

      <h2>ご利用時の注意点</h2>
      <h3>推奨環境</h3>
      <div>
        快適にご利用いただくために、<b>Google Chrome（バージョン58以降）</b>
        の使用を推奨しております。Chromeは動作の安定性や互換性が高く、よりスムーズな体験を提供します。
      </div>
      <div>
        当サービスは、以下のブラウザで動作します。
        <ul>
          <li>Windows / macOS</li>
          <li>Google Chrome（推奨・バージョン58以降）</li>
          <li>Microsoft Edge（バージョン80以降）</li>
          <li>モバイル</li>
          <li>Android：Google Chrome（バージョン58以降）</li>
          <li>
            iOS：Safari（バージョン11以降対応）に対応してますが、動作が安定しない可能性があります。動作が安定しない場合は、iOS
            Chrome等、別ブラウザでのご利用をお試しください。
          </li>
        </ul>
      </div>
      <h3>うまくいかない時は</h3>
      <div>
        ご不便をおかけして申し訳ありません。うまく通話ができない時は、大変恐れ入りますがZoom等別サービスのご利用をお願いしております。
        <br />
        チャットより個人の方にご連携いただき、ビデオ通話のURLをお知らせいただけますと幸いです。
      </div>

      <h2>個人ユーザーからの見え方</h2>
      <div>
        個人ユーザーからも、同様にチャット画面から「ビデオ通話を開始」や「カメラとマイクをテスト」ボタンが表示されております。
      </div>
      <div>また、チャット開始時にSpreadyビデオ通話を利用する可能性がある旨がお知らせされます。</div>

      <h2>録画について</h2>
      <h3>自動録画</h3>
      <div>
        Spreadyビデオ通話は自動録画機能を搭載しております。通話を開始した時点で録画が開始され、通話が終了（全員が退出）したタイミングで録画が終了されます。
      </div>

      <h3>録画データのダウンロード</h3>
      <div>
        録画した映像データは、チャット画面の「録画ファイル一覧」ボタンよりダウンロードが可能です。
      </div>
      <div>※本ボタンは録画データがない時は表示されません</div>
      <div>
        通話終了後、一定時間で録画データが作成されます。通常数分〜数十分で録画データの生成が完了します。
      </div>

      <h3>録画の再生</h3>
      <div>録画した映像データは、チャット画面の「録画ファイル一覧」ボタンより再生が可能です。</div>
      <div>ただし、72時間より前のデータの再生は有料プラン専用の機能となります。</div>

      <h2>料金プラン</h2>
      <div style={{ width: '1000px' }}>
        <img src={Pricing} alt="ビデオ通話料金プラン" />
        <div style={{ fontSize: '12px', textAlign: 'center' }}>
          ※IPアドレスはCIDRレコードのことを指します
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          maxWidth: '400px',
          gap: '10px',
          flexDirection: 'column',
          alignItems: 'center',
          margin: '20px auto 0',
        }}
      >
        <Button disabled={loading} onClick={submit} fontSize="20px" button_type={'blue'}>
          有料プランに関するお問い合わせをする
        </Button>
        <SnackBar open={showSnackbar} setOpen={setShowSnackbar} message={snackbarMessage} />
        <div>お気軽にご相談ください🙌</div>
      </div>
    </div>
  );
};
