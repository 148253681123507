import MicOff from '@mui/icons-material/MicOff';

export const MuteIcon = ({ muted }: { muted: boolean }) => {
  return (
    <div
      style={{
        padding: '.5rem',
        borderRadius: '4px',
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        display: muted ? 'block' : 'none',
      }}
    >
      <MicOff style={{ color: 'red' }} />
    </div>
  );
};
